import { DeleteFilled, MinusCircleFilled, PlusCircleFilled, PlusOutlined } from "@ant-design/icons";
import Cancel from "@mui/icons-material/Cancel";
import SwapVert from "@mui/icons-material/SwapVert";
import {
    Box,
    Checkbox,
    Chip,
    FormControl,
    IconButton,
    ListItemText,
    MenuItem,
    OutlinedInput,
    TextField,
} from "@mui/material";
import Select from "@mui/material/Select";
import { Button, Col, Collapse, Modal, Popconfirm, Row, Upload, message } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../api/auth_header";
import { API_BASE_URL } from "../../../../constants";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import RenderDiemDen from "./RenderDiemDen";
import RestartAlt from "@mui/icons-material/RestartAlt";
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
// const RenderDiemDen = (quocGiaId, tinhId) => {
//     const { t } = useTranslation();
//     const [dataQuocGia, setDataQuocGia] = useState([]);
//     const [dataQuocGiaId, setDataQuocGiaId] = useState(0);
//     const [dataTinhId, setDataTinhId] = useState(0);
//     const [dataTinhTp, setDataTinhTp] = useState([]);
//     useEffect(() => {
//         axios({
//             method: "post",
//             url: API_BASE_URL + "category/danhSachQuocGia",
//             headers: authHeader(),
//         })
//             .then(function (response) {
//                 if (response.status === 200) {
//                     // console.log("Response: ====" + response.data.data);
//                     let dataQG = [];
//                     let data = response.data.data;
//                     if (data.length > 0) {
//                         for (let i = 0; i < data.length; i++) {
//                             if (data[i].hieu_luc === true) {
//                                 dataQG.push({
//                                     value: data[i].id,
//                                     label: data[i].quoc_gia,
//                                 });
//                             }
//                         }
//                         setDataQuocGia(dataQG);
//                     }
//                 }
//             })
//             .catch(function (response) {
//                 console.log("Error response: ====" + response);
//             });
//     }, []);
//     useEffect(() => {
//         if (quocGiaId) handleChangeQG(quocGiaId, tinhId);
//     }, [quocGiaId, tinhId]);
//     const handleChangeQG = async (value, tinh) => {
//         console.log(`selected ${value}`);
//         setDataQuocGiaId(value);
//         let data = {
//             dm_quoc_gia_id: value,
//         };
//         await axios({
//             method: "post",
//             url: API_BASE_URL + "category/danhSachTinhThanhPho",
//             data: data,
//             headers: authHeader(),
//         }).then(function (response) {
//             // console.log("Response: ====" + response.status);
//             if (response.status === 200) {
//                 let dataTp = [];
//                 let data = response.data.data;
//                 if (data.length > 0) {
//                     for (let i = 0; i < data.length; i++) {
//                         if (data[i].hieu_luc === true) {
//                             dataTp.push({
//                                 value: data[i].id,
//                                 label: data[i].tinh_thanhpho,
//                             });
//                         }
//                     }
//                     setDataTinhTp(dataTp);
//                     setDataTinhId(tinh);
//                 }
//             }
//         });
//     };
//     return (
//         <>
//             <Row>
//                 <Col xs={4} sm={4} md={4} lg={4} xl={4}>
//                     <div style={style.h4}>{t("destination")}</div>
//                 </Col>
//                 <Col xs={9} sm={9} md={9} lg={9} xl={9}>
//                     <TextField
//                         value={dataQuocGiaId}
//                         select
//                         fullWidth
//                         variant="standard"
//                         onChange={(e) => handleChangeQG(e.target.value, null)}
//                     >
//                         {dataQuocGia.map((option) => (
//                             <MenuItem key={option.value} value={option.value}>
//                                 {option.label}
//                             </MenuItem>
//                         ))}
//                     </TextField>
//                 </Col>
//                 <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
//                 <Col xs={9} sm={9} md={9} lg={9} xl={9}>
//                     <TextField
//                         fullWidth
//                         variant="standard"
//                         value={dataTinhId}
//                         select
//                         // onChange={(e) => setDmTinhIdDi(e.target.value)}
//                     >
//                         {dataTinhTp.map((option) => (
//                             <MenuItem key={option.value} value={option.value}>
//                                 {option.label}
//                             </MenuItem>
//                         ))}
//                     </TextField>
//                 </Col>
//             </Row>
//         </>
//     );
// };
const style = {
    h4: { fontSize: "14px", fontWeight: "bold", marginTop: "10px" },
    rowTop: { marginTop: "10px" },
    InputLabelProps: { color: "#858383", fontSize: "22px", fontWeight: 600 },
};
const CardHanhTrinh = ({
    id,
    items,
    index,
    moveCard,
    cards,
    setCards,
    errorSoNgay,
    setIsOpenTouMudle,
    setKeySelect,
    handleDelHT,
    setIsChangeHanhTrinh,
    isCopyTourMau,
}) => {
    const { t } = useTranslation();
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [dataTinhTpDi, setDataTinhTpDi] = useState([]);
    const [dataTinhTpDen, setDataTinhTpDen] = useState([]);
    const [dmDichVus, setDmDichVus] = useState([]);
    const [dataDichVu, setDataDichVu] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [isOpen, setisOpen] = useState(false);
    const [dmQuocGiaIdDi, setDmQuocGiaIdDi] = useState(0);
    const [dmTinhIdDi, setDmTinhIdDi] = useState(0);
    const [diemDien, setDiemDien] = useState();
    const ref = useRef(null);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmDichVu",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataDV = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataDV.push({
                                    id: data[i].id,
                                    value: data[i].ten_dich_vu,
                                });
                            }
                        }
                        setDataDichVu(dataDV);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const setCardByIndex = () => {
        let data = [
            ...cards.map((card, i) => {
                if (index === i) return items;
                return card;
            }),
        ];
        setIsChangeHanhTrinh(true);
        setCards(data);
    };
    useEffect(() => {
        setDmQuocGiaIdDi(items.diemDi.quocGiaId);
        handleChangeQG(items.diemDi.quocGiaId, "di", items.diemDi.tinhId);
        if (items.diemDen.length === 0) {
            items.diemDen = [{ quocGiaId: 0, tinhId: 0 }];
            let data = [
                ...cards.map((card, i) => {
                    if (index === i) return items;
                    return card;
                }),
            ];
            // setIsChangeHanhTrinh(true);
            setCards(data);
        }
        var dmDichVusId = [];
        if (items.dmDichVus)
            items.dmDichVus.forEach((dt) => {
                dmDichVusId.push(dt.id);
            });
        setDmDichVus(dmDichVusId);
        setDiemDien(renderDD());
    }, [items, cards]);

    const [{ handlerId }, drop] = useDrop({
        accept: "card",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: "card",
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    const handleChangeQG = async (value, type, tinh) => {
        console.log(`selected ${value}`);

        let data = {
            dm_quoc_gia_id: value,
        };
        await axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachTinhThanhPho",
            data: data,
            headers: authHeader(),
        }).then(function (response) {
            // console.log("Response: ====" + response.status);
            if (response.status === 200) {
                let dataTp = [];
                let data = response.data.data;
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc === true) {
                            dataTp.push({
                                value: data[i].id,
                                label: data[i].tinh_thanhpho,
                            });
                        }
                    }
                    if (type === "di") {
                        setDmQuocGiaIdDi(value);
                        setDataTinhTpDi(dataTp);
                        if (tinh) {
                            let tinhId = dataTp.find((t) => t.label === tinh)?.value;
                            setDmTinhIdDi(tinhId);
                        }
                        if (items.diemDi.quocGiaId !== value) {
                            items.diemDi.quocGiaId = value;
                            let data = [
                                ...cards.map((card, i) => {
                                    if (index === i) return items;
                                    return card;
                                }),
                            ];
                            setCards(data);
                        }
                    }
                    // if (type === "den") {
                    //     // setDmQuocGiaIdDen(value);
                    //     setDataTinhTpDen(dataTp);
                    //     if (tinh) setDmTinhIdDen(dataTp.find((t) => t.label === tinh)?.value);
                    // }
                }
            }
        });
    };
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachQuocGia",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataQG = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQG.push({
                                    value: data[i].id,
                                    label: data[i].quoc_gia,
                                });
                            }
                        }
                        setDataQuocGia(dataQG);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const handleChangeImg = async ({ fileList: newFileList }) => {
        console.log("handleChangeImg ====", newFileList);

        if (newFileList.length > 0) {
            let file = [];
            for (let i in newFileList) {
                newFileList[i].status = "done";
                let base = await getBase64(newFileList[i].originFileObj);
                file.push({
                    id: i,
                    tenFile: newFileList[i].name,
                    base64Value: base,
                });
                items.imgFile = file;
                let data = [
                    ...cards.map((card, i) => {
                        if (index === i) return items;
                        return card;
                    }),
                ];
                setCards(data);
            }
        }
        setFileList(newFileList);
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };

    const handleChangeDv = (event) => {
        const {
            target: { value },
        } = event;
        let dt = typeof value === "string" ? value.split(",") : value;
        let dVS = [];
        for (let i = 0; i < dt.length; i++) {
            dVS.push({ id: dt[i] });
        }
        setDmDichVus(dt);
        items.dmDichVus = dVS;
        let data = [
            ...cards.map((card, i) => {
                if (index === i) return items;
                return card;
            }),
        ];
        setIsChangeHanhTrinh(true);
        setCards(data);
    };
    const beforeUploadImg = (file) => {
        const isPNG =
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/giff";
        if (!isPNG) {
            message.error(`${file.name} ` + " " + t("errorUploadImg"));
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
            message.error("Image must smaller than 3MB!");
        }

        return isPNG || Upload.LIST_IGNORE;
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const handleCancel = () => setPreviewOpen(false);
    const handleClickAdd = () => {
        let diemDien = [];
        for (let i = 0; i < items.diemDen.length; i++) {
            diemDien.push({
                ...items.diemDen[i],
            });
            if (i === 0) {
                diemDien.push({
                    diaChiCuThe: null,
                    diaDiem: null,
                    huyenId: null,
                    quocGiaId: null,
                    tinhId: null,
                    xaId: null,
                });
            }
        }
        items.diemDen = diemDien;
        let data = [
            ...cards.map((card, i) => {
                if (index === i) return items;
                return card;
            }),
        ];
        setIsChangeHanhTrinh(true);
        setCards(data);
    };
    const handleClickRemove = (ix) => {
        let diemDien = [];
        for (let i = 0; i < items.diemDen.length; i++) {
            if (i !== ix) {
                diemDien.push({
                    ...items.diemDen[i],
                });
            }
        }
        items.diemDen = diemDien;
        let data = [
            ...cards.map((card, i) => {
                if (index === i) return items;
                return card;
            }),
        ];
        setIsChangeHanhTrinh(true);
        setCards(data);
    };
    const handleOnChangeTen = (value) => {
        items.hanhTrinh = value;
        let data = [
            ...cards.map((card, i) => {
                if (index === i) return items;
                return card;
            }),
        ];
        setIsChangeHanhTrinh(true);
        setCards(data);
    };
    const handleDelData = () => {
        var rest = {
            diemDi: {},
            diemDen: [{}],
            ngay: items.ngay,
            headers: items.headers,
            id: items.id,
            baoGiaTongQuanId: items.baoGiaTongQuanId,
            noiDung: "",
        };
        let data = [
            ...cards.map((card, i) => {
                if (index === i) return rest;
                return card;
            }),
        ];
        setIsChangeHanhTrinh(true);
        setCards(data);
    };
    const renderDD = () => {
        let data = [];
        if (items.diemDen && items.diemDen.length > 0) {
            items.diemDen.map((option, idx) => {
                data.push(
                    <RenderDiemDen
                        quocGiaId={option.quocGiaId || 0}
                        tinhId={option.tinhId || 0}
                        index={index}
                        items={items}
                        idx={idx}
                        setCards={setCards}
                        cards={cards}
                        setIsChangeHanhTrinh={setIsChangeHanhTrinh}
                    />
                );
            });
        } else
            data.push(
                <RenderDiemDen
                    quocGiaId={0}
                    tinhId={0}
                    index={index}
                    items={items}
                    idx={0}
                    setCards={setCards}
                    cards={cards}
                    setIsChangeHanhTrinh={setIsChangeHanhTrinh}
                />
            );
        return data;
    };

    return (
        <Collapse defaultActiveKey={"1"}>
            <Collapse.Panel
                key={"1"}
                header={
                    <Row>
                        <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                            <div style={{ textTransform: "uppercase", fontSize: "15px", fontWeight: "bold" }}>
                                {items.headers}
                            </div>
                        </Col>
                    </Row>
                }
            >
                <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
                    <Row style={{ margin: 2 }}>
                        <Col xs={20} sm={20} md={20} lg={20} xl={20}></Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={1}>
                            {errorSoNgay ? (
                                <Popconfirm
                                    title={t("deleteTheCard")}
                                    description={t("areYouConfirmDeleteCare")}
                                    okText={t("delete")}
                                    cancelText={t("huy")}
                                    onConfirm={() => handleDelHT(index)}
                                >
                                    <Button danger icon={<DeleteFilled size="large" />} />
                                </Popconfirm>
                            ) : (
                                <></>
                            )}
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Button
                                onClick={() => {
                                    setIsOpenTouMudle(true);
                                    setKeySelect(index);
                                }}
                            >
                                {t("selectTrip")}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={22} sm={22} md={22} lg={2} xl={23}>
                            <TextField
                                fullWidth
                                id="nhapTen"
                                label={t("nhapTen") + " " + t("trip")}
                                variant="standard"
                                value={items.hanhTrinh ? items.hanhTrinh : " "}
                                onChange={(e) => handleOnChangeTen(e.target.value)}
                            />
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                            <Button size="small" icon={<SwapVert />} />

                            <Button size="small" icon={<RestartAlt />} onClick={handleDelData} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={17}>
                            <Row>
                                <Col xs={4} sm={4} md={4} lg={4} xl={2}>
                                    <div style={style.h4}>{t("point")}</div>
                                </Col>
                                <Col xs={10} sm={10} md={10} lg={10} xl={4}>
                                    <TextField
                                        value={dmQuocGiaIdDi ? dmQuocGiaIdDi : ""}
                                        select
                                        fullWidth
                                        variant="standard"
                                        onChange={(e) => {
                                            handleChangeQG(e.target.value, "di", null);
                                            setIsChangeHanhTrinh(true);
                                        }}
                                        // value={items.diemDi.quocGiaId}
                                    >
                                        {dataQuocGia.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Col>
                                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={9} sm={9} md={9} lg={9} xl={4}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        value={items.diemDi.tinhId ? items.diemDi.tinhId : ""}
                                        select
                                        onChange={(e) => {
                                            setDmTinhIdDi(e.target.value);
                                            items.diemDi.tinhId = e.target.value;
                                            setCardByIndex();
                                            setIsChangeHanhTrinh(true);
                                        }}
                                    >
                                        {dataTinhTpDi.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={2}></Col>

                                <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                    {diemDien}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4} sm={4} md={4} lg={4} xl={2}>
                                    <div style={style.h4}>{t("service")}</div>
                                </Col>
                                <Col xs={20} sm={20} md={20} lg={20} xl={22}>
                                    <FormControl variant="standard" sx={{ width: "100%" }}>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            variant="standard"
                                            multiple
                                            fullWidth
                                            value={dmDichVus}
                                            onChange={handleChangeDv}
                                            input={<OutlinedInput label={t("typeService")} />}
                                            // renderValue={(selected) => selected.map(obj=> dataDichVu[obj - 1].value).join(", ")}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                    {selected.map((dvid) => (
                                                        <Chip
                                                            key={dvid}
                                                            label={dataDichVu?.find((e) => e.id === dvid)?.value}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {dataDichVu.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    <Checkbox checked={dmDichVus.indexOf(option.id) > -1} />
                                                    <ListItemText primary={option.value} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            {items.diemDen && items.diemDen.length > 0 ? (
                                items.diemDen.map((option, index) => {
                                    if (index === 0) {
                                        return (
                                            <Row>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Button
                                                        icon={<PlusCircleFilled />}
                                                        onClick={() => handleClickAdd()}
                                                    />
                                                </Col>
                                                {items.diemDen.length !== 1 ? (
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Button
                                                            icon={<MinusCircleFilled />}
                                                            onClick={() => handleClickRemove(index)}
                                                        />
                                                    </Col>
                                                ) : (
                                                    <></>
                                                )}
                                            </Row>
                                        );
                                    } else {
                                        return (
                                            <Row>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Button
                                                        icon={<MinusCircleFilled />}
                                                        onClick={() => handleClickRemove(index)}
                                                    />
                                                </Col>
                                            </Row>
                                        );
                                    }
                                })
                            ) : (
                                <>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Button icon={<PlusCircleFilled />} onClick={() => handleClickAdd()} />
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                            <Upload
                                beforeUpload={beforeUploadImg}
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChangeImg}
                            >
                                {fileList.length >= 3 ? null : uploadButton}
                            </Upload>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                <img
                                    alt="image"
                                    style={{
                                        width: "100%",
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <CKEditor
                                editor={ClassicEditor}
                                data={items.noiDung || ""}
                                onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log("Editor is ready to use!", editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    // setNoiDung(data);
                                    // setClassName("diem-di");
                                    console.log("========ckeditor====", { event, editor, data });
                                    items.noiDung = data;
                                    let data1 = [
                                        ...cards.map((card, i) => {
                                            if (index === i) return items;
                                            return card;
                                        }),
                                    ];
                                    setCards(data1);
                                }}
                                onBlur={(event, editor) => {
                                    console.log("Blur.", editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log("Focus.", editor);
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </Collapse.Panel>
        </Collapse>
    );
};
export default CardHanhTrinh;
