import { DeleteOutlined } from "@ant-design/icons";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Add from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox, Popconfirm, Typography, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dm_ty_gia from "../../../../../api/category/dm_ty_gia";
ClassicEditor.defaultConfig = {
    toolbar: {
        items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
        ],
    },
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "en",
};

function Row({ row, haveSaveData, deleteRow, addRow }) {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const [, contextHolder] = notification.useNotification();
    const [dataTyGia, setDataTyGia] = useState([]);
    useEffect(() => {
        let data = {};
        Dm_ty_gia.getDmTyGiaHeThong(data)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    setDataTyGia(data);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
    }, []);

    const handleChangeRadio = (value, index) => {
        row.tourQuanLyKhoangKhachResponses =
            row.tourQuanLyKhoangKhachResponses?.map((dt) => {
                return { ...dt, apDung: value ? 1 : 2 };
            }) || [];
        var rowNew = {
            ...row,
            apDung: value ? 1 : 2,
        };
        haveSaveData(rowNew, "apDung");
    };
    const handleCheckAll = (value) => {};
    const handleDeleteRow = (loaiTien) => {
        deleteRow(loaiTien);
    };
    const handleAddRow = () => {
        addRow();
    };
    const onChangeSoKhach = (value) => {
        var rowNew = {
            ...row,
            soKhachToiThieu: parseInt(value.split("-")[0]?.trim()),
            soKhachToiDa: parseInt(value.split("-")[1]?.trim()),
        };
        haveSaveData(rowNew, "");
    };
    const handleChangeKhachLe = (value, index) => {
        debugger;
        var rowNew = {
            ...row,
        };
        rowNew.tourQuanLyKhoangKhachResponses[index].khachLe = parseFloat(value);
        haveSaveData(rowNew, "");
    };
    const handleChangeDaiLy = (value, index) => {
        var rowNew = {
            ...row,
        };
        rowNew.tourQuanLyKhoangKhachResponses[index].daiLy = parseFloat(value);
        haveSaveData(rowNew, "");
    };

    const onChangeTyGia = (value) => {
        row.tourQuanLyKhoangKhachResponses =
            row.tourQuanLyKhoangKhachResponses?.map((dt) => {
                return { ...dt, loaiTien: value };
            }) || [];
        var rowNew = { ...row, loaiTien: value };
        haveSaveData(rowNew, "loaiTien");
    };
    let rowDetails = row.tourQuanLyKhoangKhachResponses?.map((dt, index) => {
        return (
            <>
                <TableRow style={{ display: open ? "" : "none" }}>
                    <TableCell></TableCell>
                    <TableCell>
                        <TextField
                            onBlur={(e) => onChangeSoKhach(e.target.value, index)}
                            value={
                                dt.soKhachToiThieu === dt.soKhachToiDa
                                    ? dt.soKhachToiThieu
                                    : dt.soKhachToiThieu + " - " + dt.soKhachToiDa
                            }
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Pax</InputAdornment>,
                            }}
                            disabled
                        ></TextField>
                    </TableCell>
                    <TableCell>
                        <TextField
                            onChange={(e) => handleChangeKhachLe(e.target.value, index)}
                            fullWidth
                            value={dt.khachLe}
                            type="number"
                        ></TextField>
                    </TableCell>
                    <TableCell>
                        <TextField
                            onChange={(e) => handleChangeDaiLy(e.target.value, index)}
                            value={dt.daiLy}
                            type="number"
                        ></TextField>
                    </TableCell>
                    <TableCell>
                        <TextField
                            disabled
                            value={dataTyGia?.find((val) => val.maNgoaiTeId === row.loaiTien)?.maNgoaiTe}
                        ></TextField>
                    </TableCell>
                    <TableCell>
                        <Checkbox
                            checked={row.apDung === 1}
                            onChange={(e) => handleChangeRadio(e.target.checked, index)}
                            name={"radio-buttons" + dt.id}
                            inputProps={{ "aria-label": "A" }}
                        />
                    </TableCell>
                    <TableCell rowSpan={1}>
                        <Typography.Link>
                            <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDeleteRow(row.loaiTien)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    </TableCell>
                </TableRow>
            </>
        );
    });
    return (
        <React.Fragment>
            {contextHolder}
            {row.id === "SUBTOTAL" ? (
                <>
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                        <TableCell colSpan={2}>
                            <Box sx={{ display: "flex", alignItems: "flex-end" }} onClick={() => handleAddRow()}>
                                <Add sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                                <span style={{ marginBottom: "5px", fontSize: "15px" }}>{t("tour.themLoaiTien")}</span>
                            </Box>
                        </TableCell>
                    </TableRow>
                </>
            ) : row.id.toString().indexOf("NULL") !== -1 ? (
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                    <TableCell colSpan={7}></TableCell>
                </TableRow>
            ) : (
                <>
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                        <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {row.markupType}
                        </TableCell>
                        <TableCell style={{ visibility: "hidden" }}>
                            <TextField fullWidth value={row.khachLe}></TextField>
                        </TableCell>
                        <TableCell style={{ visibility: "hidden" }}>
                            <TextField value={row.daiLy}></TextField>
                        </TableCell>
                        <TableCell>
                            <TextField
                                select
                                fullWidth
                                disabled={row.markupType === t("tour.fixedAmount") ? true : false}
                                onChange={(e) => onChangeTyGia(e.target.value)}
                                value={row.loaiTien}
                            >
                                {dataTyGia?.map((option) => (
                                    <MenuItem key={option.maNgoaiTeId} value={option.maNgoaiTeId}>
                                        {option.maNgoaiTe}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                checked={row.apDung === 1}
                                onChange={(e) => handleChangeRadio(e.target.checked)}
                                name={"radio-buttons" + row.id}
                                inputProps={{ "aria-label": "A" }}
                            />
                        </TableCell>
                        <TableCell>
                            <Typography.Link>
                                <Popconfirm
                                    title={t("deleteTheTask")}
                                    description={t("areYouConfirm")}
                                    okText={t("delete")}
                                    cancelText={t("huy")}
                                    onConfirm={() => handleDeleteRow(row.loaiTien)}
                                >
                                    <DeleteOutlined />
                                </Popconfirm>
                            </Typography.Link>
                        </TableCell>
                    </TableRow>
                    {rowDetails}
                </>
            )}
        </React.Fragment>
    );
}

export default function TableDetailThoiGianAD({ dataSource, item, setItems, items, tourQuanLyKhoangKhachResponses }) {
    const { t } = useTranslation();
    const [dataTable, setDataTable] = useState(dataSource);
    const [, contextHolder] = notification.useNotification();
    const haveSaveData = (row, type) => {
        debugger;
        console.log(dataTable);
        console.log(row);
        var data = [];
        if (type === "loaiTien") {
            data = dataTable.map((dt) => {
                if (dt.id_ === row.id_) {
                    let tourQuanLyKhoangKhachResponses =
                        dt.tourQuanLyKhoangKhachResponses?.map((t) => {
                            return { ...t, loaiTien: row.loaiTien };
                        }) || [];
                    return {
                        ...dt,
                        loaiTien: row.loaiTien,
                        tourQuanLyKhoangKhachResponses,
                    };
                }
                return dt;
            });
        } else if (type === "apDung") {
            data = dataTable.map((dt) => {
                if (dt.id_ === row.id_ && dt.markupType === row.markupType) {
                    return { ...row };
                }
                if (dt.id_ === row.id_ && dt.markupType !== row.markupType) {
                    let tourQuanLyKhoangKhachResponses =
                        dt.tourQuanLyKhoangKhachResponses?.map((t) => {
                            return { ...t, apDung: row.apDung === 1 ? 2 : 1 };
                        }) || [];
                    return {
                        ...dt,
                        apDung: row.apDung === 1 ? 2 : 1,
                        tourQuanLyKhoangKhachResponses,
                    };
                }
                return dt;
            });
        } else {
            data = dataTable.map((dt) => {
                if (dt.id === row.id) return { ...row };
                else return { ...dt };
            });
        }

        setDataTable(data);
        var itNew = {
            ...item,
            rowsDT: data,
        };
        setItems(
            items.map((it) => {
                if (it.id === itNew.id) return itNew;
                return it;
            })
        );
    };
    const deleteRow = (loaiTien) => {
        debugger;
        const dataTableNew = dataTable.filter(
            (dt) => dt.loaiTien !== loaiTien && dt.id.toString().indexOf("SUBTOTAL") !== -1
        );
        setDataTable(dataTableNew);
        var itNew = {
            ...item,
            rowsDT: dataTableNew,
        };
        setItems(
            items.map((it) => {
                if (it.id === itNew.id) return itNew;
                return it;
            })
        );
    };
    const addRow = () => {
        debugger;
        let data = [];
        for (let index = 0; index < dataTable.length; index++) {
            if (index === dataTable.length - 1) {
                data.push({
                    id: Math.random() * (100 - 1),
                    id_: "NEW" + index,
                    markupType: t("tour.percentage"),
                    tourQuanLyKhoangKhachResponses: tourQuanLyKhoangKhachResponses.map((dt) => ({
                        ...dt,
                        tourQuanLyKhoangKhachId: dt.id,
                        id: Math.random() * (100 - 1),
                    })),
                });
                data.push({
                    id: Math.random() * (100 - 1),
                    id_: "NEW" + index,
                    markupType: t("tour.fixedAmount"),
                    tourQuanLyKhoangKhachResponses: tourQuanLyKhoangKhachResponses.map((dt) => ({
                        ...dt,
                        tourQuanLyKhoangKhachId: dt.id,
                        id: Math.random() * (100 - 1),
                    })),
                });
                data.push({
                    id: "NULL " + Math.random() * (100 - 1),
                    soKhachToiThieu: null,
                    soKhachToiDa: null,
                    tourChietTinhId: null,
                    tourQuanLyKhoangKhachId: null,
                    markupType: null,
                    type: null,
                    loaiTien: null,
                    apDung: null,
                });
            } else {
                data.push(dataTable[index]);
            }
        }
        data.push({ id: "SUBTOTAL" });
        setDataTable(data);
    };

    return (
        <TableContainer component={Paper}>
            {contextHolder}
            <Table aria-label="collapsible table">
                <TableHead
                    style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        backgroundColor: "#e2e2e2",
                        fontWeight: "bold",
                    }}
                >
                    <TableRow>
                        <TableCell />
                        <TableCell>{t("tour.markupType")}</TableCell>
                        <TableCell>{t("tour.khachLe")}</TableCell>
                        <TableCell>{t("tour.daiLy")}</TableCell>
                        <TableCell>{t("exchangeRate.currency")}</TableCell>
                        <TableCell>{t("action.apply")}</TableCell>
                        <TableCell>{t("action")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataTable.map((row) => (
                        <Row key={row.id} row={row} haveSaveData={haveSaveData} deleteRow={deleteRow} addRow={addRow} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
