import { Badge, Row, Space } from "antd";
import { Button } from "@mui/material";
import React from "react";

const ButtonStatus = React.memo(({ currentStatus, onStatusChange, statusList = [] }) => {
    return (
        <Row style={{ marginTop: "20px" }}>
            <Space size={20}>
                {statusList.map((statusItem) => (
                    <Badge count={statusItem.count} key={statusItem.value}>
                        <Button
                            disabled={currentStatus === statusItem.value}
                            variant="outlined"
                            onClick={() => onStatusChange(statusItem.value)}
                        >
                            {statusItem.label}
                        </Button>
                    </Badge>
                ))}
            </Space>
        </Row>
    );
});
export default ButtonStatus;
