import { Button, Checkbox, DialogContent, FormControlLabel, FormGroup, InputAdornment, Menu, MenuItem, OutlinedInput, Slider, TextField } from "@mui/material";
import { Col, Divider, Row } from "antd";
import Search from "@mui/icons-material/Search";
import { GridFilterAltIcon } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import nhaHangService from "../../../../../api/tour/nhaHang/nhaHangService";
import chiettinhService from "../../../../../api/tour/chiettinh.service";
import { useEffect, useState } from "react";

const ModalHeader = ({
    chietTinh,
    supplier,
    setSupplier,
    dichVu,
    setDichVu,
    quocGiaId,
    setQuocGiaId,
    tinhId,
    setTinhId,
    loaiHdvId,
    setLoaiHdvId,
    setAnchorEl,
    anchorEl,
    dmTyGiaId,
    setDmTyGiaId,
    giaApDungs,
    setGiaApDungs,
    value,
    setValue,
    thoiGianAD,
    getNccNhaHang,
    getNccHuongDanVien,
    getNccVeMayBay
}) => {
    const { t } = useTranslation();
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [dataTinhTP, setDataTinhTP] = useState([]);
    const [dmTyGia, setDmTyGia] = useState([
        { label: "VND", value: 1 },
        { label: "USD", value: 2 },
    ]);
    const [dataMucGia, setDataMucGia] = useState([]);
    const [dataMucGiaInit, setDataMucGiaInit] = useState([]);
    const [valueInit, setValueInit] = useState([0, 0]);
    const [giaMin, setGiaMin] = useState(0);
    const [giaMax, setGiaMax] = useState(0);
    const open = Boolean(anchorEl);
    let formatter = new Intl.NumberFormat("en-US", {});
    const services = {
        nhaHang: getNccNhaHang,
        huongDanVien: getNccHuongDanVien,
        veMayBay: getNccVeMayBay,
    };

    useEffect(() => {
        getDataQuocGia();
        getCauHinhMucGia({ id: dmTyGiaId });
    }, []);

    function valuetext(value) {
        return `${value} đ`;
    }

    const getDataQuocGia = () => {
        nhaHangService
            .getQuocGia()
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    const dataQG = data?.filter((it) => it.hieu_luc)
                        ?.map((item) => ({ value: item.id, label: item.quoc_gia })) || [];
                    setDataQuocGia(dataQG);
                }
            })
            .catch((error) => {
                console.log("Error response: ====" + error);
            });
    };

    const getDataTinhTP = (idQuocGia) => {
        nhaHangService
            .getTinhTP({ dm_quoc_gia_id: idQuocGia })
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    const dataTinhTP = data?.filter(it => it.hieu_luc)?.map((item) => {
                        return {
                            value: item.id,
                            label: item.tinh_thanhpho,
                        };
                    }) || [];
                    setDataTinhTP(dataTinhTP);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const handleChangeQG = (event) => {
        setQuocGiaId(event.target.value);
        getDataTinhTP(event.target.value);
    };

    const handleChangeLoaiHdv = (event) => {
        setLoaiHdvId(event.target.value);
    }

    const getCauHinhMucGia = (data) => {
        if (data.id === 1) {
            setValue([0, 100000000]);
            setValueInit([0, 100000000]);
        } else {
            setValue([0, 10000]);
            setValueInit([0, 10000]);
        }
        chiettinhService
            .layCauHinhMucGia(data)
            .then(function (response) {
                if (response.status === 200) {
                    let dataMucGia =
                        response.data.data?.map((item) => ({
                            ...item,
                            label: item.mucGia,
                        })) || [];
                    setDataMucGia(dataMucGia);
                    if (data.id === 1)
                        setDataMucGiaInit(JSON.parse(JSON.stringify(dataMucGia)));
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const handleChangeLoaiTien = (dmTyGiaId) => {
        setDmTyGiaId(dmTyGiaId);
        getCauHinhMucGia({ id: dmTyGiaId });
    };

    const handleResetFilter = () => {
        setDataMucGia(JSON.parse(JSON.stringify(dataMucGiaInit)));
        setDmTyGiaId(1);
        setValue([...valueInit]);
        setGiaApDungs([]);
    };

    const handleCheckMucGia = (e, item) => {
        setGiaMax(item.giaMax);
        setGiaMin(item.giaMin);
        setValue([...valueInit]);
        let checked = e.target.checked;
        const lstGiaApDung = [];
        if (checked) lstGiaApDung.push(item.id);
        setGiaApDungs(lstGiaApDung);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setGiaApDungs([]);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        handleApplyFilter();
        // setAnchorEl(null);
    };

    const handleApplyFilter = () => {
        setAnchorEl(null);
        let dataSearch = {
            searchNcc: supplier ? supplier : "",
            searchDichVu: dichVu ? dichVu : "",
            tuNgay: thoiGianAD !== null ? thoiGianAD.tuNgay : "",
            denNgay: thoiGianAD !== null ? thoiGianAD.denNgay : "",
            loaiHdvId: loaiHdvId ? loaiHdvId : 0,
            quocGiaId: quocGiaId ? quocGiaId : 0,
            tinhId: tinhId ? tinhId : 0,
            giaMin: giaApDungs.length > 0 ? (giaMin ? giaMin : 0) : value[0],
            giaMax: giaApDungs.length > 0 ? (giaMax ? giaMax : 0) : value[1],
            dmTyGiaId: dmTyGiaId ? dmTyGiaId : 1,
        };
        console.log(dataSearch);
        const service = services[chietTinh];
        if (service) service(dataSearch);

    };

    const handleSearchChooseService = () => {
        let dataSearch = {
            searchNcc: supplier ? supplier : "",
            searchDichVu: dichVu ? dichVu : "",
            tuNgay: thoiGianAD !== null ? thoiGianAD.tuNgay : "",
            denNgay: thoiGianAD !== null ? thoiGianAD.denNgay : "",
            loaiHdvId: loaiHdvId ? loaiHdvId : 0,
            quocGiaId: quocGiaId ? quocGiaId : 0,
            tinhId: tinhId ? tinhId : 0,
            giaMin: giaApDungs.length > 0 ? (giaMin ? giaMin : 0) : value[0],
            giaMax: giaApDungs.length > 0 ? (giaMax ? giaMax : 0) : value[1],
            dmTyGiaId: dmTyGiaId ? dmTyGiaId : 1,
        };
        console.log(dataSearch);
        const service = services[chietTinh];
        if (service) service(dataSearch);
    };

    return (
        <Row>
            <Col span={18}>
                <Row
                    style={{ marginBottom: "20px", justifyContent: "space-between" }}
                >
                    <Col span={5}>
                        <OutlinedInput
                            endAdornment={
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                            }
                            size="small"
                            placeholder="Mã/Tên nhà cung cấp"
                            value={supplier}
                            onChange={(e) => setSupplier(e.target.value)}
                        />
                    </Col>
                    <Col span={5}>
                        <OutlinedInput
                            endAdornment={
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                            }
                            size="small"
                            placeholder="Mã dịch vụ cung cấp"
                            value={dichVu}
                            onChange={(e) => setDichVu(e.target.value)}
                        />
                    </Col>
                    {chietTinh === 'huongDanVien' &&
                        <Col span={5}>
                            <TextField
                                label={t("tourGuideType")}
                                select
                                fullWidth
                                size="small"
                                name="loaiHdvId"
                                onChange={(event) => handleChangeLoaiHdv(event)}
                                value={loaiHdvId}
                            >
                                {dataQuocGia?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Col>
                    }
                    <Col span={7}>
                        <div style={{ display: "flex" }}>
                            <TextField
                                label={t("location.country")}
                                select
                                fullWidth
                                size="small"
                                name="quocGiaId"
                                onChange={(event) => handleChangeQG(event)}
                                value={quocGiaId}
                            >
                                {dataQuocGia?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label={t("location.city")}
                                select
                                fullWidth
                                size="small"
                                name="tinhId"
                                onChange={(event) => setTinhId(event.target.value)}
                                value={tinhId}
                            >
                                {dataTinhTP?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </Col>
                    <Col span={3}>
                        <Button
                            style={{
                                backgroundColor: "#898989",
                                color: "white",
                                textTransform: "none",
                                width: "100%",
                            }}
                            key="filter"
                            startIcon={<GridFilterAltIcon />}
                            onClick={handleClick}
                            aria-controls={"account-menu"}
                            aria-haspopup="true"
                            aria-expanded={"true"}
                        >
                            {t("filter")}
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            // onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    "&::before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform: "translateY(-50%) rotate(45deg)",
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: "right", vertical: "top" }}
                            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        >
                            <DialogContent>
                                <Row
                                    style={{
                                        display: "flex",
                                        width: "400px",
                                        alignItems: "center",
                                    }}
                                >
                                    <Col span={5}>
                                        <b>{t("exchangeRate.currency")}</b>
                                    </Col>
                                    <Col span={9}>
                                        <TextField
                                            select
                                            fullWidth
                                            size="small"
                                            name="dmTyGiaId"
                                            onChange={(e) => handleChangeLoaiTien(e.target.value)}
                                            value={dmTyGiaId}
                                        >
                                            {dmTyGia?.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Col>
                                </Row>
                                <Divider></Divider>
                                <Row>
                                    <Col span={5}>
                                        <b>{t("priceLevel")}</b>
                                    </Col>
                                </Row>
                                <Row>
                                    <FormGroup
                                        sx={{
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            width: "480px",
                                        }}
                                    >
                                        {dataMucGia.map((item) => {
                                            return (
                                                <FormControlLabel
                                                    key={item.id}
                                                    sx={{ width: "150px" }}
                                                    control={
                                                        <Checkbox
                                                            checked={giaApDungs?.includes(item.id)}
                                                            onClick={(e) => handleCheckMucGia(e, item)}
                                                        />
                                                    }
                                                    label={item.label}
                                                />
                                            );
                                        })}
                                    </FormGroup>
                                </Row>
                                <Divider />
                                <Row>
                                    <Col span={5}>
                                        <b>{t("priceRange")}</b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} />
                                    <Col span={16}>
                                        <Slider
                                            getAriaLabel={() => "Minimum distance"}
                                            value={value}
                                            onChange={handleChange}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetext}
                                            min={0}
                                            max={dmTyGiaId === 1 ? 100000000 : 10000}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} />
                                    <Col span={7}>
                                        <TextField
                                            label={t("tour.oto.minValue")}
                                            value={
                                                dmTyGiaId === 1
                                                    ? `${formatter.format(value[0])} đ`
                                                    : `${formatter.format(value[0])} $`
                                            }
                                            size="small"
                                            disabled
                                        ></TextField>
                                    </Col>
                                    <Col span={2}></Col>
                                    <Col span={7}>
                                        <TextField
                                            label={t("tour.oto.maxValue")}
                                            value={
                                                dmTyGiaId === 1
                                                    ? `${formatter.format(value[1])} đ`
                                                    : `${formatter.format(value[1])} $`
                                            }
                                            size="small"
                                            disabled
                                        ></TextField>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row style={{ justifyContent: "flex-end" }}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            textTransform: "none",
                                            marginRight: "5px",
                                            backgroundColor: "#898989",
                                        }}
                                        onClick={() => handleResetFilter()}
                                    >
                                        {t("action.reset")}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{ textTransform: "none" }}
                                        onClick={handleApplyFilter}
                                    >
                                        {t("action.apply")}
                                    </Button>
                                </Row>
                            </DialogContent>
                        </Menu>
                    </Col>
                    <Col span={3}>
                        <Button
                            variant="contained"
                            sx={{ textTransform: "none" }}
                            onClick={handleSearchChooseService}
                        >
                            {t("search")}
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ModalHeader;