import { PlusOutlined } from "@ant-design/icons";
import { MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Col, Form, Input, Modal, Row, Space, Tag, Upload, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import { API_BASE_URL, maxFile } from "../../../../../constants";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const InfoCard = ({ dataQuocGia, loaiTheHdv, dmLoaiTheHdvId, nameItem, restField }) => {
    const [dataTinhTP, setDataTinhTp] = useState([]);
    const [dataNgonNgu, setDataNgonNgu] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [noiCap, setNoiCap] = useState("");
    const [previewOpen, setPreviewOpen] = useState(false);
    const [quocGiaId, setQuocGiaId] = useState(null);
    const [tinhTP, setTinhTP] = useState(null);
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };
    const { t } = useTranslation();
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmNgoaiNgu",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataDV = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataDV.push({
                                    value: data[i].id,
                                    label: data[i].ngoai_ngu,
                                });
                            }
                        }
                        setDataNgonNgu(dataDV);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const handleChangeQG = (value, item) => {
        setNoiCap(item.props.children);
        setQuocGiaId(item.props.value)
        let data = {
            dm_quoc_gia_id: item.props.value,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachTinhThanhPho",
            data: data,
            headers: authHeader(),
        }).then(function (response) {
            console.log("Response: ====" + response.status);
            if (response.status === 200) {
                let dataTp = [];
                let data = response.data.data;
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc === true) {
                            dataTp.push({
                                value: data[i].id,
                                label: data[i].tinh_thanhpho,
                            });
                        }
                    }
                    setDataTinhTp(dataTp);
                }
            }
        });
    };

    const onChangeTinhTP =(value, item)=> {
        setTinhTP(item.props.value);
        setNoiCap(noiCap + " " + item.props.children);
    }
    const checkName = () => {
        switch (loaiTheHdv) {
            case 1:
                return t("internationalCard");
            case 2:
                return t("localCard");
            case 3:
                return t("locationCard");
            default:
                return null;
        }
    };
    const handleChangeImg = ({ fileList: newFileList }) => {
        console.log("handleChangeImg ====", newFileList);

        if (newFileList.length > 0) {
            for (let i in newFileList) {
                newFileList[i].status = "done";
            }
        }
        setFileList(newFileList);
    };
    const beforeUploadImg = (file) => {
        const isPNG =
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/giff";
        if (!isPNG) {
            message.error(`${file.name} ` + " " + t("errorUploadImg"));
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
            message.error("Image must smaller than 3MB!");
        }

        return isPNG || Upload.LIST_IGNORE;
    };
    const tag = () => {
        switch (dmLoaiTheHdvId) {
            case 1:
                return <Tag color="green">{t("internationalCard")}</Tag>;
            case 2:
                return <Tag color="red">{t("localCard")}</Tag>;
            case 3:
                return <Tag color="orange">{t("locationCard")}</Tag>;
            default:
                return null;
        }
    };
    const checkThe = () => {
        switch (dmLoaiTheHdvId) {
            case 1:
                return (
                    <TextField
                        xs={{
                            borderRadius: "4px",
                        }}
                        label={t("language")}
                        select
                        fullWidth
                        name="ngoaiNgu"
                        onChange={handleChangeQG}
                        value={quocGiaId}
                    >
                        {dataNgonNgu.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            case 2:
                return (
                    <TextField
                        xs={{
                            borderRadius: "4px",
                        }}
                        label={t("language")}
                        select
                        fullWidth
                        name="ngoaiNgu"
                        onChange={handleChangeQG}
                        value={quocGiaId}
                    >
                        {dataNgonNgu.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            case 3:
                return (
                    <TextField
                        name="diemDuLich"
                        id="diemDuLich"
                        sx={{
                            marginBottom: 2,
                        }}
                        required
                        fullWidth
                        label={t("location")}
                        // value={diemDuLich}
                        // onChange={(e) => setDiemDuLich(e.target.value)}
                        // error={!!soDemErrorText}
                        // helperText={soDemErrorText}
                    />
                );
            default:
                return null;
        }
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                {dmLoaiTheHdvId === 1 ? "Upload Image" : "Upload Card"}
            </div>
        </div>
    );
    return (
        <Space direction="vertical" size="small" style={{ display: "flex" }}>
            <Form.Item {...restField} name={[nameItem, "dataNoiCap"]} hidden>
                <Input value={noiCap} />
            </Form.Item>
            <Row>
                <Col xs={18} sm={18} md={18} lg={18} xl={1}></Col>
                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                    {tag()}
                </Col>
            </Row>
            <Row>
                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                            <TextField
                                sx={{
                                    marginBottom: 2,
                                }}
                                fullWidth
                                required
                                label={t("card.code")}
                                // value={soThe}
                                // onChange={(e) => setGhiChu(e.target.value)}
                                // error={!!soDemErrorText}
                                // helperText={soDemErrorText}
                            />
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                            {/*<Form.Item*/}
                            {/*    {...restField}*/}
                            {/*    name={[nameItem, "thoiHanThe"]}*/}
                            {/*    label={t("card.expiration")}*/}
                            {/*    rules={[*/}
                            {/*        {*/}
                            {/*            required: false,*/}
                            {/*            message: t("validate") + " " + t("status"),*/}
                            {/*        },*/}
                            {/*    ]}*/}
                            {/*>*/}
                            {/*<LocalizationProvider dateAdapter={AdapterDayjs} >*/}
                            {/*    <DatePicker />*/}
                            {/*</LocalizationProvider>*/}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label={t("card.expiration")}
                                    // value={value}
                                    // onChange={(newValue) => {
                                    //     setValue(newValue);
                                    // }}
                                    PopperProps={{
                                        sx: {
                                            "&.MuiPickersPopper-root": {
                                                border: "4px solid red",
                                                width: "100%",
                                            },
                                        },
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                            <Space.Compact style={{ width: "100%" }}>
                                <TextField
                                    xs={{
                                        borderRadius: "4px",
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            sx: { height: "300px" },
                                        },
                                    }}
                                    label={t("location.country")}
                                    select
                                    fullWidth
                                    name="quocGiaId"
                                    onChange={handleChangeQG}
                                    value={quocGiaId}
                                >
                                    {dataQuocGia.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    xs={{
                                        borderRadius: "4px",
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            sx: { height: "300px" },
                                        },
                                    }}
                                    label={t("location.city")}
                                    select
                                    fullWidth
                                    name="tinhId"
                                    onChange={onChangeTinhTP}
                                    value={tinhTP}
                                >
                                    {dataTinhTP.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Space.Compact>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "15px" }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                            {checkThe()}
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={12} md={24} lg={24} xl={11}>
                            <TextField
                                name="loaiTheHdv"
                                id="loaiTheHdv"
                                sx={{
                                    marginBottom: 2,
                                }}
                                fullWidth
                                label={t("cardType")}
                                // value={loaiTheHdv}
                                // onChange={(e) => setLoaiTheHdv(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                            <TextField
                                name="kinhNghiem"
                                id="kinhNghiem"
                                sx={{
                                    marginBottom: 2,
                                }}
                                fullWidth
                                type="number"
                                label={t("experience")}
                                // value={kinhNghiem}
                                // onChange={(e) => setKinhNghiem(e.target.value)}
                            />
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                            <TextField
                                name="linkTraCuu"
                                id="linkTraCuu"
                                sx={{
                                    marginBottom: 2,
                                }}
                                fullWidth
                                label={t("lookupLink")}
                                // value={linkTraCuu}
                                // onChange={(e) => setLinkTraCuu(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Row>
                        <Form.Item {...restField} name={[nameItem, "image"]}>
                            <Upload
                                beforeUpload={beforeUploadImg}
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChangeImg}
                            >
                                {fileList.length >= maxFile ? null : uploadButton}
                            </Upload>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                <img
                                    alt="image"
                                    style={{
                                        width: "100%",
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                        </Form.Item>
                    </Row>
                </Col>
            </Row>
        </Space>
    );
};
export default InfoCard;
