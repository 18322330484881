import { Tabs } from "antd";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Contact from "../../common/tabLienHe/Contact";
import AccountBanking from "../../common/tabTKNH/AccountBanking";
import GeneralInfo from "../../common/tabThongTinChung/GeneralInfo";
import DichVuCungCap from "../../common/tabDichVuCC/DichVuCungCap";

const AddHdvCty = () => {
    const onChange = (key) => {
        console.log(key);
    };
    const location = useLocation();
    const [isView, setIsView] = useState(location.pathname.includes("view"));
    const [isNew, setIsNew] = useState(location.pathname.includes("new"));
    const [isDis, setIsDis] = useState(location.pathname.includes("new") ? true : false);
    const [idNCC, setIdNCC] = useState(0);
    useEffect(() => {
        getDvId();
    }, []);
    const getDvId = (value) => {
        let Id = 0;
        if (value === undefined || value === null) {
            if (location.search.length > 0) {
                let id = location.search.split("=");
                Id = id[1];
            }
        } else {
            Id = value;
        }
        setIdNCC(Id);
    };
    const saveNew = (value) => {
        setIdNCC(value);
    };
    const getDv = () => {
        let name = "";
        if (location.pathname.length > 0) {
            let id = location.pathname.split("/");
            name = id[id.length - 2];
        }
        return name;
    };
    const { t } = useTranslation();
    const addId = (status) => {
        console.log("status ", status);
        if (isNew) {
            if (status !== undefined) {
                if (status) {
                    return setIsDis(false);
                } else {
                    return setIsDis(true);
                }
            } else {
                return setIsDis(false);
            }
        } else {
            return setIsDis(false);
        }
    };

    const items = [
        {
            key: "1",
            label: t("hotel.generalInfo"),
            children: <GeneralInfo dvName={getDv()} dvId={idNCC} addId={addId} isView={isView} newId={saveNew} />,

        },
        {
            key: "2",
            label: t("contact"),
            children: <Contact dvName={getDv()} dvId={idNCC} isView={isView} />,
            disabled: isDis,
        },
        {
            key: "3",
            label: t("res.serviceProvided"),
            children: <DichVuCungCap dvName={getDv()} dvId={idNCC} isView={isView} />,
            disabled: isDis,
        },
        {
            key: "4",
            label: t("hotel.accountBanking"),
            children: <AccountBanking dvName={getDv()} dvId={idNCC} isView={isView} />,
            disabled: isDis,
        },
    ];
    return <Tabs defaultActiveKey="1" items={items} onChange={onChange} style={{ marginLeft: "10px" }} />;
};
export default AddHdvCty;
