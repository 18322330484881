import {Affix, Col, Row, Space} from "antd";
import Buttons from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";
import Save from "@mui/icons-material/Save";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Delete from "@mui/icons-material/Delete";

const ButtonSubmit = ({onFinish, handleClear, handleCancels}) => {
    const { t } = useTranslation();
    return (
        <Affix offsetBottom={50}>
            <div
                style={{
                    textAlign: "center",
                    color: "#fff",
                    height: 60,
                    paddingInline: 50,
                    lineHeight: "60px",
                    backgroundColor: "rgba(150, 150, 150, 0.2)",
                    borderRadius: "4px",
                }}
            >
                <Space>
                    <Button variant="contained" type="submit" startIcon={<Save/>} key="clear">
                        {t("action.ok")}
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: "#898989",
                            color: "white",
                        }}
                        key="reset"
                        startIcon={<RestartAlt/>}
                        onClick={(e) => handleClear(e)}
                    >
                        {t("action.reset")}
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<Delete/>}
                        onClick={handleCancels}
                        key="clear"
                    >
                        {t("action.cancel")}
                    </Button>
                </Space>
            </div>
        </Affix>
    )
}
export default ButtonSubmit