import React from "react";
import {  Row } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ButtonThemMoi from "../../layout/ButtonThemMoi";

// const { Search } = Input;

const FormDvKhac = ({ dataAdd, keySide, handleSearch }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const onClickAdd = () => {
        switch (keySide) {
            case 12:
                return navigate("/ncc/dich-vu-khac/new");
            default:
                return null;
        }
    };
    // const onSearch = (value) => {
    //     handleSearch(value);
    // };
    return (
        <Row>
            {/*<Col xs={6} sm={6} md={6} lg={6} xl={6}></Col>*/}
            {/*<Col xs={9} sm={9} md={9} lg={9} xl={9}></Col>*/}
            {/*<Col xs={6} sm={6} md={6} lg={6} xl={6}>*/}
            {/*    <Space size="large">*/}
            {/*        /!*<Button icon={<DownloadOutlined/>} type="default">*!/*/}
            {/*        /!*    {t("exportFile")}*!/*/}
            {/*        /!*</Button>*!/*/}
            {/*        /!*<Button icon={<ImportOutlined/>} type="dashed">*!/*/}
            {/*        /!*    {t("importFile")}*!/*/}
            {/*        /!*</Button>*!/*/}
            {/*        <Button onClick={onClickAdd} icon={<PlusOutlined />} type="primary">*/}
            {/*            {t("add")}*/}
            {/*        </Button>*/}
            {/*    </Space>*/}
            {/*</Col>*/}
            <ButtonThemMoi  onClick={onClickAdd} />
        </Row>
    );
};
export default FormDvKhac;
