import React, {useState, useCallback, useMemo} from 'react';
import {Col, Divider, Row,} from 'antd';
import Header from './thuchitienpt/Header';
import Table from './thuchitienpt/Table';
import ButtonStatus from "./thuchitienpt/ButtonStatus";
import Search from "@mui/icons-material/Search";
import {useTranslation} from "react-i18next";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { InputAdornment,  MenuItem, TextField, Button} from '@mui/material';
import ModalPhieuChi from "./ModalPhieuThu";

dayjs.extend(customParseFormat);

const ThuChiTienPT = () => {
    let formatter = new Intl.NumberFormat("en-US", {});
    const {t} = useTranslation();
    const [textSearch, setTextSearch] = useState("");
    const stats = [
        {label: "Đã thanh toán", value: "04"},
        {label: "Chưa thanh toán", value: "01"},
        {label: "Tổng thu dự toán", value: "900,000 VND"},
        {label: "Tổng thu thực tế", value: "800,000 VND"},
        {label: "Tổng đã thu", value: "800,000 VND"},
        {label: "Tổng thu còn lại", value: "0 VND"},
    ];
    const [thoiGian, setThoiGian] = useState(0);
    const [thoiGianBatDau, setThoiGianBatDau] = useState(null);
    const [thoiGianKetThuc, setThoiGianKetThuc] = useState(null);
    const [thongKe] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [action, setAction] = useState("add");
    const [tranxOpRateId, setTranxOpRateId] = useState(0);
    const [opPhieuChiId, setOpPhieuChiId] = useState(0);
    const handleHidden = () => {
        setIsOpen(false);
    };
    const handleUpdatePhieuChi = (tranxOpRateId, opPhieuChiId) => {
        setTranxOpRateId(tranxOpRateId);
        setOpPhieuChiId(opPhieuChiId);
        setIsOpen(true);
        setAction("edit");
    };

    const handleCreatePhieuChi = (tranxOpRateId, opPhieuChiId) => {
        setTranxOpRateId(tranxOpRateId);
        setOpPhieuChiId(opPhieuChiId);
        setIsOpen(true);
        setAction("add");
    };
    const handleChangeThoiGian = (e) => {
        if (e.target.value === 1) {
            let crr = getCurrDate();
            setThoiGianBatDau(dayjs(crr[0] + "-" + crr[1] + "-" + (crr[2] - 1)));
            setThoiGianKetThuc(dayjs(crr[0] + "-" + crr[1] + "-" + (crr[2] + 1)));
        }
        setThoiGian(e.target.value);
    };
    const getCurrDate = () => {
        let date = new Date();
        return [date.getFullYear(), date.getMonth() + 1, date.getDate()];
    };
    const itemThoiGian = useMemo(() => [
        { value: 1, key: t("thoiGianPhieuThu") },
        { value: 2, key: t("thoiGianDenHan") },
        { value: 3, key: t("thoiGianThanhToan") },
    ], [t]);

    const [currentStatus, setCurrentStatus] = useState("0");
    const statusList = useMemo(() => [
        { value: "0", label: "Tất cả", count: 10 },
        { value: "1", label: "Cần xử lý", count: 5 },
        { value: "2", label: "Đã hoàn thành", count: 3 },
    ], []);

    const onStatusChange = useCallback((status) => {
        setCurrentStatus(status);
    }, []);

    return (
        <>
        <div style={{padding: '20px'}}>
            <Header title="Tổng số lượng phiếu thu" total={4} stats={stats}/>
            <ButtonStatus
                currentStatus={currentStatus}
                onStatusChange ={onStatusChange}
                statusList={statusList}
            />
            <Row style={{marginTop: "20px"}}>
                <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                    <TextField
                        variant="outlined"
                        startAdornment={
                            <InputAdornment position="start">
                                <Search/>
                            </InputAdornment>
                        }
                        fullWidth
                        size="small"
                        placeholder="Mã KH/ Tên KH"
                        value={textSearch}
                        onChange={(e) => setTextSearch(e.target.value)}
                    />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <TextField
                        variant="outlined"
                        label={t("chonThoiGian")}
                        fullWidth
                        size="small"
                        select
                        value={thoiGian}
                        onChange={(e) => handleChangeThoiGian(e)}
                    >
                        {itemThoiGian.map((option) => (
                            <MenuItem key={option.key} value={option.value}>
                                {option.key}
                            </MenuItem>
                        ))}
                    </TextField>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{}}
                            label={t("toDate")}
                            slotProps={{textField: {size: "small"}}}
                            value={thoiGianBatDau}
                            onChange={(newValue) => setThoiGianBatDau(newValue)}
                        />
                    </LocalizationProvider>
                </Col>
                <HorizontalRuleIcon sx={{marginTop: "10px"}}/>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={t("fromDate")}
                            slotProps={{textField: {size: "small"}}}
                            value={thoiGianKetThuc}
                            onChange={(newValue) => setThoiGianKetThuc(newValue)}
                        />
                    </LocalizationProvider>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <Button variant="contained" onClick={() => {
                    }} color="success">
                        {t("search")}
                    </Button>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                    <Button variant="outlined" onClick={() => {handleCreatePhieuChi()
                    }} startIcon={<AddIcon/>} color="warning">
                        {t("add")}
                    </Button>
                </Col>
            </Row>
            <Divider/>
            <Table handleUpdatePhieuChi={handleUpdatePhieuChi}/>
            <Divider/>
            <Row style={{marginTop: "20px"}}>
                <Col xs={15} sm={15} md={15} lg={15} xl={15}></Col>
                <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                    <Row style={{marginTop: "20px"}}>
                        {t("tongChiDuToan")} : {formatter.format(thongKe.tongChiDuToan)}
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        {t("tongChiTT")} : {formatter.format(thongKe.tongChiThucTe)}
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        {t("spent")} : {formatter.format(thongKe.daChi)}
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        {t("conLai")} : {formatter.format(thongKe.conLai)}
                    </Row>
                </Col>
            </Row>
        </div>
            <ModalPhieuChi
                isOpen={isOpen}
                action={action}
                onHidden={handleHidden}
                opThongTinChungId={3}
                opPhieuChiId={opPhieuChiId}
                tranxOpRateId={tranxOpRateId}
            />
        </>
    );
};

export default ThuChiTienPT;
