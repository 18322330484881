import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {useTranslation} from "react-i18next";

const ButtonThemMoi = ({ onClick }) => {
    const { t } = useTranslation();
    return (
        <Button
            sx={{ marginTop: "5px" }}
            variant="outlined"
            onClick={onClick}
            startIcon={<AddIcon />}
            color="warning"
        >
            {t("add")}
        </Button>
    );
};
export default ButtonThemMoi;