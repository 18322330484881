import {CloseOutlined, InboxOutlined, PlusOutlined} from "@ant-design/icons";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import {MenuItem} from "@mui/material";
import Box from "@mui/material/Box";
import Buttons from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {
    Affix,
    Card,
    Cascader,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Space,
    Tag,
    Typography,
    Upload,
    message,
    notification,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import authHeader from "../../../../../api/auth_header";
import "../../../../../assets/css/supplier/GeInfo.css";
import {API_BASE_URL, Ncc_ID, maxFile, TIME_DURATION} from "../../../../../constants";
import InfoCard from "./InfoCard";

const {Dragger} = Upload;
const {Text} = Typography;
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const GeneralInfo = ({dvName, dvId, addId, isView, newId}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [dataDichVu, setDataDichVu] = useState([]);
    const [dataVat, setDataVat] = useState([]);
    const [vatId, setVatId] = useState(0);
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [dataTinhTP, setDataTinhTP] = useState([]);
    const [form] = Form.useForm();
    const [fileListPdf, setFileListPdf] = useState([]);
    const [loaiThe, setLoaiThe] = useState();
    const [loaiTheHdvId, setLoaiTheHdvId] = useState(0);
    const [dmDichVuId, setDmDichVuId] = useState(0);
    const [id, setId] = useState(0);
    const [bottom, setBottom] = React.useState(50);
    const [tinhId, setTinhId] = useState();
    const [huyenId, setHuyenId] = useState();
    const [xaId, setXaId] = useState();
    const [hoVaTen, setHoVaTen] = useState("");
    const [hoVaTenErrorText, setHoVaTenErrorText] = React.useState("");
    const [quocGiaIdErrorText, setQuocGiaIdErrorText] = useState("");
    const [diaChiErrorText, setDiaChiErrorText] = useState("");
    const [boderColorCasca, setBoderColorCasca] = useState("");
    const [colorCasca, setColorCasca] = useState("");
    const [emailErrorText, setEmailErrorText] = useState("");
    const [sdtErrorText, setSdtErrorText] = useState("");
    const [trangThai, setTrangThai] = useState(false);
    const [quocGiaId, setQuocGiaId] = useState(0);
    const [diaChi, setDiaChi] = useState("");
    const [diaChiCuThe, setDiaChiCuThe] = useState("");
    const [email, setEmail] = useState("");
    const [sdt, setSdt] = useState("");
    const [website, setWebsite] = useState("");
    const [fax, setFax] = useState("");
    const [moTa, setMoTa] = useState("");
    const [isCod, setIsCod] = useState(null);
    const [cod, setCod] = useState("");
    const [isVat, setIsVat] = useState(null);
    const [gioiTinh, setGioiTinh] = useState(null);
    const [ngaySinh, setNgaySinh] = useState(null);
    const [cccd, setCccd] = useState(null);
    const [noiCap, setNoiCap] = useState(null);
    const [ghiChu, setGhiChu] = useState(null);
    const [dataNgonNgu, setDataNgonNgu] = useState(null);

    const props = {
        name: "file",
        multiple: true,
        maxCount: maxFile,
        // disabled: fileListPdf.length >= 5? true: false,
        onRemove: (file) => {
            const index = fileListPdf.indexOf(file);
            const newFileList = fileListPdf.slice();
            newFileList.splice(index, 1);
            setFileListPdf(newFileList);
        },
        beforeUpload: (file) => {
            const isPNG = file.type === "application/pdf";
            if (!isPNG) {
                message.error(`${file.name}` + " " + t("errorUploadPDF"));
            }
            const isLt2M = file.size / 1024 / 1024 < 3;
            if (!isLt2M) {
                message.error("Image must smaller than 3MB!");
            }
            return isPNG || Upload.LIST_IGNORE;
            // setFileListPdf([...fileListPdf, file]);
            // return false;
        },
        onChange: ({fileListPdf: newFileList}) => {
            setFileListPdf(newFileList);
        },
        fileListPdf,
    };
    useEffect(() => {

        let apiData = {
            id: dvId,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "nnc/thongtinchung/getDataHdvTuDoById",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let fileImg = [];
                    let filePdf = [];
                    console.log("Response getDataHdvTuDoById: ====", response.data.data);
                    let data = response.data.data;
                    let ngaySinh_ = null;
                    if (data.ngaySinh) ngaySinh_ = data.ngaySinh.split("/");
                    for (let h = 0; h < data.pdfFile.length; h++) {
                        filePdf.push({
                            id: data.pdfFile[h].id,
                            uid: data.pdfFile[h].id,
                            name: data.pdfFile[h].tenFile,
                            status: "done",
                            thumbUrl: "data:application/pdf;base64," + data.pdfFile[h].base64Value,
                            preview: "data:application/pdf;base64," + data.pdfFile[h].base64Value,
                        });
                    }
                    setFileListPdf(filePdf);
                    if (data.imgFile != null || data.imgFile.length > 0) {
                        for (let k = 0; k < data.imgFile.length; k++) {
                            fileImg.push({
                                id: data.imgFile[k].id,
                                uid: data.imgFile[k].id,
                                name: data.imgFile[k].tenFile,
                                status: "done",
                                thumbUrl: "data:image/png;base64," + data.imgFile[k].base64Value,
                                preview: "data:image/png;base64," + data.imgFile[k].base64Value,
                            });
                        }
                    }
                    setFileList(fileImg);
                    setDmDichVuId(data.dmDichVuId);
                    setHoVaTen(data.hoVaTen);
                    setLoaiTheHdvId(data.loaiTheHdv);
                    // setngoaiNgu(data.ngoaiNgu);
                    setTrangThai(data.trangThai);
                    setGioiTinh(data.gioiTinh);
                    if (ngaySinh_) setNgaySinh(dayjs(new Date(ngaySinh_[2], ngaySinh_[1] - 1, ngaySinh_[0])));
                    setCccd(data.cccd);
                    setNoiCap(data.noiCap);
                    setEmail(data.email);
                    setSdt(data.sdt);
                    setTinhId(data.tinhId);
                    setHuyenId(data.huyenId);
                    setXaId(data.xaId);
                    setQuocGiaId(data.quocGiaId);
                    if (data.quocGiaId) handleChangeQG(data.quocGiaId);
                    setDiaChi(data.diaChi);
                    setDiaChiCuThe(data.diaChiCuThe);
                    setMoTa(data.moTa);
                    setGhiChu(data.ghiChu);
                    setIsCod(data.isCod);
                    setCod(data.cod);
                    setVatId(data.vatId);
                    setIsVat(data.isVat);
                    setId(data.id);
                    setDmDichVuId(data.dmDichVuId);
                    setValueRadio(data.isCod ? 1 : 2);
                    setValueRadioPay(data.isVat ? 1 : 2);
                    setVatId(data.vatId);
                    if (data.isCod) {
                        setCodTrue(data.cod);
                    } else {
                        setCodFalse(data.cod);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, [dvId, form]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmNgoaiNgu",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataDV = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataDV.push({
                                    value: data[i].id,
                                    label: data[i].ngoai_ngu,
                                });
                            }
                        }
                        setDataNgonNgu(dataDV);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmDichVu",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataDV = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataDV.push({
                                    value: data[i].id,
                                    label: data[i].ten_dich_vu,
                                });
                            }
                        }
                        setDataDichVu(dataDV);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmLoaiTheHdv",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataDV = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataDV.push({
                                    value: data[i].id,
                                    label: data[i].ten_dich_vu,
                                });
                            }
                        }
                        setDataDichVu(dataDV);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);

    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmVat",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataVat = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataVat.push({
                                    value: data[i].id,
                                    label: data[i].vat,
                                });
                            }
                        }
                        setDataVat(dataVat);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const dataTrangThai = [
        {
            label: t("active"),
            value: true,
        },
        {
            label: t("deactivate"),
            value: false,
        },
    ];
    const dataGioiTinh = [
        {
            label: t("male"),
            value: "Nam",
        },
        {
            label: t("female"),
            value: "Nữ",
        },
    ];
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachQuocGia",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataQG = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQG.push({
                                    value: data[i].id,
                                    label: data[i].quoc_gia,
                                });
                            }
                        }
                        setDataQuocGia(dataQG);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const [valueRadio, setValueRadio] = useState("");
    const [valueRadioPay, setValueRadioPay] = useState("");
    const onChangeRadio = (e) => {
        console.log("radio checked", e.target.value);
        setValueRadio(e.target.value);
    };
    const onChangeRadioPay = (e) => {
        console.log("radio checked", e.target.value);
        setValueRadioPay(e.target.value);
    };
    const [codTrue, setCodTrue] = useState(0);
    const [codFalse, setCodFalse] = useState(0);
    // const handleInputChange = (e) => {
    //     if (e.target.name === "codTrue") {
    //         setCodTrue(e.target.value);
    //     } else {
    //         setCodFalse(e.target.value);
    //     }
    // };
    const [fileList, setFileList] = useState([]);
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };
    const handleChangeQG = (value) => {
        setQuocGiaId(value);
        console.log(`selected ${value}`);
        let data = {
            dm_quoc_gia_id: value,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachDiaDiem",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;

                    if (data.length > 0) {
                        let result = data.reduce((acc, o) => {
                            let tinhId = o.dm_tinh_id;
                            let cur = acc[tinhId];
                            if (!cur) {
                                cur = {
                                    value: o.tinh_thanhpho,
                                    label: o.tinh_thanhpho,
                                    id: o.dm_tinh_id,
                                    children: [],
                                };
                                acc[tinhId] = cur;
                            }
                            cur.children.push(o);
                            return acc;
                        }, {});
                        result = Object.values(result).map((v) => {
                            let dic = v.children.reduce((acc, o) => {
                                let huyenId = o.dm_huyen_id;
                                let cur = acc[huyenId];
                                if (!cur) {
                                    cur = {value: o.quan_huyen, label: o.quan_huyen, id: o.dm_huyen_id, children: []};
                                    acc[huyenId] = cur;
                                }
                                cur.children.push({
                                    value: o.phuong_xa,
                                    label: o.phuong_xa,
                                    id: o.dm_xa_id,
                                });
                                return acc;
                            }, {});
                            v.children = Object.values(dic);
                            return v;
                        });
                        setDataTinhTP(result);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    const handleExit = () => {
        navigate("/ncc/" + dvName);
    };
    const handleChangeImg = ({fileList: newFileList}) => {
        console.log("handleChangeImg ====", newFileList);

        if (newFileList.length > 0) {
            for (let i in newFileList) {
                newFileList[i].status = "done";
            }
        }
        setFileList(newFileList);
    };
    const beforeUploadImg = (file) => {
        const isPNG =
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/giff";
        if (!isPNG) {
            message.error(`${file.name} ` + " " + t("errorUploadImg"));
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
            message.error("Image must smaller than 3MB!");
        }

        return isPNG || Upload.LIST_IGNORE;
    };
    const onChangeCascader = (value, selectedOptions) => {
        setDiaChi(value ? value.join(",") : "");
        console.log(value, selectedOptions);
        if (selectedOptions.length > 0) {
            for (let i = 0; i < selectedOptions.length; i++) {
                if (i === 0) {
                    setTinhId(selectedOptions[i].id);
                }
                if (i === 1) {
                    setHuyenId(selectedOptions[i].id);
                }
                if (i === 2) {
                    setXaId(selectedOptions[i].id);
                }
            }
        }
    };
    const filterCascader = (inputValue, path) => {
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    };
    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    // const isEmpty = (record) => {
    //     for (const prop in record) {
    //         if (Object.hasOwn(record, prop)) {
    //             return false;
    //         }
    //     }
    //
    //     return true;
    // };
    const onFinish = async (values) => {
        console.log(values);
        var error = false;
        if (!hoVaTen) {
            setHoVaTenErrorText(t("validate") + " " + t("maNcc").toLowerCase() + "!");
            error = true;
        } else {
            setHoVaTenErrorText("");
        }
        if (!sdt) {
            setSdtErrorText(t("validate") + " " + t("phone").toLowerCase() + "!");
            error = true;
        } else {
            setSdtErrorText("");
        }
        if (!email) {
            setEmailErrorText(t("validate") + " " + t("email").toLowerCase() + "!");
            error = true;
        } else {
            setEmailErrorText("");
        }
        if (!quocGiaId) {
            setQuocGiaIdErrorText(t("validate") + " " + t("location.country").toLowerCase() + "!");
            error = true;
        } else {
            setQuocGiaIdErrorText("");
        }
        if (!diaChi) {
            setDiaChiErrorText(t("validate") + " " + t("address").toLowerCase() + "!");
            error = true;
        } else {
            setDiaChiErrorText("");
        }
        if (error) return;
        let dataImg = [];
        if (fileList.length > 0) {
            for (let j in fileList) {
                dataImg.push({
                    id: fileList[j].id !== undefined ? fileList[j].id : 0,
                    tenFile: fileList[j].name,
                    base64Value: fileList[j].thumbUrl.split("base64,")[1],
                });
            }
        }
        let dataPdf = [];
        if (fileListPdf.length > 0) {
            for (let k in fileListPdf) {
                let base64 = fileListPdf[k].id !== undefined ? fileListPdf[k].thumbUrl : await getBase64(fileListPdf[k].originFileObj);
                dataPdf.push({
                    id: fileListPdf[k].id !== undefined ? fileListPdf[k].id : 0,
                    tenFile: fileListPdf[k].name,
                    base64Value: base64.split("base64,")[1],
                });
            }
        }
        let cod = null;
        let isVat = null;
        let isCod = null;
        if (valueRadioPay !== undefined) {
            cod = valueRadioPay === 1 ? codTrue : codFalse;
            isCod = valueRadioPay === 1 ? true : false;
        }
        if (valueRadio !== undefined) {
            isVat = valueRadio === 1 ? true : false;
        }
        let dvId = dmDichVuId === 0 && dmDichVuId === null && dmDichVuId === undefined ? Ncc_ID[dvName] : dmDichVuId;
        var theHdvRequests = [];
        if (values && values.items) {
            values.items.forEach((element) => {
                var dmNgoaiNguId = [];
                if (element.ngoaiNgu) {
                    element.ngoaiNgu.forEach((nn) => {
                        var label = dataNgonNgu.find((x) => x.name === nn);
                        dmNgoaiNguId.push({
                            id: nn,
                            ngoaiNgu: label,
                        });
                    });
                }
                theHdvRequests.push({
                    soThe: element.soThe,
                    dmLoaiTheHdvId: element.dmLoaiTheHdvId,
                    loaiTheHdv: element.loaiTheHdv,
                    dmNgoaiNguId: dmNgoaiNguId,
                    thoiHanTheTu: element.thoiHanThe,
                    thoiHanTheDen: element.thoiHanThe,
                    quocGiaId: element.noiCap.quocGiaId,
                    tinhId: element.noiCap.tinhId,
                    noiCap: "Việt Nam- Hà Nội",
                    kinhNghiem: "5 năm",
                    diemDuLich: "Du lịch lăng bác",
                    linkTraCuu: "https://vnexpress.net/",
                    imgFile: {
                        ten_file: "",
                        base64Value: "",
                    },
                });
            });
        }
        let data = {
            id: id,
            hoVaTen: hoVaTen,
            email: email,
            sdt: sdt,
            quocGiaId: quocGiaId,
            dmDichVuId: dmDichVuId !== 0 && dmDichVuId !== null ? dmDichVuId : Ncc_ID[dvName],
            diaChi: diaChi,
            tinhId: tinhId,
            huyenId: huyenId,
            xaId: xaId,
            pdfFile: dataPdf,
            imgFile: dataImg,
            trangThai: trangThai !== undefined ? trangThai : null,
            isVat: isVat,
            isCod: isCod,
            cod: cod,
            gioiTinh: gioiTinh !== undefined ? gioiTinh : null,
            ngaySinh: ngaySinh !== undefined ? ngaySinh.format("DD/MM/YYYY") : null,
            cccd: cccd !== undefined ? cccd : null,
            noiCap: noiCap !== undefined ? noiCap : null,
            fax: fax !== undefined ? fax : null,
            diaChiCuThe: diaChiCuThe !== undefined ? diaChiCuThe : null,
            website: website !== undefined ? website : null,
            moTa: moTa !== undefined ? moTa : null,
            vatId: vatId !== undefined ? vatId : 0,
            ghiChu: ghiChu !== undefined ? ghiChu : null,
            theHdvRequests: values && values.items ? values.items : [],
        };
        console.log("Received data of form: ", JSON.stringify(data));
        axios({
            method: "post",
            url: API_BASE_URL + "nnc/thongtinchung/saveHdvTuDo",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response ====: ", response);
                if (response.status === 200 && response.data.code === "00") {
                    newId(response.data.data);
                    addId(true);
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    addId(false);
                    api["error"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(function (response) {
                addId(false);
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase() + " " + response.message,
                    duration: TIME_DURATION,
                });
            });
    };
    const handleClear = () => {
        form.resetFields();
    };
    const handleCancels = () => {
        form.resetFields();
        navigate("/ncc/" + dvName);
    };
    const [theHdvRequests, setTheHdvRequests] = useState([
        {
            soThe: "",
            dmLoaiTheHdvId: "",
            loaiTheHdv: 0,
            dmNgoaiNguId: [],
            thoiHanTheTu: "",
            thoiHanTheDen: "",
            quocGiaId: 0,
            tinhId: 0,
            noiCap: "",
            kinhNghiem: "",
            diemDuLich: "",
            linkTraCuu: "",
            imgFile: null,
            DataTinhTp: [],
            DataQG: [],
            dataImg: [],
        },
    ]);

    const dataRender = () => {
        let html;
        html = (
            <Space direction="vertical" size="small" style={{display: "flex"}}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={18}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    name="hoVaTen"
                                    id="hoVaTen"
                                    fullWidth
                                    required
                                    label={t("fullName")}
                                    value={hoVaTen}
                                    onChange={(e) => setHoVaTen(e.target.value)}
                                    error={!!hoVaTenErrorText}
                                    helperText={hoVaTenErrorText}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    name="trangThai"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    label={t("status")}
                                    select
                                    fullWidth
                                    value={trangThai}
                                    onChange={(e) => setTrangThai(e.target.value)}
                                >
                                    {dataTrangThai.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        sx={{
                                            marginBottom: 2,
                                        }}
                                        label={t("dob")}
                                        slotProps={{textField: {fullWidth: true}}}
                                        value={ngaySinh}
                                        onChange={(value) => setNgaySinh(value)}
                                    />
                                </LocalizationProvider>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("sex")}
                                    select
                                    value={gioiTinh}
                                    onChange={(e) => setGioiTinh(e.target.value)}
                                >
                                    {dataGioiTinh.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    name="cccd"
                                    id="cccd"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("cccd")}
                                    value={cccd}
                                    onChange={(e) => setCccd(e.target.value)}
                                    inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("noiCap")}
                                    value={noiCap}
                                    onChange={(e) => setNoiCap(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("email")}
                                    type="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={!!emailErrorText}
                                    helperText={emailErrorText}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("phone")}
                                    required
                                    maxLength={11}
                                    minLength={10}
                                    value={sdt}
                                    onChange={(e) => setSdt(e.target.value)}
                                    inputProps={{inputMode: "numeric", pattern: "[0-11]*"}}
                                    error={!!sdtErrorText}
                                    helperText={sdtErrorText}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <Space.Compact
                                    size="large"
                                    style={{
                                        width: "100%",
                                        marginBottom: 15,
                                        height: "56px",
                                    }}
                                    className=""
                                >
                                    <Input
                                        style={{
                                            width: "15%",
                                            borderRadius: "4px",
                                            color: {colorCasca},
                                            borderColor: {boderColorCasca},
                                        }}
                                        readOnly
                                        defaultValue={t("address") + " *"}
                                    />
                                    <TextField
                                        xs={{
                                            width: "85%",
                                            borderRadius: "4px",
                                        }}
                                        SelectProps={{
                                            MenuProps: {
                                                sx: { height: "300px" },
                                            },
                                        }}
                                        required
                                        label={t("location.country")}
                                        select
                                        fullWidth
                                        name="quocGiaId"
                                        onChange={(e) => handleChangeQG(e.target.value)}
                                        value={quocGiaId}
                                        error={!!quocGiaIdErrorText}
                                        helperText={quocGiaIdErrorText}
                                    >
                                        {dataQuocGia.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Space.Compact>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                <Cascader
                                    style={{
                                        width: "100%",
                                        borderRadius: "4px",
                                    }}
                                    size="large"
                                    options={dataTinhTP}
                                    onChange={onChangeCascader}
                                    showSearch={{
                                        filterCascader,
                                    }}
                                    status={diaChiErrorText ? "error" : ""}
                                    placeholder={
                                        t("location.city") +
                                        " - " +
                                        t("location.district") +
                                        " - " +
                                        t("location.wards")
                                    }
                                    onSearch={(value) => console.log(value)}
                                    value={diaChi ? diaChi.split(",") : []}
                                />
                                {diaChiErrorText ? <Text type="danger">{diaChiErrorText}</Text> : <></>}
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("addressDetail")}
                                    value={diaChiCuThe}
                                    onChange={(e) => setDiaChiCuThe(e.target.value)}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("website")}
                                    name="website"
                                    value={website}
                                    onChange={(e) => setWebsite(e.target.value)}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("description")}
                                    multiline
                                    rows={3}
                                    value={moTa}
                                    onChange={(e) => setMoTa(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                <Dragger {...props}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined/>
                                    </p>
                                    <p className="ant-upload-text">{t("ClickorDag")}</p>
                                    <p className="ant-upload-hint">{t("validateUploadPdf")}</p>
                                </Dragger>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                        <Row>
                            <Upload
                                beforeUpload={beforeUploadImg}
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChangeImg}
                            >
                                {fileList.length >= maxFile ? null : uploadButton}
                            </Upload>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                <img
                                    alt="image"
                                    style={{
                                        width: "100%",
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                        </Row>

                        <Row>
                            <Space direction="vertical">
                                <h2>{t("paymentTime")}</h2>
                                <Row>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={valueRadioPay}
                                        onChange={onChangeRadioPay}
                                    >
                                        <Space direction="horizontal">
                                            <FormControlLabel value={1} control={<Radio/>} label={t("payBeforeUse")}/>
                                            <TextField
                                                label={t("day")}
                                                value={codTrue}
                                                size="small"
                                                onChange={(e) => setCodTrue(e.target.value)}
                                                style={{
                                                    width: 70,
                                                    marginLeft: 10,
                                                }}
                                                inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                                            />
                                        </Space>
                                        <Space direction="horizontal">
                                            <FormControlLabel value={2} control={<Radio/>} label={t("payAfterUse")}/>
                                            <TextField
                                                label={t("day")}
                                                value={codFalse}
                                                size="small"
                                                onChange={(e) => setCodFalse(e.target.value)}
                                                style={{
                                                    width: 70,
                                                    marginLeft: 25,
                                                }}
                                                inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                                            />
                                        </Space>
                                    </RadioGroup>
                                </Row>
                            </Space>
                        </Row>

                        <Row style={{marginTop: "5%"}}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={10}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    select
                                    label={t("tax") + " " + t("vat")}
                                    fullWidth
                                    value={isVat}
                                    onChange={(e) => setIsVat(e.target.value)}
                                >
                                    {dataVat.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                        <Row>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={valueRadio}
                                onChange={onChangeRadio}
                            >
                                <FormControlLabel value={1} control={<Radio/>} label={t("priceIncludeTax")}/>
                                <FormControlLabel value={2} control={<Radio/>} label={t("priceNotIncludeTax")}/>
                            </RadioGroup>
                        </Row>
                    </Col>
                </Row>
            </Space>
        );
        return html;
    };
    return (
        <Box>
            {contextHolder}
            {dataRender()}
            <Form onFinish={onFinish}>
                <Form.List name="items">
                    {(fields, {add, remove}) => (
                        <div
                            style={{
                                display: "flex",
                                rowGap: 16,
                                flexDirection: "column",
                            }}
                        >
                            <Row>
                                <Space direction="horizontal" size="small" style={{display: "flex"}}>
                                    <h4>{t("informationTourCard")}</h4>
                                    <Tag
                                        color="green"
                                        onClick={() => {
                                            add(t("internationalCard"), 0);
                                            //handleThemMoi(1);
                                        }}
                                    >
                                        {t("internationalCard")}
                                    </Tag>
                                    <Tag
                                        color="red"
                                        onClick={() => {
                                            add(t("localCard"), 0);
                                            //handleThemMoi(2);
                                        }}
                                    >
                                        {t("localCard")}
                                    </Tag>
                                    <Tag
                                        color="orange"
                                        onClick={() => {
                                            add(t("locationCard"), 0);
                                            //handleThemMoi(3);
                                        }}
                                    >
                                        {t("locationCard")}
                                    </Tag>
                                </Space>
                            </Row>
                            {fields.map((field, index) => (
                                <Card size="large" key={index}
                                      title={
                                          <Form.Item
                                              {...field}
                                              // style={{
                                              //     backgroundColor: "#f0f2f5",
                                              // }}
                                          >
                                              <Input style={{
                                                  width: '103px',
                                                  borderColor: '#0958d9',
                                                  color: '#0958d9',
                                              }}/>

                                          </Form.Item>
                                      }
                                      extra={
                                          <CloseOutlined
                                              onClick={() => {
                                                  remove(index);
                                              }}
                                          />
                                      }
                                >
                                    {/*<Form.Item {...field} name={[index, "id"]}></Form.Item>*/}

                                    {/*<Form.Item {...field} name={[index, "dmLoaiTheHdvId"]} hidden={true}>*/}
                                    {/*    <Input*/}
                                    {/*    />*/}
                                    {/*</Form.Item>*/}
                                    <InfoCard
                                        dataQuocGia={dataQuocGia}
                                        loaiTheHdv={loaiThe}
                                        dmLoaiTheHdvId={loaiTheHdvId}
                                        nameItem={index}
                                        restField={field}
                                    />

                                </Card>
                            ))}
                        </div>
                    )}
                </Form.List>
                <Col xs={24} sm={24} md={24} lg={24} xl={18}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                            <TextField
                                name="ghiChu"
                                id="ghiChu"
                                sx={{
                                    marginBottom: 2,
                                }}
                                multiline
                                fullWidth
                                label={t("notes")}
                                value={ghiChu}
                                onChange={(e) => setGhiChu(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Col>
                <Affix offsetBottom={bottom}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={2} xl={17}></Col>
                        <Col xs={24} sm={24} md={24} lg={18} xl={7}>
                            <div
                                style={{
                                    textAlign: "center",
                                    color: "#fff",
                                    height: 60,
                                    paddingInline: 50,
                                    lineHeight: "60px",
                                    backgroundColor: "rgba(150, 150, 150, 0.2)",
                                    borderRadius: "4px",
                                }}
                            >
                                {isView ? (
                                    <Buttons key="back" onClick={handleExit}>
                                        {t("exit")}
                                    </Buttons>
                                ) : (
                                    <Space>
                                        <Buttons variant="contained" type="submit" endIcon={<SaveIcon/>}>
                                            {t("action.ok")}
                                        </Buttons>
                                        <Buttons
                                            style={{
                                                backgroundColor: "#898989",
                                                color: "white",
                                            }}
                                            key="reset"
                                            startIcon={<RestartAltIcon/>}
                                            onClick={(e) => handleClear(e)}
                                        >
                                            {t("action.reset")}
                                        </Buttons>
                                        <Buttons
                                            variant="outlined"
                                            startIcon={<DeleteIcon/>}
                                            onClick={handleCancels}
                                            key="clear"
                                        >
                                            {t("action.cancel")}
                                        </Buttons>
                                    </Space>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Affix>
            </Form>
        </Box>
    );
};
export default GeneralInfo;
