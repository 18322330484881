import { Button } from "@mui/material";
import { Col, Row } from "antd";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { t } from "i18next";

const ModalFooter = ({onSaveChooseService, onResetChooseService, onCancelChooseService}) => {
    return (
        <Row style={{ justifyContent: "center" }}>
            <Col span={12}></Col>
            <Col span={12}>
                <Button
                    variant="contained"
                    sx={{ marginRight: "5px" }}
                    onClick={onSaveChooseService}
                    startIcon={<SaveIcon />}
                >
                    {t("action.ok")}
                </Button>
                <Button
                    key="reset"
                    startIcon={<RestartAltIcon />}
                    style={{
                        backgroundColor: "#898989",
                        color: "white",
                        marginRight: "5px",
                    }}
                    onClick={onResetChooseService}
                >
                    {t("action.reset")}
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    onClick={onCancelChooseService}
                >
                    {t("action.cancel")}
                </Button>
            </Col>
        </Row>
    )

}

export default ModalFooter;