import React, { useRef, useEffect } from "react";

const ArrowCanvas = ({ lines }) => {
    const canvasRef = useRef();
    let ctx = null;

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientHeight;
        ctx = canvas.getContext("2d");

        // Clear canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw lines
        lines.forEach((line) => drawArrowLine(line));
    }, [lines]);

    const drawArrowhead = (fromX, fromY, toX, toY, arrowSize = 10) => {
        const angle = Math.atan2(toY - fromY, toX - fromX);

        ctx.beginPath();
        ctx.moveTo(toX, toY);
        ctx.lineTo(
            toX - arrowSize * Math.cos(angle - Math.PI / 6),
            toY - arrowSize * Math.sin(angle - Math.PI / 6)
        );
        ctx.lineTo(
            toX - arrowSize * Math.cos(angle + Math.PI / 6),
            toY - arrowSize * Math.sin(angle + Math.PI / 6)
        );
        ctx.closePath();
        ctx.fillStyle = "black";
        ctx.fill();
    };

    const drawArrowLine = ({ x, y, x1, y1, color = "black", width = 2 }) => {
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x1, y1);
        ctx.strokeStyle = color;
        ctx.lineWidth = width;
        ctx.stroke();

        // Chỉ vẽ mũi tên nếu đường nằm ngang
        if (Math.abs(y - y1) <= 1) {
            console.log("Drawing arrow for horizontal line:", { x, y, x1, y1 });
            drawArrowhead(x, y, x1, y1);
        } else {
            console.log("Skipping arrow for non-horizontal line:", { x, y, x1, y1 });
        }
    };



    return <canvas ref={canvasRef} style={{ width: "100%", height: "100%" }} />;
};

export default ArrowCanvas;
