import React from "react";
import {Box, Card, CardContent, Divider, ListItemIcon, Typography,} from "@mui/material";
import {Assignment, FactCheck, PriceCheck} from "@mui/icons-material";
import RefreshableText from "./RefeshIcon/RefreshableText";
import ArrowCanvas from "./ArrowCanvas/ArrowCanvas";

const StatCard = ({title, amount, time, handleRefresh}) => (
    <Card elevation={2} sx={{borderRadius: 2}}>
        <CardContent
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                gap: 2,
            }}
        >
            <RefreshableText title={title} handleRefresh={handleRefresh}/>
            <Box>
                <Typography
                    color="primary"
                    variant="h5"
                    component="div"
                    sx={{fontWeight: "bold", textAlign: "right"}}
                >
                    {amount} đ
                </Typography>
                <Typography
                    variant="caption"
                    color="text.secondary"
                >
                    Số liệu tính đến: {time}
                </Typography>
            </Box>
        </CardContent>
    </Card>
);

const WorkflowStep = ({icon, title, subtitle}) => (
    <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
        <ListItemIcon
            sx={{
                minWidth: "auto",
                padding: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18rem",
                color: "black",
            }}
        >
            {icon}
        </ListItemIcon>
        <Typography variant="subtitle2" color="primary" textAlign="center" fontWeight="bold">
            {title}
        </Typography>
        <Typography variant="caption" color="text.secondary" textAlign="center">
            {subtitle}
        </Typography>
    </Box>
);



const ThuChiTienQT = () => {

    const linesForThuTien = [
        { x: 130, y: 40, x1: 130, y1: 120 },
        { x: 130, y: 75, x1: 200, y1: 75 },
    ];

    const linesForChiTien = [
        { x: 115, y: 40, x1: 115, y1: 120 },
        { x: 115, y: 75, x1: 200, y1: 75 },
    ];

    const handleStatRefresh = (type) => {
        console.log(`Refreshing data for: ${type}`);
    };

    return (
        <Box p={3}>
            {/* Statistics Cards */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))"
                gap={3}
                mb={4}
            >
                <StatCard
                    title="Tổng thu từ đầu năm đến hiện tại"
                    amount="20,000,000"
                    time="18:20"
                    handleRefresh={() => handleStatRefresh("Tổng thu")}
                />
                <StatCard
                    title="Tổng chi từ đầu năm đến hiện tại"
                    amount="10,000,000"
                    time="18:20"
                />
                <StatCard
                    title="Tồn quỹ đến hiện tại"
                    amount="10,000,000"
                    time="18:20"
                />
            </Box>

            {/* Workflow and Reports Section */}
            <Box p={4}>
                {/* Workflow and Reports Section */}
                <Box display="grid" gridTemplateColumns="3fr 1fr" gap={4}>
                    {/* Workflow Section */}
                    <Box display="grid" gap={3}>
                        {/* Workflow: Quy trình thu tiền */}
                        <Box
                            sx={{
                                padding: 3,
                                border: "1px solid #ddd",
                                borderRadius: 3,
                                backgroundColor: "#fafafa",
                            }}
                        >
                            <Typography
                                variant="h6"
                                textAlign="center"
                                sx={{ fontWeight: "bold", pb: 3 }}
                            >
                                Quy trình thu tiền
                            </Typography>
                            <Divider />
                            <Box
                                sx={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr auto 2fr",
                                    alignItems: "center",
                                    justifyItems: "center",
                                    gap: 4,
                                    mt: 3,
                                }}
                            >
                                {/* Cột trái: 2 bước đầu */}
                                <Box
                                    sx={{
                                        padding: 3,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <WorkflowStep
                                        icon={<Assignment sx={{ fontSize: "2rem" }}/>}
                                        title="SALE"
                                        subtitle="Tạo phiếu thu"
                                    />
                                    <ArrowCanvas lines={linesForThuTien} />
                                    <WorkflowStep
                                        icon={<Assignment sx={{ fontSize: "2rem" }}/>}
                                        title="KẾ TOÁN"
                                        subtitle="Tạo phiếu thu"
                                    />
                                </Box>



                                {/* Cột phải: bước cuối */}
                                <WorkflowStep
                                    icon={<PriceCheck sx={{ fontSize: "2rem" }}/>}
                                    title="KẾ TOÁN"
                                    subtitle="Xác nhận thu"
                                />
                            </Box>
                        </Box>

                        {/* Workflow: Quy trình chi tiền */}
                        <Box
                            sx={{
                                padding: 3,
                                border: "1px solid #ddd",
                                borderRadius: 3,
                                backgroundColor: "#fafafa",
                            }}
                        >
                            <Typography
                                variant="h6"
                                textAlign="center"
                                sx={{ fontWeight: "bold", pb: 3 }}
                            >
                                Quy trình chi tiền
                            </Typography>
                            <Divider />
                            <Box
                                sx={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr auto 1fr auto 1fr",
                                    alignItems: "center",
                                    justifyItems: "center",
                                    gap: 4,
                                    mt: 3,
                                }}
                            >
                                {/* Cột trái: Hai bước đầu */}
                                <Box
                                    sx={{
                                        padding: 3,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <WorkflowStep
                                        icon={<Assignment sx={{ fontSize: "2rem" }}/>}
                                        title="ĐIỀU HÀNH"
                                        subtitle="Tạo phiếu chi"
                                    />
                                    <ArrowCanvas lines={linesForChiTien} />
                                    <WorkflowStep
                                        icon={<Assignment sx={{ fontSize: "2rem" }}/>}
                                        title="KẾ TOÁN"
                                        subtitle="Tạo phiếu chi"
                                    />
                                </Box>



                                {/* Cột giữa: GIÁM ĐỐC */}
                                <WorkflowStep
                                    icon={<FactCheck sx={{ fontSize: "2rem" }}/>}
                                    title="GIÁM ĐỐC"
                                    subtitle="Duyệt chi"
                                />

                                {/* Mũi tên nối cột giữa với cột phải */}

                                {/* Cột phải: Bước cuối */}
                                <WorkflowStep
                                    icon={<PriceCheck sx={{ fontSize: "2rem" }}/>}
                                    title="KẾ TOÁN"
                                    subtitle="Xác nhận chi"
                                />
                            </Box>
                        </Box>
                    </Box>

                    {/* Reports Section */}
                    <Box
                        sx={{
                            padding: 3,
                            border: "1px solid #ddd",
                            borderRadius: 3,
                            backgroundColor: "#fafafa",
                            minWidth: "400px",
                        }}
                    >
                        <Typography
                            variant="h6"
                            textAlign="center"
                            sx={{ fontWeight: "bold", pb: 3 }}
                        >
                            Báo cáo
                        </Typography>
                        <Box
                            sx={{
                                minHeight: 400,
                                border: "1px solid #ddd",
                                borderRadius: 2,
                                padding: 2,
                            }}
                        >
                            <Typography variant="body2" color="text.secondary">
                                Hiển thị dữ liệu báo cáo tại đây...
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ThuChiTienQT;
