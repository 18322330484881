import React, { useEffect, useState, useCallback } from "react";
import { Space, Input, Modal, Typography, Divider, Form, Row, Col, DatePicker, Card, Switch, Affix } from "antd";
import { useTranslation } from "react-i18next";
import { MinusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { API_BASE_URL, data_getDM } from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import moment from "moment";
import dayjs from "dayjs";
import {Box, Button, MenuItem, TextField} from "@mui/material";
import Save from "@mui/icons-material/Save";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Delete from "@mui/icons-material/Delete";
const { Text } = Typography;
// const EditableContext = React.createContext(null);
const { RangePicker } = DatePicker;
// const { TextArea } = Input;

const ModalPrAction = ({ isOpen, handleCancel, handleFinish, record, action, dvId }) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [tiGia, setTiGia] = useState([]);
    const [dataSourceTable, setDataSourceTable] = useState([]);
    const [idTable, setIdTable] = useState([]);
    const [dmLoaiXeId, setDmLoaiXeId] = useState();
    const [hinhThucGia, setHinhThucGia] = useState([]);
    const [loaiXe, setLoaiXe] = useState([]);
    const [soCho, setSoCho] = useState([]);
    const [id, setId] = useState(null);
    const [nccThongTinChungId, setNccThongTinChungId] = useState(null);
    const [maDichVu, setMaDichVu] = useState(null);
    const [tenDichVu, setTenDichVu] = useState("");
    const [dmTyGiaId, setDmTyGiaId] = useState("");
    const [dmHinhThucGiaId, setDmHinhThucGiaId] = useState("");
    const [ghiChu, setGhiChu] = useState("");
    const [dmSoChoId, setDmSoChoId] = useState("");
    const [tenLoaiXe, setTenLoaiXe] = useState("");
    const [maNgoaiTe, setMaNgoaiTe] = useState("");
    const [kmUocTinh, setKmUocTinh] = useState("");
    const [loaiTien, setLoaiTien] = useState("");

    const [timeErr, setTimetErr] = useState(false);
    const [maDichVuErr, setMaDichVuErr] = useState(false);
    const [tenDichVuErr, setTenDichVuErr] = useState(false);
    const [dmHinhThucGiaIdErr, setDmHinhThucGiaIdErr] = useState(false);
    const [dmSoChoIdErr, setDmSoChoIdErr] = useState(false);
    const [tenLoaiXeErr, setTenLoaiXeErr] = useState(false);

    useEffect(() => {
        // handleClear()
        apiGetData(record.isDouble ? record.nccDichVuOtoId : record.id);
        getInfo();
    }, [record]);
    const apiGetData = useCallback((newId) => {
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvOto/getDataById",
            data: {
                id: newId,
            },
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dataArr = [];
                    var items = [];
                    console.log("Response: ====" + data);
                    if (data.tranxDvOtoTimeRequests.length > 0) {
                        let dataFit = data.tranxDvOtoTimeRequests;

                        for (let k = 0; k < dataFit.length; k++) {
                            var tuNgay = dataFit[k].tuNgay.split("/");
                            var denNgay = dataFit[k].denNgay.split("/");
                            items.push({
                                id: dataFit[k].id,
                                nccDichVuOtoId: dataFit[k].nccDichVuOtoId,
                                rangeTime: [
                                    dayjs(new Date(tuNgay[2], tuNgay[1] - 1, tuNgay[0])),
                                    dayjs(new Date(denNgay[2], denNgay[1] - 1, denNgay[0])),
                                ],
                                trangThaiBooking: dataFit[k].trangThaiBooking,
                                giaNet: dataFit[k].giaNet,
                                giaBan: dataFit[k].giaBan,
                            });
                            dataArr.push({
                                id: dataFit[k].id,
                                nccDichVuOtoId: dataFit[k].nccDichVuOtoId,
                                rangeTime: [
                                    dayjs(new Date(tuNgay[2], tuNgay[1] - 1, tuNgay[0])),
                                    dayjs(new Date(denNgay[2], denNgay[1] - 1, denNgay[0])),
                                ],
                                trangThaiBooking: dataFit[k].trangThaiBooking,
                                giaNet: dataFit[k].giaNet,
                                giaBan: dataFit[k].giaBan,
                            });
                        }
                    }
                    form.setFieldsValue({
                        items: items,
                    });
                    setDataSourceTable(dataArr);
                    setIdTable(data.id);
                    setId(data.id);
                    setNccThongTinChungId(data.nccThongTinChungId);
                    setMaDichVu(data.maDichVu);
                    setTenDichVu(data.tenDichVu);
                    setDmTyGiaId(data.dmTyGiaId);
                    setDmHinhThucGiaId(data.dmHinhThucGiaId);
                    setGhiChu(data.ghiChu);
                    setDmSoChoId(data.dmSoChoId);
                    setDmLoaiXeId(data.dmLoaiXeId);
                    setTenLoaiXe(data.tenLoaiXe);
                    setMaNgoaiTe(data.maNgoaiTe);
                    setKmUocTinh(data.kmUocTinh);
                    setLoaiTien(data.loaiTien);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);
    const getInfo = () => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmTyGia",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].loai_tien,
                            });
                        }
                        setTiGia(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmHinhThucGia",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].hinh_thuc_gia,
                            });
                        }
                        setHinhThucGia(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmLoaiXe",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].ten_loai_xe,
                            });
                        }
                        setLoaiXe(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });

        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmSoCho",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].so_cho_str,
                            });
                        }
                        setSoCho(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    const onFinish = async (values) => {
        setTimetErr(false);
        setTenDichVuErr(false);
        setMaDichVuErr(false);
        setTenLoaiXeErr(false);
        setDmSoChoIdErr(false);
        setDmHinhThucGiaIdErr(false);
        var error = false;
        if (!maDichVu) {
            setMaDichVuErr(true);
            error = true;
        }
        if (!tenDichVu) {
            setTenDichVuErr(true);
            error = true;
        }
        if (!dmLoaiXeId) {
            setTenDichVuErr(true);
            error = true;
        }
        if (!dmHinhThucGiaId) {
            setDmHinhThucGiaIdErr(true);
            error = true;
        }
        if (!dmLoaiXeId) {
            setTenLoaiXeErr(true);
            error = true;
        }
        if (!dmSoChoId) {
            setDmSoChoIdErr(true);
            error = true;
        }
        var tranxDvOtoTimeRequests = [];
        var timeErr = [];
        if (values.items) {
            for (let i = 0; i < values.items.length; i++) {
                const element = values.items[i];
                var time = !element.rangeTime;
                var giaBan = !element.giaBan;
                var giaNet = !element.giaNet;

                timeErr.push({
                    time: time,
                    giaBan: giaBan,
                    giaNet: giaNet,
                });
                if (!time && !giaBan && !giaNet) {
                    error = false;
                    tranxDvOtoTimeRequests.push({
                        tuNgay: element.rangeTime[0].format("DD/MM/YYYY"),
                        denNgay: element.rangeTime[1].format("DD/MM/YYYY"),
                        giaNet: element.giaNet,
                        giaBan: element.giaBan,
                        trangThaiBooking: element.trangThaiBooking ? element.trangThaiBooking : false,
                    });
                } else error = true;
            }
        }
        setTimetErr(timeErr);
        if (error) return;
        let data = {
            id: id, // thêm mới để 0, update truyền id đúng vào
            nccThongTinChungId: nccThongTinChungId,
            dmTyGiaId: dmTyGiaId ? dmTyGiaId : 1,
            maDichVu: maDichVu,
            tenDichVu: tenDichVu,
            dmHinhThucGiaId: dmHinhThucGiaId,
            kmUocTinh: kmUocTinh ? kmUocTinh : 0,
            dmLoaiXeId: dmLoaiXeId,
            dmSoChoId: dmSoChoId,
            loaiTien: tiGia[dmTyGiaId].label,
            ghiChu: ghiChu,
            tranxDvOtoTimeRequests: tranxDvOtoTimeRequests,
            tenLoaiXe: loaiXe[dmLoaiXeId].label,
            hinhThucGia: hinhThucGia[dmHinhThucGiaId - 1].label,
            soCho: soCho[dmSoChoId].label,
        };
        debugger;
        let check = await handleFinish(open, data);
        if (check) {
            handleClear();
        }
    };
    const handleCancels = () => {
        // handleClear();
        handleCancel();
    };
    const dataRender = () => {
        let html;
        html = (
            <>

                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            {/* {tableChildren(dataSourceTable)} */}

                            <Form.List name="items">
                                {(fields, { add, remove }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            rowGap: 16,
                                            flexDirection: "column",
                                        }}
                                    >
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Card
                                                size="large"
                                                key={key}
                                                // extra={
                                                //     <CloseOutlined
                                                //         onClick={() => {
                                                //             remove(field.name);
                                                //         }}
                                                //     />
                                                // }
                                            >
                                                <Row>
                                                    <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, "rangeTime"]}
                                                            label={t("hotel.priceRoom.applicationTime") + " *"}
                                                            {...formItemLayoutDate}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <RangePicker
                                                                style={{ width: "100%" }}
                                                                placeholder={[t("toDate"), t("fromDate")]}
                                                                disabledDate={(current) => {
                                                                    let customDate = moment().format("DD-MM-YYYY");
                                                                    return (
                                                                        current &&
                                                                        current < moment(customDate, "DD-MM-YYYY")
                                                                    );
                                                                }}
                                                                status={timeErr && timeErr[key]?.time ? "error" : ""}
                                                            />

                                                            {timeErr && timeErr[key]?.time ? (
                                                                <Text type="danger">
                                                                    {t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase()}
                                                                </Text>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                        {fields.length > 0 ? (
                                                            <MinusCircleOutlined
                                                                className="dynamic-delete-button"
                                                                onClick={() => remove(name)}
                                                            />
                                                        ) : null}
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "giaNet"]}
                                                                    {...formItemLayout}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaNet"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            marginTop: 2,
                                                                        }}
                                                                        label={t("hotel.priceClass.giaNet") + " *"}
                                                                        fullWidth
                                                                        // error={
                                                                        //     timeErr && timeErr[key]?.giaNet
                                                                        //         ? true
                                                                        //         : false
                                                                        // }
                                                                    ></TextField>
                                                                    {timeErr && timeErr[key]?.giaNet ? (
                                                                        <Text type="danger">
                                                                            {t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaNet"
                                                                                ).toLowerCase()}
                                                                        </Text>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row></Row>
                                                    </Col>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "giaBan"]}
                                                                    {...formItemLayout}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaBan"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            marginTop: 2,
                                                                        }}
                                                                        label={t("hotel.priceClass.giaBan") + " *"}
                                                                        error={
                                                                            timeErr && timeErr[key]?.giaBan
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        fullWidth
                                                                    ></TextField>
                                                                    {timeErr && timeErr[key]?.giaBan ? (
                                                                        <Text type="danger">
                                                                            {t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaBan"
                                                                                ).toLowerCase()}
                                                                        </Text>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "trangThaiBooking"]}
                                                    // label={t("status") + " " + "booking"}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message:
                                                                t("validate") +
                                                                " " +
                                                                t("hotel.priceRoom.applicationTime").toLowerCase(),
                                                        },
                                                    ]}
                                                    valuePropName="checked"
                                                >
                                                    <Switch />
                                                </Form.Item>
                                            </Card>
                                        ))}
                                        <Row>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <Button onClick={() => add()} block>
                                                    {t("add") + " " + t("time").toLowerCase()}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <TextField
                                sx={{
                                    marginTop: 2,
                                }}
                                label={t("notes")}
                                value={ghiChu ? ghiChu : ""}
                                fullWidth
                                // multiline
                                rows={5}
                                onChange={(e) => setGhiChu(e.target.value)}
                            ></TextField>
                        </Col>
                    </Row>

            </>
        );
        return html;
    };
    const formItemLayoutDate = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };
    const formItemLayoutNotes = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 18,
            },
        },
    };
    const handleClear = () => {
        setDmLoaiXeId();
        setId(0);
        setMaDichVu("");
        setTenDichVu("");
        setDmTyGiaId("");
        setDmHinhThucGiaId("");
        setGhiChu("");
        setDmSoChoId("");
        setKmUocTinh("");
        form.resetFields();
    };
    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        setTimetErr(false);
        setTenDichVuErr(false);
        setMaDichVuErr(false);
        setTenLoaiXeErr(false);
        setDmSoChoIdErr(false);
        setDmHinhThucGiaIdErr(false);
        var error = false;
        if (!maDichVu) {
            setMaDichVuErr(true);
            error = true;
        }
        if (!tenDichVu) {
            setTenDichVuErr(true);
            error = true;
        }
        if (!dmLoaiXeId) {
            setTenDichVuErr(true);
            error = true;
        }
        if (!dmHinhThucGiaId) {
            setDmHinhThucGiaIdErr(true);
            error = true;
        }
        if (!dmLoaiXeId) {
            setTenLoaiXeErr(true);
            error = true;
        }
        if (!dmSoChoId) {
            setDmSoChoIdErr(true);
            error = true;
        }
        var timeErr = [];
        for (let i = 0; i < values.items.length; i++) {
            const element = values.items[i];
            var time = !element.rangeTime;
            var giaBan = !element.giaBan;
            var giaNet = !element.giaNet;

            timeErr.push({
                time: time,
                giaBan: giaBan,
                giaNet: giaNet,
            });
        }
        setTimetErr(timeErr);
    };
    return (
        <div>
            <Modal
                title={action ? t("edit") + " " + t("informationRoomClass").toLowerCase() : t("informationRoomClass")}
                open={isOpen}
                // onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1200}
                // bodyStyle={{ background: "#F5F5F5" }}
            >
                <Divider />
                <Box component="form" noValidate>
                    <Row>
                        <Form.Item hidden name="id">
                            <Input />
                        </Form.Item>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        value={maDichVu ? maDichVu : ""}
                                        fullWidth
                                        onChange={(e) => setMaDichVu(e.target.value)}
                                        error={maDichVuErr}
                                        required
                                        label={t("res.code")}
                                        helperText={
                                            maDichVuErr ? t("validate") + " " + t("res.code").toLowerCase() : ""
                                        }
                                    ></TextField>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        sx={{
                                            marginTop: 2,
                                        }}
                                        value={tenDichVu ? tenDichVu : ""}
                                        fullWidth
                                        onChange={(e) => setTenDichVu(e.target.value)}
                                        error={tenDichVuErr}
                                        label={t("res.serviceProvided")}
                                        required
                                        helperText={
                                            tenDichVuErr
                                                ? t("validate") + " " + t("res.serviceProvided").toLowerCase()
                                                : ""
                                        }
                                    ></TextField>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        sx={{
                                            marginTop: 2,
                                        }}
                                        value={dmTyGiaId ? dmTyGiaId : 1}
                                        fullWidth
                                        onChange={(e) => setDmTyGiaId(e.target.value)}
                                        label={t("exchangeRate.currency")}
                                        select
                                    >
                                        {tiGia.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TextField
                                    sx={{
                                        marginTop: 2,
                                    }}
                                    value={dmHinhThucGiaId ? dmHinhThucGiaId : ""}
                                    fullWidth
                                    select
                                    helperText={
                                        dmHinhThucGiaIdErr ? t("validate") + " " + t("formOfPricing").toLowerCase() : ""
                                    }
                                    error={dmHinhThucGiaIdErr}
                                    required
                                    onChange={(e) => setDmHinhThucGiaId(e.target.value)}
                                    label={t("formOfPricing")}
                                >
                                    {hinhThucGia.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        sx={{
                                            marginTop: 2,
                                        }}
                                        label={t("estimatedKm")}
                                        value={kmUocTinh ? kmUocTinh : ""}
                                        disabled={dmHinhThucGiaId === 2 ? true : false}
                                        fullWidth
                                        onChange={(e) => setKmUocTinh(e.target.value)}
                                    ></TextField>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        sx={{
                                            marginTop: 2,
                                        }}
                                        required
                                        value={dmLoaiXeId ? dmLoaiXeId : ""}
                                        fullWidth
                                        error={tenLoaiXeErr}
                                        select
                                        onChange={(e) => setDmLoaiXeId(e.target.value)}
                                        label={t("vehicleType") + " *"}
                                        helperText={
                                            tenLoaiXeErr ? t("validate") + " " + t("vehicleType").toLowerCase() : ""
                                        }
                                    >
                                        {loaiXe.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        sx={{
                                            marginTop: 2,
                                        }}
                                        value={dmSoChoId ? dmSoChoId : ""}
                                        required
                                        fullWidth
                                        error={dmSoChoIdErr}
                                        select
                                        onChange={(e) => setDmSoChoId(e.target.value)}
                                        label={t("number") + " *"}
                                        helperText={dmSoChoIdErr ? t("validate") + " " + t("number").toLowerCase() : ""}
                                    >
                                        {soCho.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Box>
                <Form
                    onFinishFailed={onFinishFailed}
                    form={form}
                    onFinish={onFinish}
                    colon={false}
                    layout="vertical"
                    noValidate
                    // initialValues={{
                    //     items: [{}],
                    // }}
                >
                    {dataRender()}
                    {action ? (
                        <Form.Item
                            wrapperCol={{
                                span: 12,
                                offset: 12,
                            }}
                        >
                            <Affix offsetBottom={50}>
                                <div
                                    style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        height: 60,
                                        paddingInline: 50,
                                        lineHeight: "60px",
                                        backgroundColor: "rgba(150, 150, 150, 0.2)",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <Space>
                                        <Button
                                            variant="contained"
                                            // onClick={onFinish}
                                            type="submit"
                                            startIcon={<Save />}
                                        >
                                            {t("action.ok")}
                                        </Button>
                                        <Button
                                            sx={{
                                                backgroundColor: "#898989",
                                                color: "white",
                                            }}
                                            key="reset"
                                            startIcon={<RestartAlt />}
                                            onClick={(e) => handleClear(e)}
                                        >
                                            {t("action.reset")}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Delete />}
                                            onClick={handleCancels}
                                            key="clear"
                                        >
                                            {t("action.cancel")}
                                        </Button>
                                    </Space>
                                </div>
                            </Affix>
                        </Form.Item>
                    ) : (
                        ""
                    )}
                </Form>
            </Modal>
        </div>
    );
};
export default ModalPrAction;
