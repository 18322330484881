import { DownloadOutlined, ImportOutlined } from "@ant-design/icons";
import { Col, Form, Row, Space, notification, theme } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import {API_BASE_URL, TIME_DURATION} from "../../../../../constants";
import ModalImportFilePR from "./ModalImportFilePR";
import ModalPR from "./ModalPR";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

const { useToken } = theme;

const FormPR = ({ dataAdd, dvId, setReload }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [inputMin, setInputMin] = useState(1);
    // const [inputMax, setInputMax] = useState(100);
    const [api, contextHolder] = notification.useNotification();
    const [isModalOpenUpload, setIsModalOpenUpload] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const showModalUpload = () => {
        setIsModalOpenUpload(true);
    };
    const handleFinish = (status, values) => {
        let data = {
            ...values,
            id: values.id, // thêm mới để 0, update truyền id đúng vào
            nccThongTinChungId: dvId,
        };
        console.log("data =====", data);
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvOto/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response=====", response);
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let dataLst = [];
                    let dataLstSearch = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({});
                            dataLstSearch.push({});
                        }
                        dataAdd(dataLst, dataLstSearch);
                    }
                    setIsModalOpen(status);
                    setReload(true);
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("edit") + " " + t("noti.error").toLowerCase() + ". " + response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleCancelUpload = () => {
        setIsModalOpenUpload(false);
    };
    const handleFinishUpload = () => {};
    const items = [
        {
            key: "1",
            label: <h3>{t("pricingTime")}</h3>,
        },
    ];
    const { token } = useToken();

    // const contentStyle = {
    //     backgroundColor: token.colorBgElevated,
    //     borderRadius: token.borderRadiusLG,
    //     boxShadow: token.boxShadowSecondary,
    //     border: "4px solid #CFC8C8",
    // };
    // const handleClear = () => {
    //     form.resetFields();
    // };
    // const onFinish = (values) => {};
    // const onChangeSlider = (newValue) => {
    //     setInputMin(newValue[0]);
    //     setInputMax(newValue[1]);
    // };
    // const onChangeIpMin = (newValue) => {
    //     setInputMin(newValue);
    // };
    // const onChangeIpMax = (newValue) => {
    //     setInputMax(newValue);
    // };
    return (
        <Row>
            {contextHolder}
            <Col xs={10} sm={10} md={10} lg={10} xl={4}></Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8}></Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={14}>
                <Space size="small">
                    {/*<Button icon={<DownloadOutlined />}>{t("exportFile")}</Button>*/}

                    {/*<Button icon={<ImportOutlined />} onClick={showModalUpload} type="dashed">*/}
                    {/*    {t("importFile")}*/}
                    {/*</Button>*/}
                    {/*<Button onClick={showModal} icon={<PlusOutlined />} type="primary">*/}
                    {/*    {t("add")}*/}
                    {/*</Button>*/}
                    <Button variant="outlined" startIcon={<DownloadOutlined />}>
                        {t("exportFile")}
                    </Button>
                    <Button variant="outlined" startIcon={<ImportOutlined />} onClick={showModalUpload} type="dashed">
                        {t("importFile")}
                    </Button>
                    {/*<Button onClick={showModal} icon={<PlusOutlined />} type="primary">*/}
                    {/*    {t("add")}*/}
                    {/*</Button>*/}
                    <Button
                        sx={{ marginTop: "5px" }}
                        variant="outlined"
                        onClick={showModal}
                        startIcon={<AddIcon />}
                        color="warning"
                    >
                        {t("add")}
                    </Button>
                    <ModalImportFilePR
                        isOpen={isModalOpenUpload}
                        handleCancel={handleCancelUpload}
                        handleFinish={handleFinishUpload}
                    ></ModalImportFilePR>
                    <ModalPR isOpen={isModalOpen} handleCancel={handleCancel} handleFinish={handleFinish} dvId={dvId} />
                </Space>
            </Col>
        </Row>
    );
};
export default FormPR;
