import { MinusCircleOutlined } from "@ant-design/icons";
import {Box, Button, MenuItem, TextField} from "@mui/material";
import { Card, Col, DatePicker, Divider, Form, Input, Modal, Row, Space, Switch } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../api/auth_header";
import "../../../../assets/css/supplier/oto/dvCc/modal.css";
import {API_BASE_URL, data_getDM, MAX_HEIGHT_SELECT} from "../../../../constants";
import ButtonSubmit from "../../../layout/ButtonSubmit";

const { RangePicker } = DatePicker;

const ModalPR = ({ isOpen, handleCancel, handleFinish, dvId  }) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [tiGia, setTiGia] = useState([]);
    const [maDichVu, setMaDichVu] = useState("");
    const [dmTyGiaId, setDmTyGiaId] = useState(1);
    const [tenDichVu, setTenDichVu] = useState("");
    const [ghiChu, setGhiChu] = useState("");
    const [maDichVuErr, setMaDichVuErr] = useState(false);
    const [tenDichVuErr, setTenDichVuErr] = useState(false);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmTyGia",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].loai_tien,
                            });
                        }
                        setTiGia(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);

    const onFinish = (values) => {
        setMaDichVuErr(false);
        setTenDichVuErr(false);
        var error = false;
        if (!maDichVu) {
            setMaDichVuErr(true);
            error = true;
        }
        if (!tenDichVu) {
            setTenDichVuErr(true);
            error = true;
        }
        var tranxDichVuKhacRequests = [];
        if (values.items) {
            for (let i = 0; i < values.items.length; i++) {
                const element = values.items[i];
                tranxDichVuKhacRequests.push({
                    id: element.id,
                    tuNgay: element.rangeTime[0].format("DD/MM/YYYY"),
                    denNgay: element.rangeTime[1].format("DD/MM/YYYY"),
                    giaNet: element.giaNet,
                    giaBan: element.giaBan,
                    trangThaiBooking: element.trangThaiBooking ? element.trangThaiBooking : false,
                    foc: element.foc,
                    ve: element.ve,
                });
            }
        }
        let data = {
            id: 0, // thêm mới để 0, update truyền id đúng vào
            nccThongTinChungId: dvId,
            dmTyGiaId: dmTyGiaId ? dmTyGiaId : 1,
            maDichVu: maDichVu,
            tenDichVu: tenDichVu,
            ghiChu: ghiChu ? ghiChu : "",
            tranxDichVuKhacRequests: tranxDichVuKhacRequests,
        };
        form.resetFields();
        setOpen(false);
        handleFinish(open, data);
    };
    const handleCancels = () => {
        form.resetFields();
        handleCancel();
    };
    const dataRender = () => {
        let html;
        html = (
                <div className="">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.List name="items">
                                {(fields, { add, remove }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            rowGap: 16,
                                            flexDirection: "column",
                                        }}
                                    >
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Card
                                                size="large"
                                                key={key}
                                                // extra={
                                                //     <CloseOutlined
                                                //         onClick={() => {
                                                //             remove(field.name);
                                                //         }}
                                                //     />
                                                // }
                                            >
                                                <Row>
                                                    <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, "rangeTime"]}
                                                            label={t("hotel.priceRoom.applicationTime") + " *"}
                                                            {...formItemLayoutDate}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <RangePicker
                                                                style={{ width: "100%" }}
                                                                placeholder={[t("toDate"), t("fromDate")]}
                                                                disabledDate={(current) => {
                                                                    let customDate = moment().format("DD-MM-YYYY");
                                                                    return (
                                                                        current &&
                                                                        current < moment(customDate, "DD-MM-YYYY")
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={5} sm={5} md={5} lg={5} xl={5}></Col>
                                                    <Col xs={5} sm={5} md={5} lg={5} xl={2}>
                                                        {fields.length > 0 ? (
                                                            <MinusCircleOutlined
                                                                className="dynamic-delete-button"
                                                                onClick={() => remove(name)}
                                                            />
                                                        ) : null}
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "giaNet"]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaNet"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            marginTop: 1,
                                                                        }}
                                                                        label={t("hotel.priceClass.giaNet") + " *"}
                                                                    ></TextField>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row></Row>
                                                    </Col>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "giaBan"]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.priceClass.giaBan"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            marginTop: 1,
                                                                        }}
                                                                        label={t("hotel.priceClass.giaBan") + " *"}
                                                                    ></TextField>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "foc"]}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t(
                                                                                    "hotel.roomClass.conditionFoc"
                                                                                ).toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            marginTop: 1,
                                                                        }}
                                                                        label={t("hotel.roomClass.conditionFoc")}
                                                                    ></TextField>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row></Row>
                                                    </Col>
                                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={15} xl={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "ve"]}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                            message:
                                                                                t("validate") +
                                                                                " " +
                                                                                t("ticket").toLowerCase(),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            marginTop: 1,
                                                                        }}
                                                                        label={t("ticket")}
                                                                    ></TextField>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Form.Item
                                                        {...restField}
                                                        valuePropName="checked"
                                                        name={[name, "trangThaiBooking"]}
                                                        label={t("status") + " " + "booking"}
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message:
                                                                    t("validate") +
                                                                    " " +
                                                                    t(
                                                                        "hotel.priceRoom.applicationTime"
                                                                    ).toLowerCase(),
                                                            },
                                                        ]}
                                                    >
                                                        <Switch defaultChecked={true} />
                                                    </Form.Item>
                                                </Row>

                                            </Card>
                                        ))}
                                        <Row>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <Button type="primary" onClick={() => add()} block>
                                                    {t("add") + " " + t("time").toLowerCase()}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <TextField
                                label={t("notes")}
                                sx={{
                                    marginTop: 2,
                                }}
                                value={ghiChu ? ghiChu : ""}
                                fullWidth
                                // multiline
                                // rows={5}
                                onChange={(e) => setGhiChu(e.target.value)}
                            ></TextField>
                        </Col>
                    </Row>
                </div>
        );
        return html;
    };
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }

        return true;
    };

    const formItemLayoutDate = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };

    const handleClear = () => {
        form.resetFields();
        setMaDichVu("");
        setDmTyGiaId(1);
        setTenDichVu("");
        setGhiChu("");
    };
    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        setMaDichVuErr(false);
        setTenDichVuErr(false);

        if (!maDichVu) {
            setMaDichVuErr(true);
        }
        if (!tenDichVu) {
            setTenDichVuErr(true);
        }
        console.log("onFinishFailed:", values);
    };
    return (
        <div>
            <Modal
                title={
                    !isEmpty
                        ? t("edit") + " " + t("service").toLowerCase()
                        : t("add") + " " + t("service").toLowerCase()
                }
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1200}
            >
                <Divider />
                <Box component="form" noValidate>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        label={t("res.code") + " *"}
                                        value={maDichVu ? maDichVu : ""}
                                        fullWidth
                                        onChange={(e) => setMaDichVu(e.target.value)}
                                        error={maDichVuErr}
                                        helperText={
                                            maDichVuErr ? t("validate") + " " + t("res.code").toLowerCase() : ""
                                        }
                                    ></TextField>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        sx={{
                                            marginTop: 2,
                                        }}
                                        label={t("res.serviceProvided") + " *"}
                                        value={tenDichVu ? tenDichVu : ""}
                                        fullWidth
                                        onChange={(e) => setTenDichVu(e.target.value)}
                                        error={tenDichVuErr}
                                        helperText={
                                            tenDichVuErr
                                                ? t("validate") + " " + t("res.serviceProvided").toLowerCase()
                                                : ""
                                        }
                                    ></TextField>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        sx={{
                                            marginTop: 2,
                                        }}
                                        value={dmTyGiaId}
                                        fullWidth
                                        select
                                        onChange={(e) => setDmTyGiaId(e.target.value)}
                                        label={t("exchangeRate.currency")}
                                        SelectProps={{
                                            MenuProps: {
                                                sx: {height: MAX_HEIGHT_SELECT},
                                            },
                                        }}
                                    >
                                        {tiGia.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Box>
                <Form
                    layout="vertical"
                    form={form}
                    onFinishFailed={onFinishFailed}
                    onFinish={onFinish}
                    colon={false}
                    initialValues={{
                        items: [{}],
                    }}
                >
                    {dataRender()}
                    <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 11,
                        }}
                    >
                        <ButtonSubmit onFinish={onFinish} handleClear={handleClear} handleCancels={handleCancels} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default ModalPR;
