import React, { useEffect, useState, useRef } from "react";
import {  Typography, Space, Input, Button, Popconfirm, Row, Col, notification } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { API_BASE_URL } from "../../../../constants";
import authHeader from "../../../../api/auth_header";
import Highlighter from "react-highlight-words";
import FormTk from "./FormTK";
import ModalTkAction from "./ModalTkAction";
import { DataGrid, enUS, viVN, GridToolbarQuickFilter } from "@mui/x-data-grid";
import i18n from "../../../../i18n";
import { Box } from "@mui/material";
import TableMui from "../../../layout/TableMui";
const AccountBanking = ({ dvName, dvId, isView }) => {
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [dataSource, setDataSource] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [isShowAdd, setIsShowAdd] = useState(false);
    const [dataSearch, setDataSearch] = useState();
    const [action, setAction] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [reload, setReload] = useState(false);
    const searchInput = useRef(null);
    useEffect(() => {
        let data = {
            nccThongTinChungId: dvId,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/tknh/getData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    let dataLstSearch = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                stt: i + 1,
                                id: data[i].id,
                                tenNganHang: data[i].tenNganHang,
                                dmNganHangId: data[i].dmNganHangId,
                                soTaiKhoan: data[i].soTaiKhoan,
                                chuSoHuu: data[i].chuSoHuu,
                                chiNhanh: data[i].chiNhanh,
                                maSwift: data[i].maSwift,
                                ghiChu: data[i].ghiChu,
                                capNhatCuoi: data[i].capNhatCuoi,
                                qrPath: data[i].qrPath,
                            });
                            dataLstSearch.push({
                                value:
                                    data[i].id +
                                    " - " +
                                    data[i].tenNganHang +
                                    " - " +
                                    data[i].soTaiKhoan +
                                    " - " +
                                    data[i].chuSoHuu +
                                    " - " +
                                    data[i].chiNhanh +
                                    " - " +
                                    data[i].maSwift +
                                    " - " +
                                    data[i].ghiChu +
                                    " - " +
                                    data[i].capNhatCuoi,
                            });
                        }
                        setDataSearch(dataLstSearch);
                        setDataSource(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, [dvId, reload]);
    const onSearch = (value) => {
        let data = [];
        for (let i in dataSearch) {
            if (dataSearch[i].value.toString().toLowerCase().includes(value.toLowerCase())) {
                let item = dataSearch[i].value.split(" - ");
                data.push({
                    stt: i + 1,
                    id: item[0],
                    tenNganHang: item[1],
                    soTaiKhoan: item[2],
                    chuSoHuu: item[3],
                    chiNhanh: item[4],
                    maSwift: item[5],
                    ghiChu: item[6],
                    capNhatCuoi: item[7],
                });
            }
        }
        setDataSource(data);
    };
    const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
    };
    const viewRecord = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(false);
    };
    const handleDelete = (record) => {
        debugger;
        let apiData = {
            id: record.id,
            hieuLuc: false, //record.hieuLuc == true ? false : true,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/tknh/updateStatus",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let newData = dataSource.filter((item) => item.id !== record.id);
                    setDataSource(newData);
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch((err) =>
                api["error"]({
                    message: t("stour"),
                    description: t("delete") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                })
            );
    };
    const handleSearch = (selectedKeys, confirm, field) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(field);
    };
    const getColumnSearchProps = (field) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`${t("search")}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, field)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, field)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        {t("search")}
                    </Button>
                    <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
                        {t("action.reset")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        {t("close")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[field].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === field ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: t("hotel.accountBanking.bank"),
            field: "tenNganHang",
            key: "tenNganHang",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.accountBanking.accountNumber"),
            field: "soTaiKhoan",
            key: "soTaiKhoan",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.accountBanking.accountOwner"),
            field: "chuSoHuu",
            key: "chuSoHuu",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.accountBanking.branch"),
            field: "chiNhanh",
            key: "chiNhanh",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.accountBanking.swiftCode"),
            field: "maSwift",
            key: "maSwift",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("date"),
            field: "capNhatCuoi",
            key: "capNhatCuoi",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("notes"),
            field: "ghiChu",
            key: "ghiChu",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            flex: 1,
            headerAlign: "center",
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space size="large">
                        <Typography.Link onClick={() => viewRecord(record.row)}>
                            <EyeOutlined />
                        </Typography.Link>
                        {isView ? (
                            ""
                        ) : (
                            <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record.row)}>
                                <EditOutlined style={{}} />
                            </Typography.Link>
                        )}
                        {isView ? (
                            ""
                        ) : (
                            <Typography.Link>
                                <Popconfirm
                                    headerName={t("deleteTheTask")}
                                    description={t("areYouConfirm")}
                                    okText={t("delete")}
                                    cancelText={t("huy")}
                                    onConfirm={() => handleDelete(record.row)}
                                >
                                    <DeleteOutlined />
                                </Popconfirm>
                            </Typography.Link>
                        )}
                    </Space>
                );
            },
            align: "center",
        },
    ];
    const handleCancel = () => {
        setIsShow(false);
        setIsShowAdd(false);
        setEditingKey("");
    };
    const handleFinish = (status, values) => {
        let data = {
            ...values,
            nccThongTinChungId: dvId,
            chiNhanh: values.chiNhanh !== undefined ? values.chiNhanh : "",
            maSwift: values.maSwift !== undefined ? values.maSwift : "",
            ghiChu: values.ghiChu !== undefined ? values.ghiChu : "",
            //   qrCodeImg: {
            //     base64Value: "",
            //   },
        };
        console.log("data=====", JSON.stringify(data));
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/tknh/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response=====", response);
                if (response.status === 200 && response.data.code === "00") {
                    setReload(!reload);
                    setIsShow(false);
                    setIsShowAdd(false);
                    api["success"]({
                        message: t("stour"),
                        description: (values.id ? t("edit") : t("add")) + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else
                    api["error"]({
                        message: t("stour"),
                        description: (values.id ? t("edit") : t("add")) + " " + response.data.message,
                        duration: 0,
                    });
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };
    const QuickSearchToolbar = () => {
        return (
            <Box>
                <Row>
                    <Col xs={6} sm={6} md={6} lg={12} xl={5}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={4} sm={6} md={8} lg={10} xl={12}></Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <FormTk
                            handleSearch={onSearch}
                            isView={isView}
                            handleFinish={handleFinish}
                            isOpen={isShowAdd}
                            onShow={setIsShowAdd}
                            handleCancel={handleCancel}
                        />
                    </Col>
                </Row>
            </Box>
        );
    };
    return (
        <div>
            {contextHolder}
            <TableMui dataSource={dataSource} columns={columns} edit={edit} QuickSearchToolbar={QuickSearchToolbar} />

            <ModalTkAction
                isOpen={isShow}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
                record={editingRecord}
                action={action}
            />
        </div>
    );
};
export default AccountBanking;
