import {DataGrid, enUS, viVN} from "@mui/x-data-grid";
import i18n from "../../i18n";
import React from "react";


const TableMui=({dataSource, columns, QuickSearchToolbar, edit})=>{
    return (
            <DataGrid
                sx={{
                    "& .MuiDataGrid-cell": {
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiDataGrid-row": {
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiDataGrid-columnHeader": {
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        backgroundColor: "#e2e2e2",
                        marginTop: "10px"
                    },
                    "& .MuiDataGrid-columnHeaderTitle ": {
                        fontWeight: "bold",
                    },
                }}
                onRowDoubleClick={(record) => edit(record.row)}
                rows={dataSource}
                columns={columns}
                slots={{
                    toolbar: QuickSearchToolbar,
                }}
                autoHeight
                {...dataSource}
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[5, 10, 25]}
                {...dataSource}
                localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
            />
    );
}
export default TableMui