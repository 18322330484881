import { RedoOutlined } from "@ant-design/icons";
import SaveIcon from "@mui/icons-material/Save";
import { TextField } from "@mui/material";
import Buttons from "@mui/material/Button";
import { Button, Col, Divider, Row, Space, Typography, notification } from "antd";
import update from "immutability-helper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import baoGiaService from "../../../../api/bao_gia/baoGiaService";
import tourService from "../../../../api/tour/tour.service";
import CardHanhTrinh from "./CardHanhTrinh";
import ModalCopyCHietTinh from "./ModalCopyCHietTinh";
import { use } from "i18next";
import moment from "moment";
import ModalTourSanPham from "./ModalTourSanPham";
import { TIME_DURATION } from "../../../../constants";
const HanhTrinh = ({
    soNgay,
    baoGiaTongQuanId,
    checkErrorSoNgay,
    errorSoNgay,
    id,
    action,
    ngayKhoiHanhDate,
    ngayKhoiHanh,
    setDefaultActiveKey,
    setIsChangeHanhTrinh,
}) => {
    const { t } = useTranslation();
    const [isOpenCopy, setIsOpenCopy] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [cardRender, setCardRender] = useState({});
    const [cards, setCards] = useState([]);
    const [cardsOld, setCardsOld] = useState([]);
    const [isOpenTouMudle, setIsOpenTouMudle] = useState(false);
    const [keySelect, setKeySelect] = useState(0);
    const [isCopyTourMau, setIsCopyTourMau] = useState(false);
    const moveCard = (dragIndex, hoverIndex) => {
        setCards((prevCards) => {
            let dragId = prevCards[dragIndex].id;
            let dragHeader = prevCards[dragIndex].headers;
            let hoverId = prevCards[hoverIndex].id;
            let hoverHeader = prevCards[hoverIndex].headers;
            prevCards[hoverIndex].id = dragId;
            prevCards[hoverIndex].headers = dragHeader;
            prevCards[dragIndex].id = hoverId;
            prevCards[dragIndex].headers = hoverHeader;
            return update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            });
        });
    };

    const renderCard = (card, index) => {
        return (
            <CardHanhTrinh
                key={card.id}
                index={index}
                id={card.id}
                items={card}
                moveCard={moveCard}
                cards={cards}
                setCards={setCards}
                errorSoNgay={errorSoNgay}
                setIsOpenTouMudle={setIsOpenTouMudle}
                setKeySelect={setKeySelect}
                handleDelHT={handleDelHT}
                setIsChangeHanhTrinh={setIsChangeHanhTrinh}
                isCopyTourMau={isCopyTourMau}
            />
        );
    };
    // useEffect(() => {
    //     if (cards.length > 0) checkErrorSoNgay(cards.length);
    // }, [cards]);
    const handleDelHT = async (index) => {
        let response = await baoGiaService.deleteHanhTrinh({
            baoGiaTongQuanId: cards[index].baoGiaTongQuanId,
            bgHanhTrinhId: cards[index].id,
        });
        let newCards = [...cards];
        newCards.splice(index, 1);
        setCards(newCards);
        checkErrorSoNgay(newCards.length);
    };
    useEffect(() => {
        getData();
    }, [id, ngayKhoiHanh, ngayKhoiHanhDate, soNgay, baoGiaTongQuanId]);
    const getData = () => {
        if (baoGiaTongQuanId && ngayKhoiHanh) {
            baoGiaService.getHanhTrinhByTongQuanId({ baoGiaTongQuanId: baoGiaTongQuanId }).then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    console.log("id: " + id);
                    if (data.length > 0) {
                        console.log("getChuongTrinhTour1234");
                        let dataArr = [];
                        let dateArr = ngayKhoiHanh?.split("/");
                        data = data.sort((a, b) => (a.ngay > b.ngay ? 1 : -1));
                        for (let i = 0; i < data.length; i++) {
                            let date = new Date(dateArr[2], dateArr[1], parseInt(dateArr[0]) + i);
                            let thu = date.getDay() % 6;
                            let thuVN = "";
                            switch (thu) {
                                case 0:
                                    thuVN = "Thứ Hai";
                                    break;
                                case 1:
                                    thuVN = "Thứ Ba";
                                    break;
                                case 2:
                                    thuVN = "Thứ Tư";
                                    break;
                                case 3:
                                    thuVN = "Thứ Năm";
                                    break;
                                case 4:
                                    thuVN = "Thứ Sáu";
                                    break;
                                case 5:
                                    thuVN = "Thứ Bảy";
                                    break;
                                case 6:
                                    thuVN = "Chủ Nhật";
                                    break;
                                default:
                                    break;
                            }
                            let diemDen = data[i].diemDen.map((item) => {
                                return { quocGiaId: item.diemDenDmQuocGiaId, tinhId: item.diemDenDmTinhId };
                            });
                            let dataDichVu = data[i].dmDichVuIds.map((item) => {
                                return { id: item };
                            });
                            dataArr.push({
                                stt: i + 1,
                                id: data[i].id,
                                ngay: data[i].ngay,
                                bgHanhTrinhId: data[i].bgHanhTrinhId,
                                hanhTrinh: data[i].tenHanhTrinh,
                                noiDung: data[i].noiDung,
                                diemDi: { quocGiaId: data[i].diemDiDmQuocGiaId, tinhId: data[i].diemDiDmTinhId },
                                baoGiaTongQuanId: data[i].baoGiaTongQuanId,
                                headers:
                                    "Ngày " +
                                    data[i].ngay +
                                    " (" +
                                    thuVN +
                                    ", " +
                                    date.toLocaleDateString("en-GB") +
                                    ")",
                                diemDen: diemDen,
                                dmDichVus: dataDichVu,
                                // imgFile: data[i].fileDinhKem,
                            });
                        }
                        setCards([...dataArr]);
                        setCardsOld([...dataArr]);
                        checkErrorSoNgay(data.length);
                    } else {
                        if (id) {
                            tourService
                                .getChuongTrinhTour({ tourThongTinChungId: id })
                                .then(function (response) {
                                    if (response.status === 200) {
                                        let data = response.data.data;
                                        let dataArr = [];
                                        if (data.length > 0) {
                                            checkErrorSoNgay(data.length);
                                            console.log("getChuongTrinhTour");
                                            // if (!soNgay || data.length <= soNgay) setSoNgay(data.length);
                                            let length = data.length < soNgay ? soNgay : data.length;
                                            let dateArr = ngayKhoiHanh.split("/");
                                            for (let i = 0; i < length; i++) {
                                                let date = new Date(dateArr[2], dateArr[1], parseInt(dateArr[0]) + i);
                                                let thu = date.getDay() % 6;
                                                let thuVN = "";
                                                switch (thu) {
                                                    case 0:
                                                        thuVN = "Thứ Hai";
                                                        break;
                                                    case 1:
                                                        thuVN = "Thứ Ba";
                                                        break;
                                                    case 2:
                                                        thuVN = "Thứ Tư";
                                                        break;
                                                    case 3:
                                                        thuVN = "Thứ Năm";
                                                        break;
                                                    case 4:
                                                        thuVN = "Thứ Sáu";
                                                        break;
                                                    case 5:
                                                        thuVN = "Thứ Bảy";
                                                        break;
                                                    case 6:
                                                        thuVN = "Chủ Nhật";
                                                        break;
                                                    default:
                                                        break;
                                                }
                                                if (i < data.length) {
                                                    const element = data[i];
                                                    element.headers =
                                                        element.ngay +
                                                        " (" +
                                                        thuVN +
                                                        ", " +
                                                        date.toLocaleDateString("en-GB") +
                                                        ")";
                                                    dataArr.push({ ...element });
                                                } else {
                                                    let element = {
                                                        diemDi: {},
                                                        diemDen: [{}],
                                                        ngay: "Ngày " + (i + 1),
                                                    };
                                                    element.id = "New" + i;
                                                    element.headers =
                                                        element.ngay +
                                                        " (" +
                                                        thuVN +
                                                        ", " +
                                                        date.toLocaleDateString("en-GB") +
                                                        ")";
                                                    dataArr.push({ ...element });
                                                }
                                            }
                                        }
                                        console.log(JSON.stringify(dataArr));
                                        setCards([...dataArr]);
                                        setCardsOld([...dataArr]);
                                        checkErrorSoNgay(data.length);
                                        setIsCopyTourMau(true);
                                    }
                                })
                                .catch(function (response) {
                                    console.log("Error response: ====" + response);
                                    if (response.status === 401) {
                                    }
                                });
                        } else {
                            let dataArr = [];
                            let length = data.length < soNgay ? soNgay : data.length;
                            let dateArr = ngayKhoiHanh.split("/");
                            for (let i = 0; i < length; i++) {
                                let date = new Date(dateArr[2], dateArr[1], parseInt(dateArr[0]) + i);
                                let thu = date.getDay() % 6;
                                let thuVN = "";
                                switch (thu) {
                                    case 0:
                                        thuVN = "Thứ Hai";
                                        break;
                                    case 1:
                                        thuVN = "Thứ Ba";
                                        break;
                                    case 2:
                                        thuVN = "Thứ Tư";
                                        break;
                                    case 3:
                                        thuVN = "Thứ Năm";
                                        break;
                                    case 4:
                                        thuVN = "Thứ Sáu";
                                        break;
                                    case 5:
                                        thuVN = "Thứ Bảy";
                                        break;
                                    case 6:
                                        thuVN = "Chủ Nhật";
                                        break;
                                    default:
                                        break;
                                }
                                if (i < data.length) {
                                    const element = data[i];
                                    element.headers =
                                        element.ngay + " (" + thuVN + ", " + date.toLocaleDateString("en-GB") + ")";
                                    dataArr.push({ ...element });
                                } else {
                                    let element = {
                                        diemDi: {},
                                        diemDen: [{}],
                                        ngay: "Ngày " + (i + 1),
                                    };
                                    element.id = "New" + i;
                                    element.headers =
                                        element.ngay + " (" + thuVN + ", " + date.toLocaleDateString("en-GB") + ")";
                                    dataArr.push({ ...element });
                                }
                            }
                            setCards([...dataArr]);
                            setCardsOld([...dataArr]);
                            checkErrorSoNgay(data.length);
                            setIsCopyTourMau(true);
                        }
                    }
                }
            });
        }
    };

    // const handleFinish = (id) => {
    //     console.log(id);
    // };
    const handleCancelCopy = () => {
        setIsOpenCopy(false);
    };
    const handleFinishCopy = () => {};
    const handleSave = (e, type) => {
        e.preventDefault();
        let hanhTrinhs = [];
        for (let i = 0; i < cards.length; i++) {
            let cd = cards[i];
            let diemDen = [];
            for (let j = 0; j < cards[i].diemDen.length; j++) {
                let dd = {
                    diemDenDmTinhId: cards[i].diemDen[j].tinhId,
                    diemDenDmQuocGiaId: cards[i].diemDen[j].quocGiaId,
                };
                diemDen.push(dd);
            }
            hanhTrinhs.push({
                bgHanhTrinhId: cd.id.toString().indexOf("New") !== -1 ? 0 : cd.id,
                ngay: i + 1,
                tenHanhTrinh: cd.hanhTrinh,
                diemDiDmQuocGiaId: cd.diemDi.quocGiaId,
                diemDiDmTinhId: cd.diemDi.tinhId,
                diemDen: diemDen,
                fileDinhKem:
                    cd.imgFile && cd.imgFile.length > 0
                        ? cd.imgFile
                        : [
                              {
                                  id: 1,
                                  tenFile: "",
                                  base64Value: "",
                              },
                          ],
                dmDichVuIds: cards[i].dmDichVus ? cards[i].dmDichVus?.map((dv) => dv.id) : [],
                noiDung: cd.noiDung,
            });
        }
        let data = {
            // hanhDong: type, //luu hoac tiep_tuc
            baoGiaTongQuanId: baoGiaTongQuanId ? baoGiaTongQuanId : 1,
            hanhTrinhs: hanhTrinhs,
        };
        console.log(JSON.stringify(data));
        baoGiaService
            .saveHanhTrinh(data)
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    // handleResetDataTQ();
                    // if (action === "tiep_tuc") {
                    //     focusRef.current.scrollIntoView({ behavior: "smooth" });
                    // }
                    if (action === "tiep_tuc") {
                        setDefaultActiveKey("2");
                    }
                    setIsChangeHanhTrinh(false);
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(() => {
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });
    };
    const handleResetTour = () => {
        getData();
    };
    const handleCancelModule = () => {
        setIsOpenTouMudle(false);
    };
    const handleFinishModule = (row, apDung) => {
        if (row && row.id) {
            console.log(row);
            tourService
                .getChuongTrinhTour({ tourThongTinChungId: row.id })
                .then(function (response) {
                    if (response.status === 200) {
                        var dataCard = cards;
                        let data = response.data.data;
                        const element = data[0];
                        const newCard = { ...element };
                        console.log("newCard: ", newCard);
                        let diemDen = element.diemDen?.map((it) => {
                            return { tinhId: it.tinhId, quocGiaId: it.quocGiaId };
                        });
                        newCard.diemDen = diemDen;
                        newCard.id = dataCard[keySelect].id;
                        newCard.headers = dataCard[keySelect].headers;
                        dataCard[keySelect] = newCard;
                        setCards(dataCard);
                        setIsOpenTouMudle(false);
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                    if (response.status === 401) {
                    }
                });
        }
    };

    return (
        <>
            <ModalTourSanPham
                isOpen={isOpenTouMudle}
                handleCancel={handleCancelModule}
                handleFinish={handleFinishModule}
            />
            <ModalCopyCHietTinh isOpen={isOpenCopy} handleCancel={handleCancelCopy} handleFinish={handleFinishCopy} />
            <Row>
                {contextHolder}
                {/* <Col xs={24} sm={24} md={24} lg={24} xl={3}>
                    <Button type="primary" icon={<SearchOutlined />} onClick={() => setIsOpen(true)} size="large">
                        {t("selectTourTemplate")}
                    </Button>
                </Col> */}
                <Col xs={24} sm={24} md={24} lg={24} xl={3}>
                    <Button icon={<RedoOutlined />} size="large" onClick={handleResetTour}>
                        {t("RefreshTour")}
                    </Button>
                </Col>
            </Row>
            {/* <Row></Row> */}
            {/* <Row>
                <TextField fullWidth id="nhapTen" label={t("nhapTen") + " Tour"} variant="standard" />
            </Row> */}
            <Divider />
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div>
                        {cards.map((card, i) => {
                            return renderCard(card, i);
                        })}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    {errorSoNgay ? (
                        <Typography.Text type="danger">
                            Bạn vui lòng cấu hình số ngày trong Tour khớp với số ngày trong báo giá.
                        </Typography.Text>
                    ) : (
                        <></>
                    )}
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={2} xl={9}></Col>
                <Col xs={24} sm={24} md={24} lg={18} xl={7}>
                    <div
                        style={{
                            textAlign: "center",
                            color: "#fff",
                            height: 60,
                            paddingInline: 50,
                            lineHeight: "60px",
                            backgroundColor: "rgba(150, 150, 150, 0.2)",
                            borderRadius: "4px",
                        }}
                    >
                        <Space>
                            <Buttons
                                variant="contained"
                                type="submit"
                                startIcon={<SaveIcon />}
                                onClick={(e) => handleSave(e, "luu")}
                            >
                                {t("action.ok")}
                            </Buttons>
                            <Buttons
                                variant="outlined"
                                // startIcon={<DeleteIcon />}
                                onClick={(e) => handleSave(e, "tiep_tuc")}
                                key="continue"
                                disabled={errorSoNgay}
                            >
                                {t("action.continue")}
                            </Buttons>
                        </Space>
                    </div>
                </Col>
            </Row>
            {/* <ModalTourSanPham isOpen={isOpen} handleFinish={handleFinish} handleCancel={handleCancelTourSp} /> */}
        </>
    );
};

export default HanhTrinh;
