import { Col, Row, Button, Divider, Radio, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RedoOutlined, CopyOutlined } from "@ant-design/icons";
import ViewServies from "./ViewServies";
import ViewDates from "./ViewDates";
import Sale_rate_service from "../../../api/bao_gia/sale_rate/sale_rate_service";
import baoGiaService from "../../../api/bao_gia/baoGiaService";

const ChietTinhSale = ({
    dvId,
    baoGiaTongQuanId,
    soNgay,
    ngayKhoiHanh,
    dataNhomNgLon,
    dataNhomTreEm,
    soLuongNgLonBG,
    soLuongTreEmBG,
}) => {
    const { t } = useTranslation();
    const [valueRadio, setValueRadio] = useState(0);
    const [dataNgay, setDataNgay] = useState([]);
    const [dataDv, setDataDv] = useState([]);
    const [bgHanhTrinhs, setBgHanhTrinhs] = useState([]);
    const [tongGia, setTongGia] = useState([]);
    const [isEffect, setIsEffect] = useState(false);
    const [reload, setReload] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const onChangeRadio = (e) => {
        setValueRadio(e.target.value);
        setEditingId(null);
    };
    useEffect(() => {
        if (baoGiaTongQuanId !== 0) getData();
    }, [valueRadio, baoGiaTongQuanId]);
    useEffect(() => {
        if (reload) {
            setReload(false);
            getData();
        }
    }, [reload]);

    const getData = () => {
        let data = {
            baoGiaTongQuanId,
            loaiHienThi: valueRadio, //0: Hiển thị theo ngày,1: Hiển thị theo dịch vụ
        };
        Sale_rate_service.getSaleRate(data)
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    console.log(response);
                    let formatter = new Intl.NumberFormat("en-US", {});
                    setIsEffect(true);
                    setBgHanhTrinhs(response.data.data.bgHanhTrinhs);
                    if (valueRadio === 0) {
                        setDataNgay(response.data.data.chietTinhSaleResponses);
                        setTongGia(
                            response.data.data.tongGiaResponses?.map((it) => {
                                return {
                                    ...it,
                                    tongGiaNetTheoNhom: formatter.format(it.tongGiaNetTheoNhom),
                                    tongGiaNetTheoNguoi: formatter.format(it.tongGiaNetTheoNguoi),
                                    tongGiaBanTheoNhom: formatter.format(it.tongGiaBanTheoNhom),
                                    tongGiaBanTheoNguoi: formatter.format(it.tongGiaBanTheoNguoi),
                                };
                            })
                        );
                    } else {
                        setDataDv(response.data.data.dichVusResponses);
                        setTongGia(
                            response.data.data.tongGiaResponses?.map((it) => {
                                return {
                                    ...it,
                                    tongGiaNetTheoNhom: formatter.format(it.tongGiaNetTheoNhom),
                                    tongGiaNetTheoNguoi: formatter.format(it.tongGiaNetTheoNguoi),
                                    tongGiaBanTheoNhom: formatter.format(it.tongGiaBanTheoNhom),
                                    tongGiaBanTheoNguoi: formatter.format(it.tongGiaBanTheoNguoi),
                                };
                            })
                        );
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const options = [
        {
            label: t("hienThiTheoTungNgay"),
            value: 0,
        },
        {
            label: t("hienThiTheoTungDichVu"),
            value: 1,
        },
    ];
    const onChangeCauHinh = () => {
        if (valueRadio === 1) {
            return (
                <Col span={24}>
                    <ViewServies
                        dataDv={dataDv}
                        baoGiaTongQuanId={baoGiaTongQuanId}
                        ngayKhoiHanh={ngayKhoiHanh}
                        setReload={setReload}
                        dataNhomNgLon={dataNhomNgLon}
                        dataNhomTreEm={dataNhomTreEm}
                        soLuongNgLonBG={soLuongNgLonBG}
                        soLuongTreEmBG={soLuongTreEmBG}
                        soNgay={soNgay}
                        bgHanhTrinhs={bgHanhTrinhs}
                        tongGia={tongGia}
                        setEditingId={setEditingId}
                        editingId={editingId}
                        setDataDv={setDataDv}
                    />
                </Col>
            );
        } else {
            return (
                <Col span={24}>
                    <ViewDates
                        dataNgay={dataNgay}
                        baoGiaTongQuanId={baoGiaTongQuanId}
                        ngayKhoiHanh={ngayKhoiHanh}
                        isEffect={isEffect}
                        setReload={setReload}
                        dataNhomNgLon={dataNhomNgLon}
                        dataNhomTreEm={dataNhomTreEm}
                        soLuongNgLonBG={soLuongNgLonBG}
                        soLuongTreEmBG={soLuongTreEmBG}
                        soNgay={soNgay}
                        bgHanhTrinhs={bgHanhTrinhs}
                        tongGia={tongGia}
                        setEditingId={setEditingId}
                        editingId={editingId}
                        setDataNgay={setDataNgay}
                    />
                </Col>
            );
        }
    };
    const hanldeClear = () => {
        setIsEffect(true);
        if (valueRadio === 0) {
            var chietTinhSaleResponses = dataNgay;
            chietTinhSaleResponses = chietTinhSaleResponses.map((ct) => ({
                ngayId: ct.ngayId,
                ngay: ct.ngay,
                ngayDate: ct.ngayDate,
                baoGiaHanhTrinhId: ct.baoGiaHanhTrinhId,
                dichVusResponses: ct.dichVusResponses.map((dv) => ({
                    tenDichVu: dv.tenDichVu,
                    bgChietTinhOPId: dv.bgChietTinhOPId,
                    bgMapCTSaleHtId: dv.bgMapCTSaleHtId,
                    bgCTSaleId: dv.bgCTSaleId,
                    dmDichVuId: dv.dmDichVuId,
                    mapCTSaleNguoiLon: [],
                    mapCTSaleTreEm: [],
                })),
            }));
            setDataNgay(chietTinhSaleResponses);
        } else {
            var dichVusResponses = dataDv;
            dichVusResponses = dichVusResponses.map((ct) => ({
                tenDichVu: ct.tenDichVu,
                bgChietTinhOPId: ct.bgChietTinhOPId,
                bgMapCTSaleHtId: ct.bgMapCTSaleHtId,
                bgCTSaleId: ct.bgCTSaleId,
                dmDichVuId: ct.dmDichVuId,
                mapCTSaleNguoiLon: [],
                mapCTSaleTreEm: [],
            }));
            setDataDv(dichVusResponses);
        }
    };

    return (
        <>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                    <Button disabled={valueRadio !== 1} type="primary" icon={<CopyOutlined />} size="large">
                        {t("copyRateTour")}
                    </Button>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                    <Popconfirm title={t("refreshData")} okText="Yes" cancelText="No" onConfirm={() => hanldeClear()}>
                        <Button icon={<RedoOutlined />} size="large">
                            {t("RefreshTour")}
                        </Button>
                    </Popconfirm>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={6}></Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <Radio.Group
                        style={{ marginTop: "10px" }}
                        options={options}
                        onChange={onChangeRadio}
                        value={valueRadio}
                    />
                </Col>
            </Row>
            <Divider />
            <Row>{onChangeCauHinh()}</Row>
        </>
    );
};

export default ChietTinhSale;
