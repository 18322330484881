import React, { useEffect, useState } from "react";
import {
  Modal,
  Tabs,
  Button,
  Input,
  Space,
  Switch,
  Form,
  notification,
} from "antd";
import dm_ty_gia from "../../../../api/category/dm_ty_gia";
import TableServiceConfig from "./TableServiceConfig";
import TableTotalConfig from "./TableTotalConfig";
import baoGiaService from "../../../../api/bao_gia/baoGiaService";
import { LOAI_CAU_HINH, TIME_DURATION } from "../../../../constants";
import dm_gia_ap_dung from "../../../../api/category/dm_gia_ap_dung";
import { useTranslation } from "react-i18next";
const { TabPane } = Tabs;

const MarkupConfig = ({ isOpen, onOpen, bgTongQuanId }) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [dataTyGia, setDataTyGia] = useState([]);
  const [dmDoTuoi, setDmDoTuoi] = useState([]);
  const [dataSourceInit, setDataSourceInit] = useState({});
  const [bgCauHinhTongGiaId, setBgCauHinhTongGiaId] = useState(0);
  const [bgCauHinhDoTuoiTotalId, setBgCauHinhDoTuoiTotalId] = useState(0);
  const [bgCauHinhDoTuoiServiceId, setBgCauHinhDoTuoiServiceId] = useState(0);
  const [dmDoTuoiTotalId, setDmDoTuoiTotalId] = useState([]);
  const [dmDoTuoiServiceId, setDmDoTuoiServiceId] = useState([]);
  const [dataTotalNgLon, setDataTotalNgLon] = useState([]);
  const [dataServiceNgLon, setDataServiceNgLon] = useState([]);
  const [dataTotalTreEm, setDataTotalTreEm] = useState([]);
  const [dataServiceTreEm, setDataServiceTreEm] = useState([]);
  const [activeKeyNL, setActiveKeyNL] = useState("1");
  const [activeKeyTE, setActiveKeyTE] = useState("1");

  useEffect(() => {
    getDmTyGiaHeThong({});
    getDmDoTuoi();
  }, []);

  useEffect(() => {
    getCauHinhLoiNhuan({ baoGiaTongQuanId: bgTongQuanId, loaiChietTinh: 1 })
  }, [bgTongQuanId])

  const getCauHinhLoiNhuan = (data) => {
    baoGiaService.getCauHinhLoiNhuan(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          const data = res.data.data;
          setDataSourceInit(data);
          genderData(data);
        }
      })
      .catch((error) => {
        console.log("Get Cau Hinh Loi Nhuan Error: ", error);
      });
  }

  const genderData = (data) => {
    const cauHinhTotalTreEm = data.treEm?.find((item) => item.loaiCauHinh === LOAI_CAU_HINH.total) || null;
    const cauHinhServiceTreEm = data.treEm?.find((item) => item.loaiCauHinh === LOAI_CAU_HINH.service) || null;
    const cauHinhTotalNgLon = data.nguoiLon?.find((item) => item.loaiCauHinh === LOAI_CAU_HINH.total) || {};
    const cauHinhServiceNgLon = data.nguoiLon?.find((item) => item.loaiCauHinh === LOAI_CAU_HINH.service) || {};
    if (cauHinhServiceNgLon && cauHinhServiceNgLon.apDung) setActiveKeyNL("2")
    else setActiveKeyNL("1");
    if (cauHinhServiceTreEm && cauHinhServiceTreEm.apDung) setActiveKeyTE("2");
    else setActiveKeyTE("1");
    setBgCauHinhTongGiaId(data.bgCauHinhTongGiaId);
    setDmDoTuoiTotalId(cauHinhTotalTreEm?.doTuoi[0]?.dmDoTuoiId ? cauHinhTotalTreEm?.doTuoi[0]?.dmDoTuoiId : 1);
    setDmDoTuoiServiceId(cauHinhServiceTreEm?.doTuoi[0]?.dmDoTuoiId ? cauHinhServiceTreEm?.doTuoi[0]?.dmDoTuoiId : 1);
    setBgCauHinhDoTuoiTotalId(cauHinhTotalTreEm?.doTuoi[0]?.bgCauHinhDoTuoiId ? cauHinhTotalTreEm?.doTuoi[0]?.bgCauHinhDoTuoiId : 0);
    setBgCauHinhDoTuoiServiceId(cauHinhServiceTreEm?.doTuoi[0]?.bgCauHinhDoTuoiId ? cauHinhServiceTreEm?.doTuoi[0]?.bgCauHinhDoTuoiId : 0);
    setDataTotalNgLon(genderDataTotal(cauHinhTotalNgLon, false));
    setDataServiceNgLon(genderDataService(cauHinhServiceNgLon, false));
    setDataTotalTreEm(genderDataTotal(cauHinhTotalTreEm, true));
    setDataServiceTreEm(genderDataService(cauHinhServiceTreEm, true));
  };

  const getDmDoTuoi = () => {
    dm_gia_ap_dung
      .getDmDoTuoi()
      .then(function (response) {
        if (response.status === 200 && response.data.code === "00") {
          let data = response.data.data;
          setDmDoTuoi(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ", response);
      });
  };

  const getDmTyGiaHeThong = (data) => {
    dm_ty_gia
      .getDmTyGiaHeThong(data)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.data;
          console.log(data);
          setDataTyGia(data);
        }
      })
      .catch((response) => {
        console.log("Get Dm TyGia Error...", response);
      });
  };

  const genderDataTotal = (cauHinhTotal, isTreEm) => {
    // const cauHinhLoaiTien = isTreEm ?
    //   cauHinhTotal?.doTuoi[0]?.loaiTien?.map((dt) => ({ ...dt, bgCauHinhDoTuoiId: cauHinhTotal.doTuoi[0].bgCauHinhDoTuoiId })) || []
    //   : cauHinhTotal.loaiTien?.map((dt) => ({ ...dt, bgCauHinhDoTuoiId: 0 })) || [];
    // const dataMap = cauHinhLoaiTien?.map((item) => {
    //   return {
    //     id: item.bgCauHinhLoaiTienId,
    //     bgCauHinhLaiId: cauHinhTotal.bgCauHinhLaiId,
    //     ...item,
    //     details: item.details?.map((dt) => ({
    //       ...dt,
    //       type: dt.kieuTinh === 0 ? "Percentage" : "FixedAmount",
    //     })) || []
    //   }
    // }) || [];
    // console.log("dataTotal: ", dataMap);
    const cauHinhDoTuoiTotal = isTreEm ?
      cauHinhTotal?.doTuoi?.map((item) => {
        const dataMap = genderLoaiTienTotal(item.loaiTien, cauHinhTotal.bgCauHinhLaiId)
        return {
          bgCauHinhDoTuoiId: item.bgCauHinhDoTuoiId,
          dmDoTuoiId: item.dmDoTuoiId,
          loaiTien: dataMap
        }
      }) || []
      :
      genderLoaiTienTotal(cauHinhTotal.loaiTien, cauHinhTotal.bgCauHinhLaiId);


    console.log("cauHinhDoTuoiTotal: ", cauHinhDoTuoiTotal);
    return cauHinhDoTuoiTotal;
  };

  const genderLoaiTienTotal = (loaiTien, bgCauHinhLaiId) => {
    const dataMap = loaiTien?.map((dt) => {
      return {
        id: dt.bgCauHinhLoaiTienId,
        bgCauHinhLaiId: bgCauHinhLaiId,
        ...dt,
        details: dt.details?.map((it) => ({
          ...it,
          type: it.kieuTinh === 0 ? "Percentage" : "FixedAmount",
        })) || []
      }
    }) || [];
    return dataMap;
  };

  const genderDataService = (cauHinhService, isTreEm) => {
    // const cauHinhLoaiTien = isTreEm ?
    //   cauHinhService?.doTuoi[0]?.loaiTien?.map((dt) => ({ ...dt, bgCauHinhDoTuoiId: cauHinhService.doTuoi[0].bgCauHinhDoTuoiId })) || []
    //   : cauHinhService.loaiTien?.map((dt) => ({ ...dt, bgCauHinhDoTuoiId: 0 })) || [];
    // const dataMap = cauHinhLoaiTien?.map((item) => {
    //   // Nhóm dữ liệu
    //   let groupedData = item?.details?.reduce((result, dt) => {
    //     let typeKey = dt.kieuTinh === 0 ? "Percentage" : "FixedAmount";
    //     // Tìm nhóm tương ứng
    //     let group = result.find((g) => g.type === typeKey);
    //     if (!group) {
    //       group = {
    //         key: (result.length + 1).toString(),
    //         type: typeKey,
    //         kieuTinh: dt.kieuTinh,
    //         apDung: dt.apDung,
    //         children: [],
    //       };
    //       result.push(group);
    //     }

    //     // Thêm phần tử con vào nhóm
    //     group.children.push({
    //       groupId: item.bgCauHinhLoaiTienId,
    //       detailKey: group.key,
    //       key: `${group.key}-${group.children.length + 1}`,
    //       tenDichVu: dt.tenDichVu,
    //       dmDichVuId: dt.dmDichVuId,
    //       kieuTinh: dt.kieuTinh,
    //       giaTri: dt.giaTri,
    //       apDung: dt.apDung,
    //     });

    //     return result;
    //   }, []) || [];

    //   return {
    //     id: item.bgCauHinhLoaiTienId,
    //     bgCauHinhLaiId: cauHinhService.bgCauHinhLaiId,
    //     bgCauHinhDoTuoiId: item.bgCauHinhDoTuoiId,
    //     ...item,
    //     details: groupedData,
    //   }
    // }) || [];
    // console.log("dataService: ", dataMap);

    const cauHinhDoTuoiService = isTreEm ?
      cauHinhService?.doTuoi?.map((data) => {
        const dataMap = genderLoaiTienService(data.loaiTien, data.bgCauHinhDoTuoiId, cauHinhService.bgCauHinhLaiId);
        return {
          bgCauHinhDoTuoiId: data.bgCauHinhDoTuoiId,
          dmDoTuoiId: data.dmDoTuoiId,
          loaiTien: dataMap
        }
      }) || []

      : genderLoaiTienService(cauHinhService.loaiTien, 0, cauHinhService.bgCauHinhLaiId);

    console.log("cauHinhDoTuoiService: ", cauHinhDoTuoiService);
    return cauHinhDoTuoiService;
  };

  const genderLoaiTienService = (loaiTien, bgCauHinhDoTuoiId, bgCauHinhLaiId) => {
    const dataMap = loaiTien?.map((item) => {
      // Nhóm dữ liệu
      let groupedData = item?.details?.reduce((result, dt) => {
        let typeKey = dt.kieuTinh === 0 ? "Percentage" : "FixedAmount";
        // Tìm nhóm tương ứng
        let group = result.find((g) => g.type === typeKey);
        if (!group) {
          group = {
            key: (result.length + 1).toString(),
            type: typeKey,
            kieuTinh: dt.kieuTinh,
            apDung: dt.apDung,
            children: [],
          };
          result.push(group);
        }

        // Thêm phần tử con vào nhóm
        group.children.push({
          groupId: item.bgCauHinhLoaiTienId,
          detailKey: group.key,
          key: `${group.key}-${group.children.length + 1}`,
          tenDichVu: dt.tenDichVu,
          dmDichVuId: dt.dmDichVuId,
          kieuTinh: dt.kieuTinh,
          giaTri: dt.giaTri,
          apDung: dt.apDung,
        });

        return result;
      }, []) || [];

      return {
        id: item.bgCauHinhLoaiTienId,
        bgCauHinhLaiId: bgCauHinhLaiId,
        bgCauHinhDoTuoiId: bgCauHinhDoTuoiId,
        ...item,
        details: groupedData,
      }
    }) || [];

    return dataMap;
  }

  const handleSave = () => {
    let nguoiLon = [], treEm = [];
    let isCheck = handleValidateCurrency();
    if (isCheck) {
      showNoti(
        "error", t("noti.duplicateCurrency")
      );
    } else {
      debugger;
      if (dataTotalNgLon.length > 0)
        nguoiLon.push({
          loaiCauHinh: LOAI_CAU_HINH.total,
          apDung: activeKeyNL === "1" ? true : false,
          bgCauHinhLaiId: dataTotalNgLon[0].bgCauHinhLaiId,
          loaiTien: dataTotalNgLon.map((item) => ({
            bgCauHinhLoaiTienId: item.bgCauHinhLoaiTienId,
            dmLoaiTienId: item.dmLoaiTienId,
            details: item.details
          }))
        });

      if (dataServiceNgLon.length > 0)
        nguoiLon.push({
          loaiCauHinh: LOAI_CAU_HINH.service,
          apDung: activeKeyNL === "2" ? true : false,
          bgCauHinhLaiId: dataServiceNgLon[0].bgCauHinhLaiId,
          loaiTien: dataServiceNgLon.map((item) => ({
            bgCauHinhLoaiTienId: item.bgCauHinhLoaiTienId,
            dmLoaiTienId: item.dmLoaiTienId,
            details: item.details.flatMap((dt) => (dt.children?.map((it) => ({ ...it })) || [])) || []
          }))
        });
      if (dataTotalTreEm.length > 0)
        treEm.push({
          loaiCauHinh: LOAI_CAU_HINH.total,
          apDung: activeKeyTE === "1" ? true : false,
          bgCauHinhLaiId: dataTotalTreEm[0]?.loaiTien[0]?.bgCauHinhLaiId || 0,
          doTuoi: dataTotalTreEm?.filter((item) => item.loaiTien && item.loaiTien.length > 0)
            .map((data) => ({
              bgCauHinhDoTuoiId: data.bgCauHinhDoTuoiId,
              dmDoTuoiId: data.dmDoTuoiId,
              loaiTien: data.loaiTien.map((item) => ({
                bgCauHinhLoaiTienId: item.bgCauHinhLoaiTienId,
                dmLoaiTienId: item.dmLoaiTienId,
                details: item.details
              }))
            })) || []
        });
      if (dataServiceTreEm.length > 0)
        treEm.push({
          loaiCauHinh: LOAI_CAU_HINH.service,
          apDung: activeKeyTE === "2" ? true : false,
          bgCauHinhLaiId: dataServiceTreEm[0]?.loaiTien[0]?.bgCauHinhLaiId || 0,
          doTuoi: dataServiceTreEm?.filter((item) => item.loaiTien && item.loaiTien.length > 0)
            .map((data) => ({
              bgCauHinhDoTuoiId: data.bgCauHinhDoTuoiId,
              dmDoTuoiId: data.dmDoTuoiId,
              loaiTien: data.loaiTien.map((item) => ({
                bgCauHinhLoaiTienId: item.bgCauHinhLoaiTienId,
                dmLoaiTienId: item.dmLoaiTienId,
                details: item.details.flatMap((dt) => (dt.children?.map((it) => ({ ...it })) || [])) || []
              }))
            })) || []
        });
      const dataSave = {
        baoGiaTongQuanId: bgTongQuanId, //bgTongQuanId
        loaiChietTinh: 1, //0:chiet tinh op,1 chiet tinh sale
        bgCauHinhTongGiaId: bgCauHinhTongGiaId, //0:them moi, > 0 update
        nguoiLon: nguoiLon,
        treEm: treEm
      }
      console.log("dataSave: ", dataSave);
      console.log("jsonDataSave: ", JSON.stringify(dataSave));

      baoGiaService.saveCauHinhLoiNhuan(dataSave)
        .then((res) => {
          if (res.status === 200 && res.data.code === "00") {
            showNoti(
              "success",
              t("edit") + " " + t("noti.success").toLowerCase()
            );
            handleClose();
          }
        })
        .catch((error) => {
          console.log("saveCauHinhLoiNhuan Error: ", error);
        });
    }
  };

  const handleValidateCurrency = () => {
    let isCheck = false;
    if (dataTotalNgLon.length > 1) {
      let checkTotalNL = dataTotalNgLon.every(item => item.dmLoaiTienId === dataTotalNgLon[0].dmLoaiTienId);
      if (checkTotalNL) return checkTotalNL;
    }
    if (dataServiceNgLon.length > 1) {
      let checkServiceNL = dataServiceNgLon.every(item => item.dmLoaiTienId === dataServiceNgLon[0].dmLoaiTienId);
      if (checkServiceNL) return checkServiceNL;
    }
    if (dataTotalTreEm.length > 0) {
      for (let index = 0; index < dataTotalTreEm.length; index++) {
        const item = dataTotalTreEm[index];
        if (item.loaiTien?.length > 1) {
          let checkTotalTE = item.loaiTien.every(it => it.dmLoaiTienId === item.loaiTien[0].dmLoaiTienId);
          if (checkTotalTE) return checkTotalTE;
        }
      }
    }
    if (dataServiceTreEm.length > 1) {
      for (let index = 0; index < dataServiceTreEm.length; index++) {
        const item = dataServiceTreEm[index];
        if (item?.loaiTien.length > 1) {
          let checkServiceTE = item.loaiTien.every(it => it.dmLoaiTienId === item.loaiTien[0].dmLoaiTienId);
          if (checkServiceTE) return checkServiceTE;
        }
      }
    }
    return isCheck;
  }

  const handleDeleteLoaiTien = (bgCauHinhLaiId, bgCauHinhLoaiTienId, bgCauHinhDoTuoiId) => {
    let dataReq = {
      baoGiaTongQuanId: bgTongQuanId,
      bgCauHinhTongGiaId: bgCauHinhTongGiaId,
      bgCauHinhLaiId: bgCauHinhLaiId,
      bgCauHinhLoaiTienId: bgCauHinhLoaiTienId,
      bgCauHinhDoTuoiId: bgCauHinhDoTuoiId
    }
    console.log("reqDeleteCurrency: ", dataReq);

    baoGiaService.deleteCauHinhLoiNhuan(dataReq)
      .then((res) => {
        if (res.status === 200 && res.data.code === "00") {
          showNoti(
            "success",
            t("delete") + " " + t("noti.success").toLowerCase()
          );
        }
      })
      .catch((error) => {
        console.log("deleteCauHinhLoiNhuan Error: ", error);
      });
  }

  const handleResetData = () => {
    genderData(dataSourceInit);
  };

  const handleClose = () => {
    getCauHinhLoiNhuan({ baoGiaTongQuanId: bgTongQuanId, loaiChietTinh: 1 });
    onOpen(false);
  };

  const showNoti = (type, message) => {
    return api[type]({
      message: t("stour"),
      description: t(message),
      duration: TIME_DURATION,
    });
  };

  return (
    <Modal
      title="Cấu hình tỷ lệ lợi nhuận"
      visible={isOpen}
      onCancel={() => handleClose(false)}
      footer={null}
      width={800}
    >
      {contextHolder}
      <Tabs defaultActiveKey="1">
        <TabPane tab="Người lớn" key="1">
          <Tabs activeKey={activeKeyNL} onChange={setActiveKeyNL}>
            <TabPane tab="Total" key="1">
              <TableTotalConfig dataTyGia={dataTyGia} dataTotal={dataTotalNgLon} setDataTotal={setDataTotalNgLon} isTreEm={false} dmDoTuoi={dmDoTuoi}
                dmDoTuoiId={dmDoTuoiTotalId} setDmDoTuoiId={setDmDoTuoiTotalId} onDeleteLoaiTien={handleDeleteLoaiTien} />
            </TabPane>
            <TabPane tab="Services" key="2">
              <TableServiceConfig dataTyGia={dataTyGia} dataService={dataServiceNgLon} setDataService={setDataServiceNgLon} isTreEm={false} dmDoTuoi={dmDoTuoi}
                dmDoTuoiId={dmDoTuoiServiceId} setDmDoTuoiId={setDmDoTuoiServiceId} onDeleteLoaiTien={handleDeleteLoaiTien} />
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab="Trẻ em" key="2">
          <Tabs activeKey={activeKeyTE} onChange={setActiveKeyTE}>
            <TabPane tab="Total" key="1">
              <TableTotalConfig dataTyGia={dataTyGia} dataTotal={dataTotalTreEm} setDataTotal={setDataTotalTreEm} isTreEm={true}
                dmDoTuoi={dmDoTuoi} dmDoTuoiId={dmDoTuoiTotalId} setDmDoTuoiId={setDmDoTuoiTotalId} onDeleteLoaiTien={handleDeleteLoaiTien} />
            </TabPane>
            <TabPane tab="Services" key="2">
              <TableServiceConfig dataTyGia={dataTyGia} dataService={dataServiceTreEm} setDataService={setDataServiceTreEm} isTreEm={true}
                dmDoTuoi={dmDoTuoi} dmDoTuoiId={dmDoTuoiServiceId} setDmDoTuoiId={setDmDoTuoiServiceId} onDeleteLoaiTien={handleDeleteLoaiTien} />
            </TabPane>
          </Tabs>
        </TabPane>
      </Tabs>
      <Form layout="inline" style={{ marginTop: "20px" }}>
        <Form.Item label="Lưu mẫu">
          <Switch />
        </Form.Item>
        <Form.Item>
          <Input placeholder="Tên mẫu" />
        </Form.Item>
      </Form>
      <Space style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
        <Button onClick={() => handleResetData()}>Thiết lập lại</Button>
        <Button onClick={() => handleSave()} type="primary">Lưu</Button>
        <Button onClick={() => handleClose()} danger>Hủy</Button>
      </Space>
    </Modal>
  );
};

export default MarkupConfig;
