import React, { useEffect, useState } from "react";
import { Col, Divider, Modal, Row, Table, notification } from "antd";
import { useTranslation } from "react-i18next";
import {
    Button,
    Checkbox,
    DialogContent,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Slider,
    TextField,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import { GridFilterAltIcon } from "@mui/x-data-grid";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import otoService from "../../../../../api/tour/oto/oto.service";
import { TIME_DURATION } from "../../../../../constants";
function valuetext(value) {
    return `${value} đ`;
}

const ModalThongTinNccOto = ({ isOpen, action, onIsShow, onSaves, maNcc, lenNgLon, tuNgay, denNgay }) => {
    const { t } = useTranslation();
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [dataTinhTP, setDataTinhTP] = useState([]);
    const [quocGiaId, setQuocGiaId] = useState("");
    const [tinhId, setTinhId] = useState("");
    const [supplier, setSupplier] = useState("");
    const [dichVu, setDichVu] = useState("");
    const [hinhThucGiaId, setHinhThucGiaId] = useState("");
    const [soChoId, setSoChoId] = useState("");
    const [dmSoCho, setDmSoCho] = useState([]);
    const [dmHinhThucGia, setDmHinhThucGia] = useState([]);
    const [loaiXeId, setLoaiXeId] = useState("");
    const [dmLoaiXe, setDmLoaiXe] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [selectedRows, setSelectedRows] = useState([]);
    const [select, setSelect] = useState({
        selectedRowKeys: [],
        loading: false,
    });
    const { selectedRowKeys, loading } = select;
    const [dataNccOto, setDataNccOto] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState([20000, 7000000]);
    const open = Boolean(anchorEl);
    // rowSelection objects indicates the need for row selection
    useEffect(() => {
        getDataQuocGia();
        getDmLoaiXe();
        getDmHinhThucGia();
        getDmSoCho();
    }, []);
    useEffect(() => {
        getNccOto({
            searchNcc: maNcc,
            searchDichVu: "",
            tuNgay: tuNgay ? tuNgay : "",
            denNgay: denNgay ? denNgay : "",
            hinhThucGiaId: 0,
            soChoId: 0,
            loaiXeId: 0,
            quocGiaId: 0,
            tinhId: 0
        });
    }, [maNcc, denNgay, tuNgay]);
    const getDataQuocGia = () => {
        otoService
            .getQuocGia()
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataQG = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQG.push({
                                    value: data[i].id,
                                    label: data[i].quoc_gia,
                                });
                            }
                        }
                        setDataQuocGia(dataQG);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDataTinhTP = (idQuocGia) => {
        otoService
            .getTinhTP({ dm_quoc_gia_id: idQuocGia })
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dataTinhTP = data
                        .filter((it) => it.hieu_luc)
                        .map((item) => {
                            return {
                                value: item.id,
                                label: item.tinh_thanhpho,
                            };
                        });
                    setDataTinhTP(dataTinhTP);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmHinhThucGia = () => {
        otoService
            .getDmHinhThucGia()
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    setDmHinhThucGia(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getNccOto = (data) => {
        debugger;
        otoService
            .getNccOto(data)
            .then(function (response) {
                if (response.status === 200) {
                    let dataNccOto = response.data.data;
                    dataNccOto = dataNccOto.map((item, index) => {
                        item.key = index;
                        return item;
                    });
                    // console.log("dataNccOto", dataNccOto);
                    setDataNccOto(dataNccOto);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmSoCho = () => {
        otoService
            .getDmSoCho()
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dmSoCho = [];
                    data.forEach((item) => {
                        if (item.hieu_luc) {
                            dmSoCho.push({
                                value: item.id,
                                label: item.so_cho_str,
                            });
                        }
                    });
                    setDmSoCho(dmSoCho);
                }
            })
            .catch((response) => {
                console.log("Error response: ====" + response);
            });
    };

    const getDmLoaiXe = () => {
        otoService
            .getDmLoaiXe()
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dmLoaiXe = data
                        .filter((it) => it.hieu_luc)
                        .map((item) => {
                            return {
                                value: item.id,
                                label: item.ten_loai_xe,
                            };
                        });
                    setDmLoaiXe(dmLoaiXe);
                }
            })
            .catch((response) => {
                console.log("Error response: ====" + response);
            });
    };

    const handleData = (value) => {
        let number = 0;
        if (value !== undefined) {
            number = parseInt(value);
        }
        return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            setSelect({
                ...select,
                selectedRowKeys: selectedRowKeys,
            });
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
            setSelectedRows(selectedRows);
        },
    };
    const expandedRowRender = (record) => {
        const columns = [
            {
                title: t("res.code"),
                dataIndex: "maDichVu",
                key: "maDichVu",
                width: "10%",
            },
            {
                title: t("service") + "/" + t("trip"),
                dataIndex: "tenDichVu",
                key: "tenDichVu",
                width: "10%",
            },
            {
                title: t("formOfPricing"),
                key: "hinhThucGia",
                dataIndex: "hinhThucGia",
                width: "10%",
            },
            {
                title: t("number"),
                dataIndex: "soCho",
                key: "soCho",
                width: "10%",
            },
            {
                title: t("vehicleType"),
                dataIndex: "loaiXe",
                key: "loaiXe",
                width: "10%",
            },
            {
                title: t("hotel.priceRoom.applicationTime"),
                dataIndex: "thoiGianAD",
                key: "thoiGianAD",
                width: "20%",
            },
            {
                title: t("hotel.priceClass.giaNet"),
                dataIndex: "giaNet",
                key: "giaNet",
                width: "10%",
                render: (_, record) => {
                    return handleData(record.giaNet);
                },
            },
            {
                title: t("exchangeRate.currency"),
                dataIndex: "loaiTien",
                key: "loaiTien",
                width: "10%",
            },
            {
                title: t("tax") + " " + t("vat"),
                dataIndex: "vat",
                key: "vat",
                width: "10%",
            },
        ];
        let dataDichVus = record?.dichVus?.map((item) => {
            return {
                ...item,
                key: item.tranxOtoId,
                thoiGianAD: `${item.tuNgay} - ${item.denNgay}`,
                loaiTien: item.maNgoaiTe,
                vat: record.vat,
                dmVatId: record.dmVatId,
                tenNcc: record.tenNcc,
                maNcc: record.maNcc,
                isVat: record.isVat,
                nccThongTinChungId: record.nccThongTinChungId,
            };
        });
        return (
            <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataDichVus}
                // pagination={false}
                loading={loading}
            />
        );
    };
    const columns = [
        {
            title: t("maNcc"),
            dataIndex: "maNcc",
            key: "maNcc",
            width: "10%",
        },
        {
            title: t("supplier"),
            dataIndex: "tenNcc",
            key: "tenNcc",
            width: "20%",
        },
        {
            title: t("address"),
            dataIndex: "diaChi",
            key: "diaChi",
            width: "30%",
        },
        {
            title: t("phoneNumber"),
            dataIndex: "sdt",
            key: "sdt",
            width: "20%",
        },
        {
            title: t("email"),
            dataIndex: "email",
            key: "email",
            width: "20%",
        },
    ];

    const handleCancels = () => {
        onIsShow(false);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeQG = (event) => {
        setQuocGiaId(event.target.value);
        getDataTinhTP(event.target.value);
    };

    const handleCancelChooseService = () => {
        setSelect({ selectedRowKeys: [], loading: false });
        setSelectedRows([]);
        handleCancels();
    };

    const handleResetChooseService = () => {
        setSelect({ selectedRowKeys: [], loading: false });
        setSelectedRows([]);
        setSupplier("");
        setDichVu("");
        setHinhThucGiaId("");
        setSoChoId("");
        setLoaiXeId("");
        setQuocGiaId("");
        setTinhId("");
    };

    const handleSearchChooseService = () => {
        let dataSearch = {
            searchNcc: maNcc ? maNcc : supplier,
            searchDichVu: dichVu ? dichVu : "",
            tuNgay: tuNgay ? tuNgay : "",
            denNgay: denNgay ? denNgay : "",
            hinhThucGiaId: hinhThucGiaId ? hinhThucGiaId : 0,
            soChoId: soChoId ? soChoId : 0,
            loaiXeId: loaiXeId ? loaiXeId : 0,
            quocGiaId: quocGiaId ? quocGiaId : 0,
            tinhId: tinhId ? tinhId : 0,
        };
        console.log(dataSearch);
        getNccOto(dataSearch);
    };

    const handleConfirmSave = (e) => {
        e.preventDefault();
        if (action === "UPDATE_NCC" || selectedRows.length === 1) {
            onSaves(selectedRows);
            setSelect({ selectedRowKeys: [], loading: false });
            setSelectedRows([]);
            handleCancels();
            // if (action === "ADD_SERVICE")
            //   showNoti("success", t("add") + " " + t("noti.success").toLowerCase());
            // else
            //   showNoti("success", t("edit") + " " + t("noti.success").toLowerCase());
        } else {
            showNoti("error", t("noti.selectServiceError"));
        }
    };

    const showNoti = (type, message) => {
        return api[type]({
            message: t("stour"),
            description: t(message),
            duration: TIME_DURATION,
        });
    };

    return (
        <>
            {contextHolder}
            <Modal
                title={t("tour.oto.chonDichVu")}
                open={isOpen}
                onOk={handleConfirmSave}
                onCancel={handleCancels}
                footer={null}
                className="custom-modal-ncc"
                // width={1300}
                // height={700}
            >
                <Divider style={{ backgroundColor: "black" }} />
                <Row spacing={2} style={{ marginBottom: "20px", justifyContent: "space-between" }}>
                    <Col xs={4} sm={4} md={4} lg={4} xl={3}>
                        <OutlinedInput
                            endAdornment={
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                            }
                            size="small"
                            placeholder="Mã/Tên nhà cung cấp"
                            value={supplier}
                            onChange={(e) => setSupplier(e.target.value)}
                        />
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={3}>
                        <OutlinedInput
                            endAdornment={
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                            }
                            size="small"
                            placeholder="Mã dịch vụ cung cấp"
                            value={dichVu}
                            onChange={(e) => setDichVu(e.target.value)}
                        />
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={3}>
                        <TextField
                            label={t("formOfPricing")}
                            select
                            fullWidth
                            size="small"
                            name="hinhThucGiaId"
                            onChange={(e) => setHinhThucGiaId(e.target.value)}
                            value={hinhThucGiaId}
                        >
                            {dmHinhThucGia?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.hinh_thuc_gia}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={2}>
                        <TextField
                            label={t("number")}
                            select
                            fullWidth
                            size="small"
                            name="soChoId"
                            onChange={(e) => setSoChoId(e.target.value)}
                            value={soChoId}
                        >
                            {dmSoCho?.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={2}>
                        <TextField
                            label={t("vehicleType")}
                            select
                            fullWidth
                            size="small"
                            name="loaiXeId"
                            onChange={(e) => setLoaiXeId(e.target.value)}
                            value={loaiXeId}
                        >
                            {dmLoaiXe?.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{ display: "flex" }}>
                            <TextField
                                label={t("location.country")}
                                select
                                fullWidth
                                size="small"
                                name="quocGiaId"
                                onChange={(event) => handleChangeQG(event)}
                                value={quocGiaId}
                            >
                                {dataQuocGia?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label={t("location.city")}
                                select
                                fullWidth
                                size="small"
                                name="tinhId"
                                onChange={(event) => setTinhId(event.target.value)}
                                value={tinhId}
                            >
                                {dataTinhTP?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={2}>
                        <Button
                            style={{
                                backgroundColor: "#898989",
                                color: "white",
                                textTransform: "none",
                                width: "100%",
                            }}
                            key="filter"
                            startIcon={<GridFilterAltIcon />}
                            onClick={handleClick}
                            aria-controls={"account-menu"}
                            aria-haspopup="true"
                            aria-expanded={"true"}
                        >
                            Bộ lọc
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            // onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    "&::before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform: "translateY(-50%) rotate(45deg)",
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: "right", vertical: "top" }}
                            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        >
                            <DialogContent>
                                <Row
                                    style={{
                                        display: "flex",
                                        width: "400px",
                                        alignItems: "center",
                                    }}
                                >
                                    <Col span={5}>
                                        <b>Loại tiền</b>
                                    </Col>
                                    <Col span={9}>
                                        <TextField
                                            select
                                            fullWidth
                                            size="small"
                                            // name="thoiGianApDung"
                                            // onChange={(e) => setThoiGianApDung(e.target.value)}
                                            // value={thoiGianApDung}
                                        >
                                            <MenuItem value={0}>VND</MenuItem>
                                            <MenuItem value={1}>USD</MenuItem>
                                        </TextField>
                                    </Col>
                                </Row>
                                <Divider></Divider>
                                <Row>
                                    <Col span={5}>
                                        <b>Mức giá</b>
                                    </Col>
                                </Row>
                                <Row>
                                    <FormGroup
                                        sx={{
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            width: "400px",
                                        }}
                                    >
                                        <FormControlLabel
                                            sx={{ width: "120px" }}
                                            control={<Checkbox />}
                                            label="Label"
                                        />
                                        <FormControlLabel
                                            sx={{ width: "120px" }}
                                            control={<Checkbox />}
                                            label="Required"
                                        />
                                        <FormControlLabel
                                            sx={{ width: "120px" }}
                                            control={<Checkbox />}
                                            label="Disabled"
                                        />
                                        <FormControlLabel
                                            sx={{ width: "120px" }}
                                            control={<Checkbox />}
                                            label="Label"
                                        />
                                        <FormControlLabel
                                            sx={{ width: "120px" }}
                                            control={<Checkbox />}
                                            label="Required"
                                        />
                                        <FormControlLabel
                                            sx={{ width: "120px" }}
                                            control={<Checkbox />}
                                            label="Disabled"
                                        />
                                        <FormControlLabel
                                            sx={{ width: "120px" }}
                                            control={<Checkbox />}
                                            label="Label"
                                        />
                                        <FormControlLabel
                                            sx={{ width: "120px" }}
                                            control={<Checkbox />}
                                            label="Required"
                                        />
                                        <FormControlLabel
                                            sx={{ width: "120px" }}
                                            control={<Checkbox />}
                                            label="Disabled"
                                        />
                                    </FormGroup>
                                </Row>
                                <Divider />
                                <Row>
                                    <Col span={5}>
                                        <b>Khoảng giá</b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} />
                                    <Col span={16}>
                                        <Slider
                                            getAriaLabel={() => "Minimum distance"}
                                            value={value}
                                            onChange={handleChange}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetext}
                                            min={20000}
                                            max={7000000}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} />
                                    <Col span={7}>
                                        <TextField
                                            label={t("tour.oto.minValue")}
                                            value={`${value[0]} đ`}
                                            size="small"
                                            disabled
                                        ></TextField>
                                    </Col>
                                    <Col span={2}></Col>
                                    <Col span={7}>
                                        <TextField
                                            label={t("tour.oto.maxValue")}
                                            value={`${value[1]} đ`}
                                            size="small"
                                            disabled
                                        ></TextField>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row style={{ justifyContent: "flex-end" }}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            textTransform: "none",
                                            marginRight: "5px",
                                            backgroundColor: "#898989",
                                        }}
                                    >
                                        {t("action.reset")}
                                    </Button>
                                    <Button variant="contained" sx={{ textTransform: "none" }}>
                                        {t("action.apply")}
                                    </Button>
                                </Row>
                            </DialogContent>
                        </Menu>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={2}>
                        <Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSearchChooseService}>
                            {t("search")}
                        </Button>
                    </Col>
                </Row>
                <Row style={{ width: "100%" }}>
                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender,
                            defaultExpandedRowKeys: ["0"],
                        }}
                        dataSource={dataNccOto}
                        style={{ width: "100%" }}
                    />
                </Row>
                <Row>
                    <Col span={12}></Col>
                    <Col span={12}>
                        <Button
                            variant="contained"
                            sx={{ marginRight: "5px" }}
                            onClick={handleConfirmSave} // handleSaveChooseService
                            startIcon={<SaveIcon />}
                        >
                            {t("action.ok")}
                        </Button>
                        <Button
                            key="reset"
                            startIcon={<RestartAltIcon />}
                            style={{
                                backgroundColor: "#898989",
                                color: "white",
                                marginRight: "5px",
                            }}
                            onClick={handleResetChooseService}
                        >
                            {t("action.reset")}
                        </Button>
                        <Button variant="outlined" startIcon={<DeleteIcon />} onClick={handleCancelChooseService}>
                            {t("action.cancel")}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
export default ModalThongTinNccOto;
