import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Box } from "@mui/material";
import {  GridToolbarQuickFilter } from "@mui/x-data-grid";
import {  Col,  Popconfirm, Row, Space, Switch, Typography, notification } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
// import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../api/auth_header";
import "../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import { API_BASE_URL } from "../../../../constants";
// import i18n from "../../../../i18n";
import FormPR from "./FormPR";
import ModalPrAction from "./ModalPrAction";
import TableMui from "../../../layout/TableMui";

const DichVuCungCap = ({ dvName, dvId, isView }) => {
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [action, setAction] = useState(false);
    // const [filteredInfo, setFilteredInfo] = useState({});
    // const [dataSearch, setDataSearch] = useState();
    const isEditing = (record) => record.key === editingKey;
    const [api, contextHolder] = notification.useNotification();
    const [reload, setReload] = useState(false);
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
    };
    useEffect(() => {
        getData();
    }, [dvId]);
    useEffect(() => {
        if (reload) getData();
    }, [reload]);
    const getData = () => {
        let data = {
            nccThongTinChungId: dvId,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvkhac/getData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    console.log("dvkha1c: ", data);
                    if (data.length > 0) {
                        let sttCounter = 1;
                        let dataLst = data.flatMap((item, index) => {
                            const commonFields = {
                                rowKey: 1 + Math.random() * (100 - 1),
                                isDouble: item.tranxDichVuKhacRequests.length > 0,
                                id: item.id,
                                nccThongTinChungId: item.nccThongTinChungId,
                                dmTyGiaId: item.dmTyGiaId,
                                loaiTien: item.loaiTien,
                                maDichVu: item.maDichVu,
                                tenDichVu: item.tenDichVu,
                                ghiChu: item.ghiChu,

                            }
                            // Nếu có chi tiết dịch vụ
                            if (item.tranxDichVuKhacRequests.length > 0) {
                                return item.tranxDichVuKhacRequests.map(detail => ({
                                    stt: sttCounter++,
                                    ...commonFields,
                                    ids: detail.id,
                                    nccDichVuKhacId: detail.nccDichVuKhacId,
                                    thoiGianApDung: `${detail.tuNgay} - ${detail.denNgay}`,
                                    tuNgay: detail.tuNgay,
                                    denNgay: detail.denNgay,
                                    giaNet: detail.giaNet,
                                    giaBan: detail.giaBan,
                                    ve: detail.ve,
                                    foc: detail.foc,
                                    trangThaiBooking: detail.trangThaiBooking,
                                    hieuLuc: detail.hieuLuc,
                                }));
                            }

                            // Nếu không có chi tiết dịch vụ
                            return [{
                                stt: sttCounter++,  // Tăng số thứ tự cho mục chính
                                ...commonFields
                            }];

                        });
                        // Tạo danh sách tìm kiếm
                        // let dataLstSearch = data.map(item => ({
                        //     value: `${item.id}-${item.maDichVu}-${item.tenDichVu}`
                        // }));

                        setDataSource(dataLst);
                    }
                }

            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    // const handleChange = (pagination, filters, sorter) => {
    //     console.log("Various parameters", pagination, filters, sorter);
    //     setFilteredInfo(filters);
    // };
    const handleBorderChange = (record) => {
        let values = {
            id: record.idGp,
            trangThaiBooking: record.trangThaiBooking === true ? false : true,
            hieuLuc: record.hieuLuc,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvkhac/updateStatusTranxTime",
            data: values,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                })
            );

        // setDataSource(newData);
    };
    const viewRecord = (record) => {
        setEditingRecord(record);
        setIsShow(true);
    };
    const handleDelete = (record) => {
        const newData = dataSource.filter((item) => item.key !== record.key);
        let apiData = {
            id: record.id,
            hieuLuc: record.hieuLuc == true ? false : true,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvkhac/updateStatus",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: 0,
                    });
                }
            })
            .catch(
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                })
            );

        setDataSource(newData);
    };
    const handleCancel = () => {
        setIsShow(false);
    };
    const handleFinish = (status, values) => {
        setIsShow(status);
        setAction(status);
        console.log(JSON.stringify(values));
        debugger;
        let data = {
            ...values,
            id: values.id, // thêm mới để 0, update truyền id đúng vào
        };
        console.log("data edit=====", data);
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvkhac/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response=====", response);
                if (response.status === 200 && response.data.code === "00") {
                    let updatedList = dataSource.map((item) => {
                        values.tranxDichVuKhacRequests.forEach((element) => {
                            if (element.id === item.idGp) {
                                return (item = {
                                    ...item,
                                    nccHangPhongId: values.nccHangPhongId,
                                    dmTyGiaId: values.dmTyGiaId,
                                    loaiTien: values.loaiTien,
                                    maPhong: values.maPhong,
                                    ghiChu: values.ghiChu,
                                    tenDichVu: values.tenDichVu,
                                    maDichVu: values.maDichVu,
                                    tranxDichVuKhacRequests: values.tranxDichVuKhacRequests.length,
                                    nccGiaPhongId: element.nccGiaPhongId,
                                    idGp: element.id,
                                    thoiGianApDung: element.tuNgay + " - " + element.denNgay,
                                    tuNgay: element.tuNgay,
                                    denNgay: element.denNgay,
                                    fitNgayThuongNet: element.fitNgayThuongNet,
                                    fitNgayThuongGiaBan: element.fitNgayThuongGiaBan,
                                    fitCuoiTuanNet: element.fitCuoiTuanNet,
                                    fitCuoiTuanGiaBan: element.fitCuoiTuanGiaBan,
                                    trangThaiBooking: element.trangThaiBooking,
                                });
                            }
                            return item;
                        });
                        return item;
                    });
                    setDataSource(updatedList);
                    api["success"]({
                        message: t("stour"),
                        description: t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("edit") + " " + t("noti.error").toLowerCase(),
                    duration: 0,
                });
            });
    };
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, field) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(field);
    };
    // const getColumnSearchProps = (field) => ({
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    //         <div
    //             style={{
    //                 padding: 8,
    //             }}
    //             onKeyDown={(e) => e.stopPropagation()}
    //         >
    //             <Input
    //                 ref={searchInput}
    //                 placeholder={`${t("search")}`}
    //                 value={selectedKeys[0]}
    //                 onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //                 onPressEnter={() => handleSearch(selectedKeys, confirm, field)}
    //                 style={{
    //                     marginBottom: 8,
    //                     display: "block",
    //                 }}
    //             />
    //             <Space>
    //                 <Button
    //                     type="primary"
    //                     onClick={() => handleSearch(selectedKeys, confirm, field)}
    //                     icon={<SearchOutlined />}
    //                     size="small"
    //                 >
    //                     {t("search")}
    //                 </Button>
    //                 <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
    //                     {t("action.reset")}
    //                 </Button>
    //                 <Button
    //                     type="link"
    //                     size="small"
    //                     onClick={() => {
    //                         close();
    //                     }}
    //                 >
    //                     {t("close")}
    //                 </Button>
    //             </Space>
    //         </div>
    //     ),
    //     filterIcon: (filtered) => (
    //         <SearchOutlined
    //             style={{
    //                 color: filtered ? "#1677ff" : undefined,
    //             }}
    //         />
    //     ),
    //     onFilter: (value, record) => record[field].toString().toLowerCase().includes(value.toLowerCase()),
    //     onFilterDropdownOpenChange: (visible) => {
    //         if (visible) {
    //             setTimeout(() => searchInput.current?.select(), 100);
    //         }
    //     },
    //     render: (text) =>
    //         searchedColumn === field ? (
    //             <Highlighter
    //                 highlightStyle={{
    //                     backgroundColor: "#ffc069",
    //                     padding: 0,
    //                 }}
    //                 searchWords={[searchText]}
    //                 autoEscape
    //                 textToHighlight={text ? text.toString() : ""}
    //             />
    //         ) : (
    //             text
    //         ),
    // });
    const maPhongs = new Set();
    const stts = new Set();
    const hangPhongs = new Set();
    useEffect(() => {
        // https://stackblitz.com/edit/react-bubauz?file=index.js
        // https://stackoverflow.com/questions/61285411/ant-design-table-row-merge-based-on-data-instead-of-index-value
        maPhongs.clear();
        stts.clear();
        hangPhongs.clear();
    }, [hangPhongs, maPhongs, stts]);
    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            width: 60,
            headerAlign: "center",
        },
        {
            headerName: t("res.code"),
            field: "maDichVu",
            key: "maDichVu",
            width: 200,
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("res.serviceProvided"),
            field: "tenDichVu",
            key: "tenDichVu",
            width: 200,
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.priceRoom.applicationTime"),
            field: "thoiGianApDung",
            key: "thoiGianApDung",
            width: 500,
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "giaNet",
            key: "giaNet",
            align: "center",
            flex: 1,
            headerAlign: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "giaBan",
            key: "giaBan",
            align: "center",
            flex: 1,
            headerAlign: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("exchangeRate.currency"),
            field: "loaiTien",
            key: "loaiTien",
            align: "center",
            flex: 1,
            headerAlign: "center",
        },
        {
            headerName: t("status"),
            field: "trangThaiBooking",
            key: "trangThaiBooking",
            align: "center",
            flex: 1,
            headerAlign: "center",
            renderCell: ({ value, row }) => {
                return <Switch defaultValue={value} onChange={() => handleBorderChange(row)} />;
            },
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            flex: 1,
            headerAlign: "center",
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space>
                        <Typography.Link>
                            <EyeOutlined onClick={() => viewRecord(record.row)} />
                        </Typography.Link>
                        <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record.row)}>
                            <EditOutlined style={{}} />
                        </Typography.Link>
                        <Typography.Link>
                            <Popconfirm
                                headerName={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDelete(record.row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                );
            },
            align: "center",
        },
    ];

    // const handleReset = (clearFilters) => {
    //     clearFilters();
    //     setSearchText("");
    // };
    // const onSearch = (value) => {
    //     let data = []
    //     for (let i in dataSearch) {
    //         if (dataSearch[i].value.toString().toLowerCase().includes(value.toLowerCase())) {
    //             let item = dataSearch[i].value.split(" - ")
    //             data.push({
    //                 stt: i,
    //                 id: item[0],
    //                 hoVaTen: item[1],
    //                 phongBan: item[2],
    //                 chucVu: item[3],
    //                 sdt: item[4],
    //                 email: item[5]
    //             })
    //         }
    //     }
    //     setDataSource(data)
    // }
    const QuickSearchToolbar = () => {
        return (
            <Box
                sx={{
                    p: 0.5,
                    pb: 0,
                }}
            >
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={5}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={4}></Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FormPR dataAdd={dataSource} dvId={dvId} setReload={setReload} />
                    </Col>
                </Row>
            </Box>
        );
    };
    return (
        <div>
            {contextHolder}
            <TableMui dataSource={dataSource} columns={columns} QuickSearchToolbar={QuickSearchToolbar} edit={edit}/>
            <ModalPrAction
                isOpen={isShow}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
                record={editingRecord}
                action={action}
            />
        </div>
    );
};
export default DichVuCungCap;
