import React from "react";
import { Col, Row } from "antd";

const Header = ({
                    title = "Tổng số lượng phiếu thu",
                    total = 0,
                    stats = [],
                }) => {
    return (
        <Row>
            <Col
                span={24}
                style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "10px",
                    justifyContent: "space-around",
                    display: "flex",
                    paddingBottom: "20px",
                    paddingTop: "20px",
                    textAlign: "center",
                }}
            >
                {/* Main title */}
                <div style={{ fontWeight: "bold" }}>
                    <div style={{ fontSize: "20px" }}>{title}</div>
                    <div style={{ fontSize: "50px", alignSelf: "flex-end" }}>{total}</div>
                </div>

                {/* Statistics section */}
                <div
                    style={{
                        justifyContent: "space-around",
                        alignItems: "center",
                        display: "flex",
                        width: "70%",
                    }}
                >
                    {stats.map((stat, index) => (
                        <React.Fragment key={index}>
                            <div>
                                <h4>{stat.label}</h4>
                                <div>{stat.value}</div>
                            </div>
                            {index < stats.length - 1 && (
                                <span
                                    style={{
                                        borderLeft: "1px solid black",
                                        height: "50%",
                                        alignSelf: "flex-end",
                                        marginBottom: "1%",
                                    }}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </Col>
        </Row>
    );
};

export default Header;
