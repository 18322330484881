import { Tabs } from "antd";
import React, {  useState } from "react";
import { useTranslation } from "react-i18next";
import ThuChiTienQT from "./thuchi/ThuChiTienQT";
import PaymentProcessTimeline from "./thuchi/ThuChiTienPT";

const KeToan = () => {
    const { t } = useTranslation();
    const [defaultActiveKey, setDefaultActiveKey] = useState("1");
    const [dataSource, setDataSource] = useState([]);
    const items = [
        {
            key: "1",
            label: t("ketoan.quytrinh"),
            children: (
                <ThuChiTienQT
                />
            ),
        },{
            key: "2",
            label: t("booking.phieuThu"),
            children: (
                <PaymentProcessTimeline
                />
            ),
        },
    ];
    return (
        <>
            <Tabs activeKey={defaultActiveKey} items={items} onChange={(key) => setDefaultActiveKey(key)} />
        </>
    );
}
export default KeToan;
