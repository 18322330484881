import {useTranslation} from "react-i18next";
import React from "react";

const TextFieldNumber = ({ name, label,value, onChange }) => {
    const { t } = useTranslation();
    return (
        <TextFieldNumber
            fullWidth
            label={t(label)}
            name= {name}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
        />
    );
};
export default TextFieldNumber;