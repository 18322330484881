import React, {useState} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import {keyframes} from '@mui/system';

// CSS animation for rotate
const rotateAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const RefreshableText = React.memo(({ title, handleRefresh }) => {
    const [rotating, setRotating] = useState(false);

    const handleClick = () => {
        if (!rotating) {
            setRotating(true);
            handleRefresh?.();
            setTimeout(() => setRotating(false), 1000); // Reset trạng thái sau 1 giây
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: 'bold' }}>
                {title}
            </Typography>
            <IconButton onClick={handleClick} disabled={rotating}>
                <RefreshIcon
                    sx={{
                        fontSize: '20px',
                        animation: rotating ? `${rotateAnimation} 1s linear` : 'none',
                    }}
                />
            </IconButton>
        </Box>
    );
});


export default RefreshableText;
