import React, {useEffect, useState, useRef} from "react";
import { Typography, Switch, notification, Space, Popconfirm, Input, Button, Row, Col} from "antd";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined} from "@ant-design/icons";
import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import FormPR from "./FormPR";
// import Highlighter from "react-highlight-words";
import {API_BASE_URL, TIME_DURATION} from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import ModalPrAction from "./ModalPrAction";
import { GridToolbarQuickFilter} from "@mui/x-data-grid";
import {Box} from "@mui/material";
import TableMui from "../../../../layout/TableMui";

const DichVuCungCap = ({dvName, dvId, isView}) => {
    const {t} = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [action, setAction] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [dataSearch, setDataSearch] = useState();
    const isEditing = (record) => record.key === editingKey;
    const [api, contextHolder] = notification.useNotification();
    const [reload, setReload] = useState(false);
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
    };
    useEffect(() => {
        getData();
        console.log("dvId: " + dvId);
    }, [dvId]);
    useEffect(() => {
        if (reload) {
            setReload(false);
            getData();
        }
    }, [reload]);
    const getData = () => {
        let data = {
            nccThongTinChungId: dvId,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvOto/getData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====", response);
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log("length: ====", data);
                    if (data.length > 0) {
                        let sttCounter = 1;
                        let dataLst = data.flatMap((item, index) => {
                            const commonFields = {
                                rowKey: 1 + Math.random() * (100 - 1),
                                id: item.id,
                                nccThongTinChungId: item.nccThongTinChungId,
                                maDichVu: item.maDichVu,
                                tenDichVu: item.tenDichVu,
                                dmTyGiaId: item.dmTyGiaId,
                                maNgoaiTe: item.maNgoaiTe,
                                loaiTien: item.loaiTien,
                                ghiChu: item.ghiChu,
                                dmHinhThucGiaId: item.dmHinhThucGiaId,
                                hinhThucGia: item.hinhThucGia,
                                dmSoChoId: item.dmSoChoId,
                                soCho: item.soCho,
                                dmLoaiXeId: item.dmLoaiXeId,
                                tenLoaiXe: item.tenLoaiXe,
                                isDouble: item.tranxDvOtoTimeRequests.length > 0
                            };

                            // Nếu có chi tiết dịch vụ
                            if (item.tranxDvOtoTimeRequests.length > 0) {
                                return item.tranxDvOtoTimeRequests.map(detail => ({
                                    stt: sttCounter++,
                                    ...commonFields,
                                    ids:detail.id,
                                    dvoToId: detail.id,
                                    nccDichVuOtoId: detail.nccDichVuOtoId,
                                    thoiGianApDung: `${detail.tuNgay} - ${detail.denNgay}`,
                                    tuNgay: detail.tuNgay,
                                    denNgay: detail.denNgay,
                                    giaNet: detail.giaNet,
                                    giaBan: detail.giaBan,
                                    trangThaiBooking: detail.trangThaiBooking,
                                    hieuLuc: detail.hieuLuc
                                }));
                            }

                            // Nếu không có chi tiết dịch vụ
                            return [{
                                stt: sttCounter++,  // Tăng số thứ tự cho mục chính
                                ...commonFields
                            }];
                        });

                        // Tạo danh sách tìm kiếm
                        let dataLstSearch = data.map(item => ({
                            value: `${item.id}-${item.maDichVu}-${item.tenDichVu}`
                        }));

                        setDataSearch(dataLstSearch);
                        setDataSource(dataLst);
                    }
                }

            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };

    // const handleChange = (pagination, filters, sorter) => {
    //     console.log("Various parameters", pagination, filters, sorter);
    //     setFilteredInfo(filters);
    // };
    const handleBorderChange = (record, value) => {
        let values = {
            id: record.dvoToId,
            trangThaiBooking:  value !== true,
            hieuLuc: record.hieuLuc,
        };
        console.log("handleBorderChange " + JSON.stringify(values));
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvOto/updateStatusTranxTime",
            data: values,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description: t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch((error) => {
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });

        // setDataSource(newData);
    };
    const viewRecord = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(false);
    };
    const handleDelete = (record) => {
        const newData = dataSource.filter((item) => item.id !== record.id);
        let apiData = {
            id: record.id,
            hieuLuc: false,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvOto/updateStatus",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch((error) => {
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });

        setDataSource(newData);
    };
    const handleCancel = () => {
        setIsShow(false);
    };
    const handleFinish = async (status, values) => {
        let data = {
            ...values,
            id: values.id, // thêm mới để 0, update truyền id đúng vào
        };
        // console.log("data edit dv oto=====", data);
        try {
            let response = await axios({
                method: "post",
                url: API_BASE_URL + "ncc/dvOto/saveData",
                data: data,
                headers: authHeader(),
            });
            if (response.status === 200 && response.data.code === "00") {
                setIsShow(false);
                setAction(status);
                getData();
                api["success"]({
                    message: t("stour"),
                    description: t("edit") + " " + t("noti.success").toLowerCase(),
                    duration: TIME_DURATION,
                });
                return true;
            } else {
                setAction(true);
                api["error"]({
                    message: t("stour"),
                    description: response.data.message,
                    duration: TIME_DURATION,
                });
                return false;
            }
        } catch (error) {
            console.log("Error response: ====" + error);
            api["error"]({
                message: t("stour"),
                description: t("edit") + " " + t("noti.error").toLowerCase(),
                duration: TIME_DURATION,
            });
            return false;
        }
        // axios({
        //     method: "post",
        //     url: API_BASE_URL + "ncc/dvOto/saveData",
        //     data: data,
        //     headers: authHeader(),
        // })
        //     .then(function (response) {
        //         console.log("response=====", response);
        //     })
        //     .catch(function (response) {
        //         console.log("Error response: ====" + response);
        //         api["error"]({
        //             message: t("stour"),
        //             description: t("edit") + " " + t("noti.error").toLowerCase(),
        //             duration: TIME_DURATION,
        //         });
        //     });
    };

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, field) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(field);
    };
    // const getColumnSearchProps = (field) => ({
    //     filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
    //         <div
    //             style={{
    //                 padding: 8,
    //             }}
    //             onKeyDown={(e) => e.stopPropagation()}
    //         >
    //             <Input
    //                 ref={searchInput}
    //                 placeholder={`${t("search")}`}
    //                 value={selectedKeys[0]}
    //                 onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //                 onPressEnter={() => handleSearch(selectedKeys, confirm, field)}
    //                 style={{
    //                     marginBottom: 8,
    //                     display: "block",
    //                 }}
    //             />
    //             <Space>
    //                 <Button
    //                     type="primary"
    //                     onClick={() => handleSearch(selectedKeys, confirm, field)}
    //                     icon={<SearchOutlined/>}
    //                     size="small"
    //                 >
    //                     {t("search")}
    //                 </Button>
    //                 <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
    //                     {t("action.reset")}
    //                 </Button>
    //                 <Button
    //                     type="link"
    //                     size="small"
    //                     onClick={() => {
    //                         close();
    //                     }}
    //                 >
    //                     {t("close")}
    //                 </Button>
    //             </Space>
    //         </div>
    //     ),
    //     filterIcon: (filtered) => (
    //         <SearchOutlined
    //             style={{
    //                 color: filtered ? "#1677ff" : undefined,
    //             }}
    //         />
    //     ),
    //     onFilter: (value, record) => record[field].toString().toLowerCase().includes(value.toLowerCase()),
    //     onFilterDropdownOpenChange: (visible) => {
    //         if (visible) {
    //             setTimeout(() => searchInput.current?.select(), 100);
    //         }
    //     },
    //     render: (text) =>
    //         searchedColumn === field ? (
    //             <Highlighter
    //                 highlightStyle={{
    //                     backgroundColor: "#ffc069",
    //                     padding: 0,
    //                 }}
    //                 searchWords={[searchText]}
    //                 autoEscape
    //                 textToHighlight={text ? text.toString() : ""}
    //             />
    //         ) : (
    //             text
    //         ),
    // });
    // const maPhongs = new Set();
    // const stts = new Set();
    // const hangPhongs = new Set();
    // useEffect(() => {
    //     // https://stackblitz.com/edit/react-bubauz?file=index.js
    //     // https://stackoverflow.com/questions/61285411/ant-design-table-row-merge-based-on-data-instead-of-index-value
    //     maPhongs.clear();
    //     stts.clear();
    //     hangPhongs.clear();
    // }, [hangPhongs, maPhongs, stts]);
    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            headerAlign: "center",
            align: "center",
            // renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
        },
        {
            headerName: t("res.code"),
            field: "maDichVu",
            key: "maDichVu",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("res.serviceProvided") + "/ " + t("trip"),
            field: "tenDichVu",
            key: "tenDichVu",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("formOfPricing"),
            field: "hinhThucGia",
            key: "hinhThucGia",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("number"),
            field: "soCho",
            key: "soCho",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("vehicleType"),
            field: "tenLoaiXe",
            key: "tenLoaiXe",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.priceRoom.applicationTime"),
            field: "thoiGianApDung",
            key: "thoiGianApDung",
            width: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "giaNet",
            key: "giaNet",
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "giaBan",
            key: "giaBan",
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("exchangeRate.currency"),
            field: "loaiTien",
            key: "loaiTien",
            flex: 1,
            headerAlign: "center",
            align: "center",
            width: 200,
        },
        {
            headerName: t("status"),
            field: "trangThaiBooking",
            key: "trangThaiBooking",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: ({value, row}) => {
                if (value !== undefined) { return <Switch defaultChecked={value} onChange={() => handleBorderChange(row, value)} />; }
                else {
                    return null; }
            },
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space>
                        <Typography.Link>
                            <EyeOutlined onClick={() => viewRecord(record.row)}/>
                        </Typography.Link>
                        <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record.row)}>
                            <EditOutlined style={{}}/>
                        </Typography.Link>
                        <Typography.Link>
                            <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDelete(record.row)}
                            >
                                <DeleteOutlined/>
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                );
            },
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    // const handleReset = (clearFilters) => {
    //     clearFilters();
    //     setSearchText("");
    // };
    // const onSearch = (value) => {
    //     let data = []
    //     for (let i in dataSearch) {
    //         if (dataSearch[i].value.toString().toLowerCase().includes(value.toLowerCase())) {
    //             let item = dataSearch[i].value.split(" - ")
    //             data.push({
    //                 stt: i,
    //                 id: item[0],
    //                 hoVaTen: item[1],
    //                 phongBan: item[2],
    //                 chucVu: item[3],
    //                 sdt: item[4],
    //                 email: item[5]
    //             })
    //         }
    //     }
    //     setDataSource(data)
    // }
    const QuickSearchToolbar = () => {
        return (
            <Box>
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={5}>
                        <GridToolbarQuickFilter style={{width: "100%"}}/>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={4}></Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FormPR dataAdd={dataSource} dvId={dvId} setReload={setReload}/>
                    </Col>
                </Row>
            </Box>
        );
    };
    return (
        <div>
            {contextHolder}

            <TableMui dataSource={dataSource} columns={columns} edit={edit} QuickSearchToolbar={QuickSearchToolbar} />
            <ModalPrAction
                isOpen={isShow}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
                record={editingRecord}
                action={action}
                dvId={editingRecord.id}
            />
        </div>
    );
};
export default DichVuCungCap;
