import React, { useEffect, useState } from "react";
import { Col, Divider, Modal, Row, Table, notification } from "antd";
import { useTranslation } from "react-i18next";
import {
    Button,
    Checkbox,
    DialogContent,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Slider,
    TextField,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import { GridFilterAltIcon } from "@mui/x-data-grid";
import khachSanService from "../../../../../api/tour/khachSan/khachSanService";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import chiettinhService from "../../../../../api/tour/chiettinh.service";
import { TIME_DURATION } from "../../../../../constants";

function valuetext(value) {
    return `${value} đ`;
}

const ModalThongTinNccKhachSan = ({ isOpen, onSaves, onIsShow, action, maNcc, lenNgLon, tuNgay, denNgay }) => {
    const { t } = useTranslation();
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [dataTinhTP, setDataTinhTP] = useState([]);
    const [quocGiaId, setQuocGiaId] = useState("");
    const [tinhId, setTinhId] = useState("");
    const [supplier, setSupplier] = useState("");
    const [dichVu, setDichVu] = useState("");
    const [api, contextHolder] = notification.useNotification();
    const [selectedRows, setSelectedRows] = useState([]);
    const [select, setSelect] = useState({
        selectedRowKeys: [],
        loading: false,
    });
    const { selectedRowKeys, loading } = select;
    const [dataNccKhachSan, setDataNccKhachSan] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState([0, 0]);
    const [valueInit, setValueInit] = useState([0, 0]);
    const [dataMucGia, setDataMucGia] = useState([]);
    const [dataMucGiaInit, setDataMucGiaInit] = useState([]);
    const [giaMin, setGiaMin] = useState(0);
    const [giaMax, setGiaMax] = useState(0);
    const [dmTyGiaId, setDmTyGiaId] = useState(1);
    const [dmTyGia, setDmTyGia] = useState([
        { label: "VND", value: 1 },
        { label: "USD", value: 2 },
    ]);
    const [giaApDungs, setGiaApDungs] = useState([]);
    const open = Boolean(anchorEl);
    useEffect(() => {
        getDataQuocGia();
        getCauHinhMucGia({ id: dmTyGiaId });
    }, []);
    useEffect(() => {
        getNccKhachSan({
            searchNcc: maNcc,
            searchPhong: "",
            tuNgay: tuNgay ? tuNgay : "",
            denNgay: denNgay ? denNgay : "",
            quocGiaId: 0,
            tinhId: 0,
            giaMin: 0,
            giaMax: 0,
            dmTyGiaId: 0,
        });
    }, [maNcc, denNgay, denNgay]);
    const getDataQuocGia = () => {
        khachSanService
            .getQuocGia()
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response: ====" + response.data.data);
                    let dataQG = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQG.push({
                                    value: data[i].id,
                                    label: data[i].quoc_gia,
                                });
                            }
                        }
                        setDataQuocGia(dataQG);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDataTinhTP = (idQuocGia) => {
        khachSanService
            .getTinhTP({ dm_quoc_gia_id: idQuocGia })
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dataTinhTP = data
                        .filter((it) => it.hieu_luc)
                        .map((item) => {
                            return {
                                value: item.id,
                                label: item.tinh_thanhpho,
                            };
                        });
                    setDataTinhTP(dataTinhTP);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getNccKhachSan = (data) => {
        khachSanService
            .getNccKhachSan(data)
            .then(function (response) {
                if (response.status === 200) {
                    let dataNcc = response.data.data;
                    dataNcc = dataNcc.map((item, index) => {
                        item.key = index;
                        return item;
                    });
                    console.log("dataNcc", dataNcc);
                    setDataNccKhachSan(dataNcc);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getCauHinhMucGia = (data) => {
        if (data.id === 1) {
            setValue([0, 100000000]);
            setValueInit([0, 100000000]);
        } else {
            setValue([0, 10000]);
            setValueInit([0, 10000]);
        }
        chiettinhService
            .layCauHinhMucGia(data)
            .then(function (response) {
                if (response.status === 200) {
                    let dataMucGia =
                        response.data.data?.map((item) => ({
                            ...item,
                            label: item.mucGia,
                        })) || [];
                    setDataMucGia(dataMucGia);
                    if (data.id === 1) setDataMucGiaInit(JSON.parse(JSON.stringify(dataMucGia)));
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const handleData = (value) => {
        let number = 0;
        if (value !== undefined) {
            number = parseInt(value);
        }
        return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            setSelect({
                ...select,
                selectedRowKeys: selectedRowKeys,
            });
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
            setSelectedRows(selectedRows);
        },
    };
    const expandedRowRender = (record) => {
        const columns = [
            {
                title: t("hotel.priceClass.codeRoom"),
                dataIndex: "maPhong",
                key: "maPhong",
                width: "8%",
                align: "center",
            },
            {
                title: t("hotel.roomClass"),
                dataIndex: "hangPhong",
                key: "hangPhong",
                width: "10%",
                align: "center",
            },
            {
                title: t("hotel.priceClass.typeRoom"),
                key: "kieuPhong",
                dataIndex: "kieuPhong",
                width: "15%",
                align: "center",
            },
            {
                title: t("hotel.priceRoom.applicationTime"),
                dataIndex: "thoiGianAD",
                key: "thoiGianAD",
                width: "20%",
                align: "center",
            },
            {
                title: t("hotel.priceClass.weekdayPrice"),
                children: [
                    {
                        title: "Fit",
                        dataIndex: "fitNgayThuongNet",
                        key: "fitNgayThuongNet",
                        align: "center",
                        render: (_, record) => {
                            return handleData(record.fitNgayThuongNet);
                        },
                    },
                    {
                        title: "Extra",
                        dataIndex: "extraNgayThuongNet",
                        key: "extraNgayThuongNet",
                        align: "center",
                        render: (_, record) => {
                            return handleData(record.extraNgayThuongNet);
                        },
                    },
                ],
                width: "20%",
                align: "center",
            },
            {
                title: t("hotel.priceClass.weekendPrice"),
                children: [
                    {
                        title: "Fit",
                        dataIndex: "fitCuoiTuanNet",
                        key: "fitCuoiTuanNet",
                        align: "center",
                        render: (_, record) => {
                            return handleData(record.fitCuoiTuanNet);
                        },
                    },
                    {
                        title: "Extra",
                        dataIndex: "extraCuoiTuanNet",
                        key: "extraCuoiTuanNet",
                        align: "center",
                        render: (_, record) => {
                            return handleData(record.extraCuoiTuanNet);
                        },
                    },
                ],
                width: "20%",
                align: "center",
            },
            {
                title: t("hotel.roomClass.conditionFoc"),
                dataIndex: "dieuKienFoc",
                key: "dieuKienFoc",
                width: "10%",
                align: "center",
            },
            {
                title: t("exchangeRate.currency"),
                dataIndex: "maNgoaiTe",
                key: "maNgoaiTe",
                width: "10%",
                align: "center",
            },
            {
                title: t("tax") + " " + t("vat"),
                dataIndex: "vat",
                key: "vat",
                width: "8%",
                align: "center",
            },
        ];
        let dataDichVus = record?.dichVus?.map((item) => {
            return {
                ...item,
                key: item.tranxGiaPhongId,
                thoiGianAD: `${item.tuNgay} - ${item.denNgay}`,
                vat: record.vat,
                dmVatId: record.dmVatId,
                tenNcc: record.tenNcc,
                maNcc: record.maNcc,
                isVat: record.isVat,
                nccThongTinChungId: record.nccThongTinChungId,
            };
        });
        return (
            <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataDichVus}
                scroll={{ x: "110%" }}
                // pagination={false}
                loading={loading}
            />
        );
    };
    const columns = [
        {
            title: t("maNcc"),
            dataIndex: "maNcc",
            key: "maNcc",
            width: "10%",
            align: "center",
        },
        {
            title: t("supplier"),
            dataIndex: "tenNcc",
            key: "tenNcc",
            width: "20%",
            align: "center",
        },
        {
            title: t("address"),
            dataIndex: "diaChi",
            key: "diaChi",
            width: "30%",
            align: "center",
        },
        {
            title: t("phoneNumber"),
            dataIndex: "sdt",
            key: "sdt",
            width: "20%",
            align: "center",
        },
        {
            title: t("email"),
            dataIndex: "email",
            key: "email",
            width: "20%",
            align: "center",
        },
    ];

    const handleCancels = () => {
        setSupplier("");
        setDichVu("");
        setQuocGiaId(0);
        setTinhId(0);
        onIsShow(false);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setGiaApDungs([]);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        handleResetFilter();
        // setAnchorEl(null);
    };

    const handleApplyFilter = () => {
        setAnchorEl(null);
        let dataSearch = {
            searchNcc: supplier ? supplier : "",
            searchPhong: dichVu ? dichVu : "",
            tuNgay: tuNgay ? tuNgay : "",
            denNgay: denNgay ? denNgay : "",
            quocGiaId: quocGiaId ? quocGiaId : 0,
            tinhId: tinhId ? tinhId : 0,
            giaMin: giaApDungs.length > 0 ? (giaMin ? giaMin : 0) : value[0],
            giaMax: giaApDungs.length > 0 ? (giaMax ? giaMax : 0) : value[1],
            dmTyGiaId: dmTyGiaId ? dmTyGiaId : 1,
        };
        console.log(dataSearch);
        getNccKhachSan(dataSearch);
    };

    const handleChangeQG = (event) => {
        setQuocGiaId(event.target.value);
        getDataTinhTP(event.target.value);
    };

    const handleCancelChooseService = () => {
        setSelect({ selectedRowKeys: [], loading: false });
        setSelectedRows([]);
        handleCancels();
    };

    const handleResetChooseService = () => {
        setSelect({ selectedRowKeys: [], loading: false });
        setSelectedRows([]);
        setSupplier("");
        setDichVu("");
        setQuocGiaId("");
        setTinhId("");
    };

    const handleSearchChooseService = () => {
        let dataSearch = {
            searchNcc: maNcc ? maNcc : supplier,
            searchPhong: dichVu ? dichVu : "",
            tuNgay: tuNgay ? tuNgay : "",
            denNgay: denNgay ? denNgay : "",
            quocGiaId: quocGiaId ? quocGiaId : 0,
            tinhId: tinhId ? tinhId : 0,
            giaMin: giaApDungs.length > 0 ? (giaMin ? giaMin : 0) : value[0],
            giaMax: giaApDungs.length > 0 ? (giaMax ? giaMax : 0) : value[1],
            dmTyGiaId: dmTyGiaId ? dmTyGiaId : 1,
        };
        console.log(dataSearch);
        getNccKhachSan(dataSearch);
    };

    const handleSaveChooseService = () => {
        if (action === "UPDATE_NCC" || selectedRows.length === 1) {
            // if (lenNgLon > 0 && selectedRows.length > lenNgLon) {
            //     showNoti("error", t("noti.errorMaxServices"));
            // } else {
                onSaves(selectedRows);
                setSelect({ selectedRowKeys: [], loading: false });
                setSelectedRows([]);
                handleCancels();
            // }
        } else {
            showNoti("error", t("noti.selectServiceError"));
        }
    };

    const handleChangeLoaiTien = (dmTyGiaId) => {
        setDmTyGiaId(dmTyGiaId);
        getCauHinhMucGia({ id: dmTyGiaId });
    };

    const handleCheckMucGia = (e, item) => {
        setGiaMax(item.giaMax);
        setGiaMin(item.giaMin);
        setValue([...valueInit]);
        let checked = e.target.checked;
        const lstGiaApDung = [];
        if (checked) lstGiaApDung.push(item.id);
        setGiaApDungs(lstGiaApDung);
    };

    const handleResetFilter = () => {
        // setDataMucGia([...dataMucGiaInit]);
        setDataMucGia(JSON.parse(JSON.stringify(dataMucGiaInit)));
        setDmTyGiaId(1);
        setValue([...valueInit]);
        setGiaApDungs([]);
    };

    let formatter = new Intl.NumberFormat("en-US", {});

    const showNoti = (type, message) => {
        return api[type]({
            message: t("stour"),
            description: t(message),
            duration: TIME_DURATION,
        });
    };

    return (
        <>
            {contextHolder}
            <Modal
                title={t("tour.khachSan.chonDichVu")}
                open={isOpen}
                onOk={handleSaveChooseService}
                onCancel={handleCancels}
                footer={null}
                className="custom-modal-ncc"
                // width={1500}
                // height={1500}
            >
                <Divider style={{ backgroundColor: "black" }} />
                <Row>
                    <Col span={18}>
                        <Row style={{ marginBottom: "20px", justifyContent: "space-between" }}>
                            <Col span={5}>
                                <OutlinedInput
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Search />
                                        </InputAdornment>
                                    }
                                    size="small"
                                    placeholder="Mã/Tên nhà cung cấp"
                                    value={supplier}
                                    onChange={(e) => setSupplier(e.target.value)}
                                />
                            </Col>
                            <Col span={5}>
                                <OutlinedInput
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Search />
                                        </InputAdornment>
                                    }
                                    size="small"
                                    placeholder="Mã dịch vụ cung cấp"
                                    value={dichVu}
                                    onChange={(e) => setDichVu(e.target.value)}
                                />
                            </Col>
                            <Col span={7}>
                                <div style={{ display: "flex" }}>
                                    <TextField
                                        label={t("location.country")}
                                        select
                                        fullWidth
                                        size="small"
                                        name="quocGiaId"
                                        onChange={(event) => handleChangeQG(event)}
                                        value={quocGiaId}
                                    >
                                        {dataQuocGia?.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        label={t("location.city")}
                                        select
                                        fullWidth
                                        size="small"
                                        name="tinhId"
                                        onChange={(event) => setTinhId(event.target.value)}
                                        value={tinhId}
                                    >
                                        {dataTinhTP?.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Col>
                            <Col span={3}>
                                <Button
                                    style={{
                                        backgroundColor: "#898989",
                                        color: "white",
                                        textTransform: "none",
                                        width: "100%",
                                    }}
                                    key="filter"
                                    startIcon={<GridFilterAltIcon />}
                                    onClick={handleClick}
                                    aria-controls={"account-menu"}
                                    aria-haspopup="true"
                                    aria-expanded={"true"}
                                >
                                    {t("filter")}
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    // onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: "visible",
                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                            mt: 1.5,
                                            "& .MuiAvatar-root": {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            "&::before": {
                                                content: '""',
                                                display: "block",
                                                position: "absolute",
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: "background.paper",
                                                transform: "translateY(-50%) rotate(45deg)",
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                >
                                    <DialogContent>
                                        <Row
                                            style={{
                                                display: "flex",
                                                width: "400px",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Col span={5}>
                                                <b>{t("exchangeRate.currency")}</b>
                                            </Col>
                                            <Col span={9}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    size="small"
                                                    name="dmTyGiaId"
                                                    onChange={(e) => handleChangeLoaiTien(e.target.value)}
                                                    value={dmTyGiaId}
                                                >
                                                    {dmTyGia?.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Col>
                                        </Row>
                                        <Divider></Divider>
                                        <Row>
                                            <Col span={5}>
                                                <b>{t("priceLevel")}</b>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <FormGroup
                                                sx={{
                                                    flexDirection: "row",
                                                    flexWrap: "wrap",
                                                    width: "480px",
                                                }}
                                            >
                                                {dataMucGia.map((item) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={item.id}
                                                            sx={{ width: "150px" }}
                                                            control={
                                                                <Checkbox
                                                                    checked={giaApDungs?.includes(item.id)}
                                                                    onClick={(e) => handleCheckMucGia(e, item)}
                                                                />
                                                            }
                                                            label={item.label}
                                                        />
                                                    );
                                                })}
                                            </FormGroup>
                                        </Row>
                                        <Divider />
                                        <Row>
                                            <Col span={5}>
                                                <b>{t("priceRange")}</b>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={4} />
                                            <Col span={16}>
                                                <Slider
                                                    getAriaLabel={() => "Minimum distance"}
                                                    value={value}
                                                    onChange={handleChange}
                                                    valueLabelDisplay="auto"
                                                    getAriaValueText={valuetext}
                                                    min={0}
                                                    max={dmTyGiaId === 1 ? 100000000 : 10000}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={4} />
                                            <Col span={7}>
                                                <TextField
                                                    label={t("tour.oto.minValue")}
                                                    value={
                                                        dmTyGiaId === 1
                                                            ? `${formatter.format(value[0])} đ`
                                                            : `${formatter.format(value[0])} $`
                                                    }
                                                    size="small"
                                                    disabled
                                                ></TextField>
                                            </Col>
                                            <Col span={2}></Col>
                                            <Col span={7}>
                                                <TextField
                                                    label={t("tour.oto.maxValue")}
                                                    value={
                                                        dmTyGiaId === 1
                                                            ? `${formatter.format(value[1])} đ`
                                                            : `${formatter.format(value[1])} $`
                                                    }
                                                    size="small"
                                                    disabled
                                                ></TextField>
                                            </Col>
                                        </Row>
                                        <Divider />
                                        <Row style={{ justifyContent: "flex-end" }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    textTransform: "none",
                                                    marginRight: "5px",
                                                    backgroundColor: "#898989",
                                                }}
                                                onClick={() => handleResetFilter()}
                                            >
                                                {t("action.reset")}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                sx={{ textTransform: "none" }}
                                                onClick={handleApplyFilter}
                                            >
                                                {t("action.apply")}
                                            </Button>
                                        </Row>
                                    </DialogContent>
                                </Menu>
                            </Col>
                            <Col span={3}>
                                <Button
                                    variant="contained"
                                    sx={{ textTransform: "none" }}
                                    onClick={handleSearchChooseService}
                                >
                                    {t("search")}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ width: "100%" }}>
                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender,
                            defaultExpandedRowKeys: ["0"],
                        }}
                        dataSource={dataNccKhachSan}
                        style={{ width: "100%" }}
                    />
                </Row>
                <Row>
                    <Col span={12}></Col>
                    <Col span={12}>
                        <Button
                            variant="contained"
                            sx={{ marginRight: "5px" }}
                            onClick={handleSaveChooseService}
                            startIcon={<SaveIcon />}
                        >
                            {t("action.ok")}
                        </Button>
                        <Button
                            key="reset"
                            startIcon={<RestartAltIcon />}
                            style={{
                                backgroundColor: "#898989",
                                color: "white",
                                marginRight: "5px",
                            }}
                            onClick={handleResetChooseService}
                        >
                            {t("action.reset")}
                        </Button>
                        <Button variant="outlined" startIcon={<DeleteIcon />} onClick={handleCancelChooseService}>
                            {t("action.cancel")}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
export default ModalThongTinNccKhachSan;
