import React from "react";
import {Col, Row, Tag} from "antd";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";

export default function Table(props) {
    const {t} = useTranslation();

    const generateFakeRows = (num) => {
        const names = ["Ngô Ngọc Anh", "Công ty TNHH ABC", "Trần Thị Lan", "Phạm Văn A"];
        const statuses = ["Đã thanh toán", "Chưa thanh toán", "Đã đặt", "Chờ hủy"];
        const paymentTypes = ["Tiền mặt", "Chuyển khoản", "Thẻ tín dụng"];
        const reasons = ["Đơn hàng đã giao", "Hóa đơn khách hàng", "Đã thanh toán đủ"];

        return Array.from({length: num}, (_, index) => ({
            id: index + 1,
            stt: index + 1,
            soPhieu: `PT${(index + 1).toString().padStart(3, "0")}`,
            loaiThanhToan: paymentTypes[Math.floor(Math.random() * paymentTypes.length)],
            doiTuong: names[Math.floor(Math.random() * names.length)],
            ngayPhieuThu: `2023-11-${Math.floor(Math.random() * 30 + 1).toString().padStart(2, "0")}`,
            ngayDenHan: `2023-11-${Math.floor(Math.random() * 30 + 1).toString().padStart(2, "0")}`,
            ngayThanhToan: `2023-11-${Math.floor(Math.random() * 30 + 1).toString().padStart(2, "0")}`,
            tongTienThu: Math.floor(Math.random() * 10000000 + 1000000),
            tinChapThanhToan: Math.floor(Math.random() * 5000000 + 1000000),
            tongTienCanThu: Math.floor(Math.random() * 5000000),
            lyDo: reasons[Math.floor(Math.random() * reasons.length)],
            nguoiTao: names[Math.floor(Math.random() * names.length)],
            tenTrangThai: statuses[Math.floor(Math.random() * statuses.length)], // Thêm trường trạng thái
            dateUpdate: `2023-11-${Math.floor(Math.random() * 30 + 1).toString().padStart(2, "0")}`, // Thêm lần sửa đổi cuối
        }));
    };


    const fakeRows = generateFakeRows(50);

    const columns = [
        {
            headerName: "STT",
            field: "stt",
            key: "stt",
            align: "center",
            width: 50,
            type: "number",
            headerAlign: "center",
        },
        {
            headerName: t("booking.soPhieu"),
            field: "soPhieu",
            key: "soPhieu",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: t("loaiThanhToan"),
            field: "loaiThanhToan",
            key: "loaiThanhToan",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: "Doi tuong",
            field: "doiTuong",
            key: "doiTuong",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: "Ngay phieu thu",
            field: "ngayPhieuThu",
            key: "ngayPhieuThu",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: "Ngay den han",
            field: "ngayDenHan",
            key: "ngayDenHan",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: "Ngay thanh toan",
            field: "ngayThanhToan",
            key: "ngayThanhToan",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: "Tong tien thu",
            field: "tongTienThu",
            key: "tongTienThu",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: "Tin chap thanh toan",
            field: "tinChapThanhToan",
            key: "tinChapThanhToan",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: "Tong tien can thu",
            field: "tongTienCanThu",
            key: "tongTienCanThu",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: t("booking.lyDo"),
            field: "lyDo",
            key: "lyDo",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: "Nguoi tao",
            field: "nguoiTao",
            key: "nguoiTao",
            headerAlign: "center",
            flex: 1,
        },
        {
            headerName: t("status"),
            field: "tenTrangThai", // Đảm bảo khớp với dữ liệu
            key: "tenTrangThai",
            align: "center",
            minWidth: 180,
            headerAlign: "center",
            renderCell: (params) => {
                const text = params.row.tenTrangThai;
                return text === "Đã thanh toán" ? (
                    <Tag color="green">{text}</Tag>
                ) : text === "Chưa thanh toán" ? (
                    <Tag color="red">{text}</Tag>
                ) : text === "Không hợp lệ" ? (
                    <Tag color="yellow">{text}</Tag>
                ) : (
                    <Tag color="gray">{text}</Tag>
                );
            }
        },
        {
            headerName: t("lanSuaCuoi"),
            field: "dateUpdate", // Đảm bảo khớp với dữ liệu
            key: "dateUpdate",
            align: "center",
            width: 180,
            headerAlign: "center",
            renderCell: (params) => <span>{params.row.dateUpdate}</span>, // Hiển thị giá trị trực tiếp
        },
        {
            headerName: "Thao tác",
            field: "actions",
            type: "actions",
            width: 50,
            getActions: (params) => [
                <GridActionsCellItem
                    label="Sửa"
                    showInMenu
                    onClick={() => props.handleUpdatePhieuChi(params.row.tranxOpRateId, params.row.opPhieuChiId)}
                />,
                <GridActionsCellItem label="Chuyển kế toán" showInMenu/>,
                <GridActionsCellItem
                    label="Xác nhận"
                    showInMenu
                />,
                <GridActionsCellItem
                    label="Xóa"
                    showInMenu
                />,
                <GridActionsCellItem
                    label="Lịch sử phiếu thu"
                    showInMenu
                />,
            ],
        },
    ];
    return (
        <Row style={{marginTop: "20px"}}>
            <Col span={24}>
                <DataGrid
                    sx={{
                        "& .MuiDataGrid-cell": {
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                        },
                        "& .MuiDataGrid-row": {
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        },
                        "& .MuiDataGrid-columnHeader": {
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            backgroundColor: "#e2e2e2",
                        },
                        "& .MuiDataGrid-columnHeaderTitle ": {
                            fontWeight: "bold",
                        },
                    }}
                    columns={columns}
                    rows={fakeRows}
                    autoHeight
                />
            </Col>
        </Row>
    )
}